// React
import React, { useState } from "react";

//Atoms
import Button from "Components/atoms/buttons/Button";

const NumericInput = (props) => {
  const { quantity, justValue, handleLocalStorage1, disabled } = props;

  const handleDefineValue = (quantity) => {
    handleLocalStorage1(quantity);
  };

  return (
    <>
      {!justValue && (
        <Button
          size="small"
          label={"-"}
          variant="contained"
          customStyle={{
            padding: "0px 3px",
            minWidth: "30px",
            backgroundImage: `linear-gradient(90deg, white, white)`,
            color: "grey",
            margin: "5px",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
          }}
          onClick={() =>
            handleDefineValue(quantity === 1 ? quantity : quantity - 1)
          }
          disabled={disabled}
        />
      )}
      <Button
        disabled={true}
        size="small"
        label={justValue ? "x" + quantity : quantity}
        variant="contained"
        customStyle={{
          padding: "0px 3px",
          minWidth: "30px",
          boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
          margin: !justValue ? "5px 0px" : "4px",
          marginRight: !justValue ? null : "0px",
          height: "min-content",
        }}
      />
      {!justValue && (
        <Button
          size="small"
          label={"+"}
          variant="contained"
          customStyle={{
            padding: "0px 3px",
            minWidth: "30px",
            backgroundImage: `linear-gradient(90deg, white, white)`,
            color: "grey",
            margin: "5px",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
          }}
          onClick={() => handleDefineValue(quantity + 1)}
          disabled={disabled}
        />
      )}
    </>
  );
};

NumericInput.defaultProps = {
  quantity: "",
  justValue: false,
  handleLocalStorage1: null,
  disabled: false,
};

export default NumericInput;

// React
import React, { useEffect, memo } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import { detectSafari } from "Util/functions";

// Util functions
import { getCookie } from "Util/functions";
import { GLOBAL_VARIABLES } from "Util/idConfig";

// MUI
import Typography from "@material-ui/core/Typography";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Box } from "@material-ui/core";

// Molecules
import SingleReview from "Components/molecules/SingleReview";

// Atoms
import Price from "Components/atoms/UI/Price";
import ReviewStar from "Components/atoms/UI/ReviewStar";
import Tag from "Pages/browse/components/molecules/Tag";
import IconButton from "Components/atoms/buttons/IconButton";

// Layouts
import FullScreenSpinner from "Layouts/loaders/FullScreenSpinner";

// Accordion
import OrderSection from "Pages/browse/components/Accordion/OrderSection";

const NotificationForMaxNumber = ({ template_id_temp, maxCap }) => {
	const { t } = useTranslation();

	const temp = Object.keys(GLOBAL_VARIABLES.templates).find(
		(key) => GLOBAL_VARIABLES.templates[key] === template_id_temp
	);

	const maxNumOfReservations = GLOBAL_VARIABLES.maxNumOfReservations.find(
		(i) => i.name === temp
	).value;

	return (
		<p
			style={{
				//margin: "3px",
				fontFamily: "Rubik",
				fontWeight: 400,
				color: "grey",
			}}
		>
			{t("Max. number of reservation by once is")}
			<strong>
				{maxCap > maxNumOfReservations ? maxNumOfReservations : maxCap}
			</strong>
			{"."}
		</p>
	);
};

const AccordionDetails = withStyles({
	root: {
		padding: 0,
		display: "grid",
		gridTemplateColumns: "1fr",
		gridTemplateRows: "auto",
		rowGap: "12px",
		gridTemplateAreas: '"itemDesc" "itemOrder" "itemReview"',
	},
})(MuiAccordionDetails);

const DetailsImageless = (props) => {
	const { t } = useTranslation();
	const oneItemData = useSelector((state) => state.browse?.oneItem?.data);
	const oneItemLoading = useSelector((state) => state.browse?.oneItem?.loading);
	const cartItemsData = useSelector((state) => state.cart?.items?.data);
	const template_id_temp = useSelector(
		(state) => state.browse?.oneItem?.data?.category?.template
	);
	const calendar_max_capacity = useSelector(
		(state) => state.browse?.oneItem?.data?.calendar_max_capacity
	);

	let itemEl = null;
	const {
		id,
		expanded,
		title,
		reviewsString,
		classes,
		price,
		status,
		currency,
		descriptionText,
		handleAdd,
		handleRemove,
		setOpenReviews,
		handleChange,
	} = props;

	function transform(node, index) {
		if (node.type === "tag" && node.name === "img") {
			return (
				<img
					src={node.attribs.src}
					style={{ maxWidth: "100%", maxHeight: "100vh", margin: "auto" }}
				/>
			);
		}
	}

	const options = {
		decodeEntities: true,
		transform,
	};

	useEffect(() => {
		setTimeout(() => {
			if (expanded === id) {
				itemEl = document.getElementById(`item${expanded}`);
				itemEl?.offsetHeight <= screen.height
					? detectSafari()
						? elementScrollIntoView(itemEl, {
								behavior: "smooth",
								block: "center",
								inline: "nearest",
						  })
						: itemEl?.scrollIntoView({
								behavior: "smooth",
								block: "center",
								inline: "nearest",
						  })
					: detectSafari()
					? elementScrollIntoView(itemEl, {
							behavior: "smooth",
							block: "start",
							inline: "nearest",
					  })
					: itemEl?.scrollIntoView({
							behavior: "smooth",
							block: "start",
							inline: "nearest",
					  });
			}
		}, 400);

		return () => {};
	}, []);

	return (
		<AccordionDetails onClick={handleChange(id)}>
			<div className={classes.itemDescImageless}>
				<div className={classes.itemTitle}>
					<Typography className={classes.detailsTitle}>{t(title)}</Typography>
				</div>
				<div className={classes.itemButtons}>
					{price > 0 && (
						<Price
							label={`${price ? price.toFixed(2) : ""} ${currency || ""}`}
							customStyle={{
								padding: "4px",
								fontSize: "14px",
							}}
						/>
					)}
					{template_id_temp === GLOBAL_VARIABLES.templates.appointment && (
						<Box
							style={{
								marginLeft: "12px",
								display: "flex",
								flexDirection: "row",
								alignItems: "flex-start",
								paddingTop: "4px",
							}}
						>
							<AccessTimeIcon color="default" />
							<Typography
								style={{ marginLeft: "4px", color: "dimgray" }}
								variant="subtitle1"
							>{`${oneItemData.duration_value} min`}</Typography>
						</Box>
					)}
					{status && (
						<Tag
							label={status}
							priceExists={price > 0}
							customStyle={{
								padding: "4px",
								fontSize: "14px",
							}}
						/>
					)}
				</div>
				<div className={classes.itemText}>
					<Typography className={classes.itemTextContent}>
						{ReactHtmlParser(descriptionText, options)}
					</Typography>
					{(template_id_temp === GLOBAL_VARIABLES.templates.appointment ||
						template_id_temp === GLOBAL_VARIABLES.templates.date_range) &&
					calendar_max_capacity > 1 ? (
						<NotificationForMaxNumber
							template_id_temp={template_id_temp}
							maxCap={calendar_max_capacity}
						/>
					) : null}
				</div>
			</div>
			<div className={classes.itemOrder}>
				<OrderSection />
			</div>
			<div
				onClick={(event) => {
					event.stopPropagation();
					setOpenReviews(oneItemData?.average_rating !== null);
				}}
				className={classes.itemReview}
			>
				{oneItemLoading ? (
					<FullScreenSpinner />
				) : oneItemData?.average_rating ? (
					<>
						<div className={classes.itemReviewTitle}>
							<ReviewStar
								label={oneItemData?.average_rating}
								customStyle={{ float: "left" }}
							/>
							<Typography className={classes.reviewsString}>
								{`${oneItemData?.number_of_reviews} ${t(
									reviewsString
								).toUpperCase()}`}
							</Typography>{" "}
							<IconButton
								customStyle={{ float: "right", padding: "0px" }}
								color="default"
								icon={<ArrowForwardIosIcon />}
							/>
						</div>
						<div className={classes.itemReviewContent}>
							{oneItemData?.reviews?.length > 0 && (
								<SingleReview
									variant="subtitle1"
									name={
										oneItemData?.reviews[0].guest.first_name +
										" " +
										oneItemData?.reviews[0].guest.last_name
									}
									date={oneItemData?.reviews[0].order_item?.review_time}
									stars={oneItemData?.reviews[0].order_item?.review_point}
									reviewDesc={
										oneItemData?.reviews[0].order_item?.review_description
									}
									customStyle={{ size: "small" }}
								/>
							)}
						</div>
					</>
				) : (
					<div className={classes.itemReviewTitle}>
						<Typography
							className={classes.itemReviewTitleText}
							variant="subtitle1"
						>
							{t("not rated").toUpperCase()}
						</Typography>
					</div>
				)}
			</div>
		</AccordionDetails>
	);
};

DetailsImageless.defaultProps = {
	title: "Title",
	classes: {},
	id: "",
	descriptionText: "",
	reviewsString: "",
	price: "",
	status: null,
	currency: null,
	handleAdd: () => console.log("add"),
	handleRemove: () => console.log("remove"),
	handleChange: () => console.log("change"),
	setOpenReviews: () => console.log("reviews"),
};

export default memo(DetailsImageless);

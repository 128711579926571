// React
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GLOBAL_VARIABLES } from "Util/idConfig";
import { htmlToText } from "html-to-text";

// Mui
import { Box, makeStyles, Typography } from "@material-ui/core";

// Atoms
import Price from "Components/atoms/UI/AlternativePrice";
import Tag from "Pages/browse/components/molecules/Tag";

const useStyles = makeStyles((theme) => ({
  title: {
    gridArea: "title",
    paddingLeft: "8px",

    paddingTop: "4px",
  },
  titleHeader: {
    ontSize: "15px",
    fontWeight: "600",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  titleDesc: {
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: "13px",
    mozLineHeight: "13px",
    fontSize: "12px",
    fontWeight: "normal",
    color: "dimgrey",
  },
  itemMiniImage: {
    gridArea: "img",
    width: "100%",
    minHeight: "70px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px",
  },
  priceTag: {
    paddingLeft: "8px",
    gridArea: "priceTag",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    inlineSize: "fit-content",
  },
}));

const style = {
  width: "100%",
  height: 70,
  margin: 0,
  padding: 0,
  display: "grid",
  gridTemplateColumns: "70px 1fr 1fr 1fr 1fr",
  gridTemplateRows: "43px 27px",
  gridColumnGap: "0px",
  gridRowGap: "0px",
  gridTemplateAreas:
    '"img title title title title" "img priceTag priceTag priceTag priceTag"',
};

const SearchCardContent = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const lists = useSelector((state) => state.lists.data);
  const marked_list = lists.find((item) => item.Marked);
  const currency = useSelector((state) => state.app?.theme?.currency);
  function getMarkedStatus(id) {
    const marked = marked_list
      ? marked_list.Marked.find((item) => item.id === id)
      : null;
    if (marked?.id !== GLOBAL_VARIABLES.marked_list.without_header) {
      return marked?.text;
    }
    return null;
  }

  const { image, title, description, price, status } = props;
  return (
    <Box sx={style}>
      <div
        className={classes.itemMiniImage}
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>
      <div className={classes.title}>
        <Typography className={classes.titleHeader}>{t(title)}</Typography>
        <Typography className={classes.titleDesc} variant="caption">
          {htmlToText(description).replaceAll('*', '')}
        </Typography>
      </div>
      <div className={classes.priceTag}>
          {
              price > 0 &&
              <Price
          label={`${price ? price.toFixed(2) : ""} ${currency || ""}`}
          customStyle={{
            padding: "0 3px",
            fontSize: "13px",
          }}
        />
          }
        
        {status && (
          <Tag
            label={getMarkedStatus(status)}
            priceExists={price > 0}
            customStyle={{
              padding: "0 3px",
              fontSize: "13px",
            }}
          />
        )}
      </div>
    </Box>
  );
};

SearchCardContent.defaultProps = {
  image: "",
  title: "Title",
  description: "description",
  price: "",
  currency: null,
};

export default memo(SearchCardContent);

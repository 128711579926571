// MUI
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#0072D4",
    },
    secondary: {
      main: "#00A1BA",
    },
    background: {
      default: "#fff",
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: ["Rubik", "Roboto"].join(","),
  },
  overrides: {
    MuiButton: {
      label: {
        paddingTop: "2px",
        alignItems: "normal",
      },
    },
  },
});

export default theme;

// React
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// MUI
import SkeletonMUI from "@material-ui/lab/Skeleton";

const style = makeStyles(() => ({
  shape: {
    marginBottom: "10px",
    marginRight: "5px",
    borderRadius: "10px",
  },
}));

const Skeleton = (props) => {
  const { height, width, variant, animation, customStyle } = props;
  const classes = style();

  return (
    <SkeletonMUI
      className={classes.shape}
      variant={variant}
      width={width}
      height={height}
      animation={animation}
      style={customStyle}
    />
  );
};

Skeleton.defaultProps = {
  animation: "wave",
  variant: "rect",
  customStyle: {},
  width: "100%",
  height: "30px",
};

export default Skeleton;

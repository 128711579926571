// React
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";

// Material UI
import DialogMUI from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";

// MUI
import Rating from "@material-ui/lab/Rating";
import TextField from "@material-ui/core/TextField";

// Molecules
import TitleWithDivider from "Components/molecules/TitleWithDivider";

// Atoms
import Button from "Components/atoms/buttons/Button";
import { Typography } from "@material-ui/core";

// Actions
import { rateOurApp } from "Modules/units/Rate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  rating: {
    display: "flow-root",
    boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
    borderRadius: "10px",
    marginTop: "10px",
    height: "60px",
    textAlign: "center",
    paddingTop: "6px",
  },
  ratingStar: {
    color: theme.palette.primary.main,
  },
}));

const RateApp = ({ opened, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [stars, onSetStarts] = useState(null);
  const [text, onSetText] = useState(null);

  const handleRating = () => {
    if (stars) {
      dispatch(
        rateOurApp({
          review_point: stars,
          review_description: text !== "" ? text : null,
        })
      );
    } else {
      NotificationManager.error(t("Please select stars from 1 to 5!"));
    }
  };

  return (
    <div>
      <DialogMUI
        TransitionComponent={Transition}
        open={opened}
        // onClose={handleClose}
      >
        <div style={{ padding: "15px", paddingBottom: "0px" }}>
          <TitleWithDivider
            title={t("Rate our app")}
            size="18px"
            width="40px"
          />
          <Typography>
            {t(
              "Your review will help us improve GuestApp. Please, give us your honest opinon!"
            )}
          </Typography>
          <Box className={classes.rating}>
            <Rating
              className={classes.ratingStar}
              style={{
                fontSize: "-webkit-xxx-large",
              }}
              // name={item.id}
              value={stars}
              onChange={(e) => onSetStarts(e.target.value)}
            />
          </Box>
          <br />
          <TextField
            multiline
            rows={4}
            placeholder={t("Type here...")}
            variant="outlined"
            fullWidth
            value={text === null ? "" : text}
            onChange={(e) => onSetText(e.target.value)}
          />
        </div>

        <DialogActions
          style={{ display: "block", padding: "15px", paddingTop: "0px" }}
        >
          <Button
            onClick={handleRating}
            label={t("rate")}
            variant="contained"
            customStyle={{
              width: "100%",
              height: "40px",
              marginTop: "10px",
              marginLeft: "0px",
              boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
            }}
          />
          <Button
            onClick={handleClose}
            label={t("not now")}
            variant="contained"
            customStyle={{
              width: "100%",
              height: "40px",
              boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
              fontWeight: "bold",
              margin: "0px",
              marginTop: "8px",
            }}
            customFontColor={true}
          />
        </DialogActions>
      </DialogMUI>
    </div>
  );
};

export default RateApp;

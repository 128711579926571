// React
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { GLOBAL_VARIABLES } from "Util/idConfig";
import { scrollIntoView } from "seamless-scroll-polyfill";

// Util functions
import {
	getCookie,
	usePersistedState,
	disableScroll,
	enableScroll,
	detectMi,
} from "Util/functions";

// MUI
import SlideChip from "Components/atoms/chips/SlideChip";
import CardDistributor from "Components/molecules/CardDistributor";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";

// Accordion
import AccordionContent from "Pages/browse/components/Accordion/AccordionContent";
import AccordionContentImageless from "Pages/browse/components/Accordion/AccordionContentImageless";
//import Tutorial from "Pages/browse/components/molecules/Tutorial";

// Swiper
import { useSwipeable } from "react-swipeable";

// Atoms
import Skeleton from "Components/atoms/UI/Skeleton";
import IconButton from "Components/atoms/buttons/IconButton";

//Molecules
import TitleWithDivider from "Components/molecules/TitleWithDivider";
import OrgNavigation from "./components/molecules/OrgNavigation";

import {
	getAllActiveSubcategories,
	getAllItemsByChosenOptions,
	getAllOrgsWithAtLeastOneItem,
	clearSubcatAndItems,
	getOneItem,
} from "Modules/units/Browse";
import { getAllLists } from "Modules/units/Lists";

const style = makeStyles(() => ({
	display: {
		color: "black",
		fontSize: "20px",
		overflowX: detectMi() ? "hidden" : "initial",
	},
	slider: {
		display: "flex",
		overflowX: "auto",
		scrollSnapType: "none",
		"-webkit-overflow-scrolling": "touch",
		//height: "48px",
		paddingLeft: "5px",
		margin: "0 -10px 0px -10px",
	},
}));

const config = [
	{
		delta: 50, // min distance(px) before a swipe starts. *See Notes*
		preventDefaultTouchmoveEvent: true, // call e.preventDefault *See Details*
		trackTouch: true, // track touch input
		trackMouse: false, // track mouse input
		rotationAngle: 0, // set a rotation angle
	},
];

const SelectViewCollapse = ({ getHandler }) => {
	const dispatch = useDispatch();
	const appTheme = useSelector((state) => state.app?.theme);
	const auth = useSelector((state) => state.auth);
	const browse = useSelector((state) => state.browse);
	const orgsData = useSelector((state) => state.browse?.orgs?.data);
	const subcategoriesData = useSelector(
		(state) => state.browse?.subcategories?.data
	);
	const lists = useSelector((state) => state.lists.data);
	const displayImages = useSelector(
		(state) => state.browse?.items?.displayImages
	);

	let { id } = useParams();

	const classes = style();
	const history = useHistory();

	const [selectedOrganisation, setOrg] = usePersistedState(
		"selectedOrganisation",
		""
	);
	const [item, setItem] = usePersistedState("item", "");
	const [type, setType] = usePersistedState("type", "");
	const marked_list = lists.find((item) => item["Marked"]);
	const curr = JSON.parse(localStorage.getItem("theme"))?.currency;
	const choosenCategory = JSON.parse(localStorage.getItem("clickedCategory"));
	const [controlState, setControlState] = useState();
	const [expanded, setExpanded] = useState(false);
	const forStatistics = JSON.parse(localStorage.getItem("forStatistics"));
	const contentRef = useRef(null);

	const disableSwipeHandlers = useSwipeable({
		onSwiping: ({ event }) => {
			event.stopPropagation();
		},
	});

	const handlers = useSwipeable({
		onSwipeStart: ({ dir }) => {
			if (
				dir === "Right" &&
				contentRef.current &&
				!contentRef.current.classList.contains("animate__slideOutRight")
			) {
				disableScroll();
				contentRef.current.classList.add(
					"animate__animated",
					"animate__slideOutRight"
				);
			} else if (
				dir === "Left" &&
				contentRef.current &&
				!contentRef.current.classList.contains("animate__slideOutLeft")
			) {
				disableScroll();
				contentRef.current.classList.add(
					"animate__animated",
					"animate__slideOutLeft"
				);
			}
		},
		onSwipedLeft: () => {
			let subCatIndex = subcategoriesData?.findIndex((el) => el.id === type);
			if (subCatIndex + 1 !== subcategoriesData?.length) {
				let subCatId = subcategoriesData[subCatIndex + 1]?.id;
				setType(subCatId);
				scrollIntoView(document.getElementById(`option${subCatId}`), {
					behavior: "smooth",
					inline: "center",
					block: "nearest",
				});

				if (contentRef.current.classList.contains("animate__slideOutRight")) {
					contentRef.current.classList.remove(
						"animate__animated",
						"animate__slideOutRight"
					);
				} else if (
					contentRef.current.classList.contains("animate__slideOutLeft")
				) {
					contentRef.current.classList.remove(
						"animate__animated",
						"animate__slideOutLeft"
					);
				}
				contentRef.current.classList.add(
					"animate__animated",
					"animate__slideInRight",
					"animate__faster"
				);

				dispatch(
					getAllItemsByChosenOptions(
						getCookie("lang", auth),
						selectedOrganisation,
						id,
						subCatId
					)
				);
				setTimeout(() => {
					contentRef.current.classList.remove(
						"animate__animated",
						"animate__slideInRight",
						"animate__faster"
					);
				}, 150);
			} else {
				if (contentRef.current.classList.contains("animate__slideOutRight")) {
					contentRef.current.classList.remove(
						"animate__animated",
						"animate__slideOutRight"
					);
				} else if (
					contentRef.current.classList.contains("animate__slideOutLeft")
				) {
					contentRef.current.classList.remove(
						"animate__animated",
						"animate__slideOutLeft"
					);
				}
				contentRef.current.classList.add(
					"animate__animated",
					"animate__headShake",
					"animate__faster"
				);
			}
			enableScroll();
		},
		onSwipedRight: () => {
			let subCatIndex = subcategoriesData?.findIndex((el) => el.id === type);
			if (subCatIndex > 0) {
				let subCatId = subcategoriesData[subCatIndex - 1]?.id;
				setType(subCatId);
				scrollIntoView(document.getElementById(`option${subCatId}`), {
					behavior: "smooth",
					inline: "center",
					block: "nearest",
				});
				if (contentRef.current.classList.contains("animate__slideOutRight")) {
					contentRef.current.classList.remove(
						"animate__animated",
						"animate__slideOutRight"
					);
				} else if (
					contentRef.current.classList.contains("animate__slideOutLeft")
				) {
					contentRef.current.classList.remove(
						"animate__animated",
						"animate__slideOutLeft"
					);
				}
				contentRef.current.classList.add(
					"animate__animated",
					"animate__slideInLeft",
					"animate__faster"
				);

				dispatch(
					getAllItemsByChosenOptions(
						getCookie("lang", auth),
						selectedOrganisation,
						id,
						subCatId
					)
				);
				setTimeout(() => {
					contentRef.current.classList.remove(
						"animate__animated",
						"animate__slideInLeft",
						"animate__faster"
					);
				}, 150);
			} else {
				if (contentRef.current.classList.contains("animate__slideOutRight")) {
					contentRef.current.classList.remove(
						"animate__animated",
						"animate__slideOutRight"
					);
				} else if (
					contentRef.current.classList.contains("animate__slideOutLeft")
				) {
					contentRef.current.classList.remove(
						"animate__animated",
						"animate__slideOutLeft"
					);
				}
				contentRef.current.classList.add(
					"animate__animated",
					"animate__headShake"
					//"animate__fast"
				);
			}
			enableScroll();
		},
		onSwipedDown: () => {
			if (contentRef.current.classList.contains("animate__slideOutRight")) {
				contentRef.current.classList.remove(
					"animate__animated",
					"animate__slideOutRight"
				);
			} else if (
				contentRef.current.classList.contains("animate__slideOutLeft")
			) {
				contentRef.current.classList.remove(
					"animate__animated",
					"animate__slideOutLeft"
				);
			}
			enableScroll();
		},
		onSwipedUp: () => {
			if (contentRef.current.classList.contains("animate__slideOutRight")) {
				contentRef.current.classList.remove(
					"animate__animated",
					"animate__slideOutRight"
				);
			} else if (
				contentRef.current.classList.contains("animate__slideOutLeft")
			) {
				contentRef.current.classList.remove(
					"animate__animated",
					"animate__slideOutLeft"
				);
			}
			enableScroll();
		},
		config,
	});

	useEffect(() => {
		getHandler(handlers);
		return () => {};
	}, []);

	useEffect(() => {
		if (lists?.length === 0) {
			dispatch(getAllLists());
		}
	}, [lists]);

	useEffect(() => {
		localStorage.setItem(
			"forStatistics",
			JSON.stringify(GLOBAL_VARIABLES.forStatistics.selectView)
		);
		localStorage.removeItem("lastOpenedOrder");
		dispatch(getAllOrgsWithAtLeastOneItem(id));

		type_refs && type_refs[type]?.current?.scrollIntoViewIfNeeded(true);

		org_refs &&
			org_refs[selectedOrganisation]?.current?.scrollIntoViewIfNeeded(true);

		if (browse?.orgs?.data?.length > 0) {
			dispatch(
				getAllActiveSubcategories(
					getCookie("lang", auth),
					browse?.orgs?.data[0].org_core_id,
					id
				)
			);
			// dispatch(
			//   getAllItemsByChosenOptions(
			//     getCookie("lang", auth),
			//     browse?.orgs?.data[0].org_core_id,
			//     id,
			//     type === ""
			//       ? browse?.subcategories?.data?.length > 0 &&
			//           browse?.subcategories?.data[0].id
			//       : type
			//   )
			// );
			org_refs &&
				org_refs[selectedOrganisation]?.current?.scrollIntoViewIfNeeded(true);
		}

		return () => {
			dispatch(clearSubcatAndItems());
		};
	}, []);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
		if (panel !== expanded) {
			dispatch(getOneItem(getCookie("lang", auth), panel, forStatistics));
		}
	};

	useEffect(() => {
		if (controlState === "none")
			history.push("/singleview/" + item + "/" + null);
	}, [controlState]);

	const handleChooseItemsORG = (chosenOrg) => {
		setOrg(chosenOrg);
		dispatch(getAllActiveSubcategories(getCookie("lang", auth), chosenOrg, id));
		setType("");
	};

	const handleChooseItemsORGprev = (currIndex) => {
		let chosenOrg = orgsData[currIndex - 1]?.org_core_id;
		setOrg(chosenOrg);
		dispatch(getAllActiveSubcategories(getCookie("lang", auth), chosenOrg, id));
		setType("");
	};

	const handleChooseItemsORGnext = (currIndex) => {
		let chosenOrg = orgsData[currIndex + 1]?.org_core_id;
		console.log(chosenOrg);
		setOrg(chosenOrg);
		dispatch(getAllActiveSubcategories(getCookie("lang", auth), chosenOrg, id));
		setType("");
	};

	/*const handleChooseItemsTYPE = chosenType => {
    setType(chosenType);
    dispatch(
      getAllItemsByChosenOptions(
        getCookie("lang", auth),
        selectedOrganisation,
        id,
        chosenType
      )
    );
  };*/

	const handleChooseItemsTYPE = (event, chosenType) => {
		event.preventDefault();
		setType(chosenType);
		scrollIntoView(document.getElementById(`option${chosenType}`), {
			behavior: "smooth",
			inline: "center",
			block: "nearest",
		});

		dispatch(
			getAllItemsByChosenOptions(
				getCookie("lang", auth),
				selectedOrganisation,
				id,
				chosenType
			)
		);
	};

	/*useEffect(() => {
    if (browse?.subcategories?.data?.length > 0) {
      if (type === "") {
        setType(browse?.subcategories?.data[0]?.id);
        dispatch(
          getAllItemsByChosenOptions(
            getCookie("lang", auth),
            selectedOrganisation,
            id,
            browse?.subcategories?.data[0].id
          )
        );
      }
      type_refs && type_refs[type]?.current?.scrollIntoViewIfNeeded(true);
    }
  }, [browse?.subcategories?.data]);*/

	useEffect(() => {
		if (subcategoriesData?.length > 0) {
			type === ""
				? setType(subcategoriesData[0]?.id)
				: (scrollIntoView(document.getElementById(`option${type}`), {
						behavior: "smooth",
						inline: "center",
						block: "nearest",
				  }),
				  !detectMi()
						? null
						: (document.getElementById("display").style.overflowX = "unset"));

			dispatch(
				getAllItemsByChosenOptions(
					getCookie("lang", auth),
					selectedOrganisation,
					id,
					type === "" ? subcategoriesData[0]?.id : type
				)
			);
		}
	}, [subcategoriesData]);

	useEffect(() => {
		if (browse?.items?.data?.length > 0) {
			items_refs && items_refs[item]?.current?.scrollIntoViewIfNeeded(true);
		}
	}, [browse?.items?.data]);

	useEffect(() => {
		if (browse?.orgs?.data?.length > 0) {
			selectedOrganisation === ""
				? setOrg(
						browse?.orgs?.data?.length > 0
							? browse?.orgs?.data[0].org_core_id
							: ""
				  )
				: null;
			dispatch(
				getAllActiveSubcategories(
					getCookie("lang", auth),
					selectedOrganisation === ""
						? browse?.orgs?.data[0].org_core_id
						: selectedOrganisation,
					id
				)
			);
			type
				? dispatch(
						getAllItemsByChosenOptions(
							getCookie("lang", auth),
							selectedOrganisation === ""
								? browse?.orgs?.data[0].org_core_id
								: selectedOrganisation,
							id,
							type
						)
				  )
				: null;
			org_refs &&
				org_refs[selectedOrganisation]?.current?.scrollIntoViewIfNeeded(true);
		}
	}, [browse?.orgs?.data]);

	useEffect(() => {
		type_refs && type_refs[type]?.current?.scrollIntoViewIfNeeded(true);
	}, [type_refs]);

	useEffect(() => {
		org_refs &&
			org_refs[selectedOrganisation]?.current?.scrollIntoViewIfNeeded(true);
	}, [org_refs]);

	useEffect(() => {
		items_refs && items_refs[item]?.current?.scrollIntoViewIfNeeded(true);
	}, [items_refs]);

	function getMarkedStatus(id) {
		const marked = marked_list
			? marked_list["Marked"].find((item) => item.id === id)
			: null;
		if (marked?.id !== GLOBAL_VARIABLES.marked_list.without_header) {
			return marked.text;
		} else return null;
	}

	const type_refs = browse?.subcategories?.data?.reduce((acc, value) => {
		acc[value.id] = React.createRef();
		return acc;
	}, {});

	const org_refs = browse?.orgs?.data?.reduce((acc, value) => {
		acc[value.org_core_id] = React.createRef();
		return acc;
	}, {});

	const items_refs = browse?.items?.data?.reduce((acc, value) => {
		acc[value.id] = React.createRef();
		return acc;
	}, {});

	useEffect(() => {
		return () => {
			contentRef.current = null;
		};
	}, []);

	return (
		<>
			<div className={classes.display}>
				<TitleWithDivider
					title={choosenCategory?.name}
					dataCy={"nameOnSelectView"}
				/>

				{browse?.orgs?.loading ? (
					<div style={{ display: "flex" }}>
						<Skeleton height="32px" />
					</div>
				) : orgsData?.length < 2 ? null : (
					<OrgNavigation
						length={orgsData?.length}
						org={orgsData?.find((i) => i.org_core_id === selectedOrganisation)}
						index={orgsData?.findIndex(
							(el) => el.org_core_id === selectedOrganisation
						)}
						onClickPrev={handleChooseItemsORGprev}
						onClickNext={handleChooseItemsORGnext}
					/>
				)}

				{browse?.subcategories?.loading ? (
					<div className={classes.slider}>
						<Skeleton height="32px" />
						<Skeleton height="32px" />
						<Skeleton height="32px" />
					</div>
				) : browse?.subcategories?.data?.length === 0 ? null : (
					<div {...disableSwipeHandlers} className={classes.slider}>
						{browse?.subcategories?.data?.map((i) => (
							<div
								/*ref={el => {
                  type_refs[i.id].current = el;
                }}*/
								key={i.id}
								id={`option${i.id}`}
							>
								<SlideChip
									label={i.name}
									id={i.id}
									customStyle={{ width: "auto" }}
									color={i.id === type}
									onClick={(e) => {
										handleChooseItemsTYPE(e, i.id);
									}}
								/>
							</div>
						))}
					</div>
				)}

				<div
					ref={contentRef}
					style={{
						margin: !detectMi() ? "20px 0px" : "20px 2px",
					}}
				>
					{browse?.items?.loading ? (
						<Box mt={2}>
							<Skeleton height="78px" />
							<Skeleton height="78px" />
							<Skeleton height="78px" />
						</Box>
					) : displayImages !== false ? (
						browse?.items?.data?.map((i) => (
							<div style={{ marginTop: "12px" }} key={i.id}>
								<AccordionContent
									expanded={expanded}
									handleChange={handleChange}
									title={i.name}
									id={i.id}
									image={i.gallery && i.gallery.length > 0 && i.gallery[0].path}
									descriptionText={i.description}
									price={i.price}
									currency={curr}
									status={i.marked ? getMarkedStatus(i.marked) : null}
								/>
							</div>
						))
					) : (
						browse?.items?.data?.map((i) => (
							<div style={{ marginTop: "12px" }} key={i.id}>
								<AccordionContentImageless
									expanded={expanded}
									handleChange={handleChange}
									title={i.name}
									id={i.id}
									descriptionText={i.description}
									price={i.price}
									currency={curr}
									status={i.marked ? getMarkedStatus(i.marked) : null}
								/>
							</div>
						))
					)}
				</div>
			</div>
		</>
	);
};

export default SelectViewCollapse;

// React
import React from "react";
import { useTranslation } from "react-i18next";

// Material UI
import DialogMUI from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

//Atoms
import IconButton from "Components/atoms/buttons/IconButton";
import Button from "Components/atoms/buttons/Button";

const Dialog = ({
  opened,
  handleClose,
  title,
  content,
  textContent,
  contentInfo,
  handleAccept,
  cancelOrderButton,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <DialogMUI
        open={opened}
        // onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle
          style={{
            // whiteSpace: "nowrap",
            // textOverflow: "ellipsis",
            // overflow: "hidden",
          }}
          id="alert-dialog-title"
        >
          {t(title).toUpperCase()}
          <IconButton
            onClick={handleClose}
            icon={<CloseIcon />}
            customStyle={{ float: "right" }}
          />
        </DialogTitle>
        {contentInfo ? (
          <DialogContent
            dividers
            style={{
              padding: "10px",
              // height: "67px",
              overflowY: "unset",
            }}
          >
            {contentInfo}
          </DialogContent>
        ) : null}
        <DialogContent dividers style={{ padding: "0px 10px 10px 10px" }}>
          {textContent ? (
            <DialogContentText style={{ textAlign: "center" }}>
              {t(textContent)}
            </DialogContentText>
          ) : null}
          {content}
          {cancelOrderButton && (
            <DialogActions>
              <Button
                onClick={cancelOrderButton}
                label={t("yes, i am sure")}
                variant="contained"
                customStyle={{
                  width: "100%",
                  height: "80px",
                  marginTop: "10px",
                  marginLeft: "0px",
                  boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
                  fontWeight: "bold",
                }}
              />
            </DialogActions>
          )}
        </DialogContent>
        {/* {handleClose && (
          <DialogActions>
            <Button
              onClick={handleClose}
              label={"Close"}
              customFontColor={true}
            />
          </DialogActions>
        )} */}
        {handleAccept && (
          <DialogActions>
            <Button
              onClick={handleAccept}
              label={t("cancel order")}
              customFontColor={true}
            />
          </DialogActions>
        )}
      </DialogMUI>
    </div>
  );
};

export default Dialog;

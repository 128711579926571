// React
import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// Material UI
import DialogMUI from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";

//Atoms
import Button from "Components/atoms/buttons/Button";
import { Typography } from "@material-ui/core";

// Image
import { ExceededLimitImg } from "Components/atoms/imgs/ExceededLimitImg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    // backgroundImage:
    //   "url(https://static.skillshare.com/uploads/project/257830/cover_1242_9ebab8afc03efcf78fa63a6157ae3ed2.png)",
    // backgroundColor: "#ffffff",
    height: "405px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    position: "relative",
    alignSelf: "center",
    display: "flex",
    alignItems: "center",
  },
}));

const ExceededLimitDialog = ({ opened, handleClose, putOnRoomBillAction }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const order = useSelector((state) => state.order);

  return (
    <div>
      <DialogMUI
        TransitionComponent={Transition}
        fullScreen
        open={opened}
        onClose={handleClose}
      >
        <Box style={{ textAlign: "center", marginTop: "20px" }}>
          <Typography style={{ fontSize: "xx-large" }}>
            {t(order?.exceededMsg)}
          </Typography>
        </Box>
        <div className={classes.imageStyle}>
          <ExceededLimitImg />
        </div>
        <Box style={{ textAlign: "center" }}>
          <Typography
            style={{
              position: "relative",
              fontSize: "large",
              width: "75%",
              margin: "auto",
            }}
          >
            {t("Do you want cancel an action or continue?")}
          </Typography>
        </Box>
        <DialogActions style={{ display: "block", padding: "15px" }}>
          <Button
            onClick={handleClose}
            label={t("close")}
            variant="contained"
            customStyle={{
              width: "100%",
              height: "75px",
              boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
              fontWeight: "bold",
            }}
            customFontColor={true}
          />
          <Button
            onClick={putOnRoomBillAction}
            label={t("continue")}
            variant="contained"
            customStyle={{
              width: "100%",
              height: "75px",
              marginTop: "10px",
              marginLeft: "0px",
              boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
            }}
          />
        </DialogActions>
      </DialogMUI>
    </div>
  );
};

export default ExceededLimitDialog;

module.exports = {
	"Invalid code!": "Nevaljan kod!",
	Food: "Hrana",
	Drinks: "Piće",
	Massage: "Masaža",
	Cleaning: "Čišćenje",
	Taxi: "Taxi",
	Souvenirs: "Suvenirnica",
	"CHOOSE QUANTITY AND EXTRAS": "ODABERI KOLIČINU I OSTALO",
	"SELECT THE NUMBER OF TICKETS": "ODABERITE BROJ ULAZNICA",
	"choose quantity and extras": "odaberi količinu i ostalo",
	"Choose quantity": "Odaberi količinu",
	"select the number of tickets": "odaberite broj ulaznica",
	"Select the number of tickets": "Odaberite broj ulaznica",
	reviews: "recenzije",
	Close: "Zatvori",
	New: "Novo",
	all: "sve",
	Pizza: "Pizza",
	Pasta: "Tjestenina",
	Fish: "Riba",
	"my cart": "moja košarica",
	checkout: "račun",
	"remove all": "izbriši sve",
	"Delivery schedule may differ because items are from different locations.":
		"Vrijeme dostave može odstupati jer su stavke sa različitih lokacija.",
	"TOTAL ITEMS": "UKUPNO ARTIKALA",
	"Show details": "Pokaži detalje",
	appointments: "rezervacije",
	events: "događaji",
	Ketchup: "Kečap",
	Mayonnaise: "Majoneza",
	"Olive oil": "Maslinovo ulje",
	"Hot sauce": "Ljuti umak",
	extras: "dodaci",
	quantity: "količina",
	"add to cart": "dodaj u košaricu",
	"room bill": "stavi na račun sobe",
	"pay with card": "plati karticom",
	"Order successfully placed!": "Narudžba uspješno izvršena!",
	"You can always track your orders in ORDERS section under main menu.":
		"Uvijek možete pratiti svoje naružbe u odjeljku NARUŽBE u glavnom izborniku.",
	"order more": "naruči više",
	"my orders": "moje narudžbe",
	"My stay": "Moj boravak",
	"Rate your stay": "Ocjeni svoj boravak",
	"delete rating": "izbriši ocjene",
	accomodation: "smještaj",
	service: "usluga",
	review: "recenzija",
	"Type here...": "Piši ovdje...",
	confirm: "potvrdi",
	"Floor & Room": "Kat & Soba",
	"Departure time": "Vrijeme odlaska",
	"My balance": "Moj račun",
	"My spendings": "Moja potrošnja",
	Spendings: "Potrošnja",
	Spent: "Potrošeno",
	notifications: "obavijesti",
	"read all": "označi kao pročitano",
	Chips: "Čips",
	Tortillas: "Tortilje",
	Hamburger: "Hamburger",
	"special offer": "posebna ponuda",
	"Special offer": "Posebna ponuda",
	Popular: "Popularno",
	"today's specialities": "dnevna ponuda",
	"my timeline": "vremenska crta",
	pending: "u tijeku",
	finished: "završeno",
	"Swimming pool": "Bazen",
	rate: "ocjeni",
	"chat with triplehost": "pričaj sa triplehostom",
	"This feature is coming soon...": "Uskoro...",
	total: "ukupno",
	cancel: "zatvori",
	"view available termins": "pogledaj dostupno vrijeme",
	reserve: "rezerviraj",
	"MAKE AN APPOINTMENT": "ZAKAŽI TERMIN",
	"make an appointment": "zakaži termin",
	ORDER: "NARUČI",
	order: "naruči",
	"Odaberi početni datum": "Odaberi početni datum",
	"Odaberi krajnji datum": "Odaberi krajnji datum",
	"Cart contains invalid items": "Neispravne stavke u košarici",
	"Scheduled date is in past": "Zakazani datum je u prošlosti",
	"Date from is greater than date to":
		"Početni datum je veći od krajnjeg datuma",
	"Enter the amount": "Unesi iznos",
	"Set a limit": "Postavi ograničenje",
	Ban: "zabrana",
	Limitation: "ograničenje",
	"Not a valid number.": "Broj nije valjan",
	"Amount can not be negative ": "Iznos ne može biti u minusu",
	close: "zatvori",
	continue: "nastavi",
	"You have exceeded the limit": "Prekoračili ste ograničenje!",
	"Do you want cancel an action or continue?":
		"Želite li odustati ili nastaviti?",
	"Date to must be greater than date from":
		"Krajnji datum mora biti veći od početnog",
	"not rated": "nije ocijenjeno",
	"on room bill": "na račun sobe",
	"my orders & appointments": "moje narudžbe & termini",
	Accommodation: "Smještaj",
	Service: "Usluga",
	"A review": "Recenzija",
	"schedule delivery": "zakaži isporuku",
	home: "početna",
	browse: "traži",
	orders: "narudžbe",
	chat: "chat",
	profile: "profil",
	Continue: "Nastavi",
	Proceed: "Nastavi",
	cancelOrder: "otkaži narudžbu",
	cancelOrderText: "Jeste li sigurni da želite otkazati stavku sa narudžbe?",
	Free: "Besplatno",
	"Please pick an appointment!": "Molimo odaberite termin!",
	"Search...": "Pretraži...",
	"yes, i am sure": "da, siguran/a sam",
	"call off": "otkaži",
	Type: "Upiši još",
	"more characters": "znak/a",
	Search: "Traži...",
	"Please fill out the whole review!": "Molimo popunite cijelu recenziju!",
	"Chat is disabled": "Chat je onemogućen",
	"Add new conversation": "Dodaj novi razgovor",
	"Remove conversation": "Izbriši razgovor",
	"Type message here": "Upiši poruku ovdje",
	"Log out": "Odjavi se",
	"Guest is not assigned to Room": "Gost nije dodjeljen u sobu",
	"Order items have been added to Cart!":
		"Stavke iz narudžbe su dodane u košaricu!",
	"There isn't any orders! Make an order and it'll appear here!":
		"Nemate nijednu narudžbu! Napravite ju da bi se prikazala ovdje!",
	"No restrictions": "Bez ograničenja",
	Set: "Postavi",
	"Your review will help us improve GuestApp. Please, give us your honest opinon!":
		"Vaša ocjena će nam pomoći da poboljšamo GuestApp. Molimo vas, napišite nam svoje mišljenje!",
	"Card payment is not implemented yet!":
		"Plaćanje karticom za sad nije implementirano",
	"Scheduled time has to be greater than current time.":
		"Planirano vrijeme mora biti veće od trenutnog vremena.",
	"Closing time of this organisation is at ":
		"Radno vrijeme ove organizacije je do ",
	submit: "potvrdi",
	"Your rating is successfully saved": "Vaša ocjena je uspješno spremljena!",
	"Rate our app": "Ocjeni našu aplikaciju",
	"not now": "not now",
	"start making an appointment": "započni proces rezerviranja",
	"Max. number of reservation by once is":
		"Maks. broj rezerviranja odjednom je ",
	"Please follow these steps to make an appointment":
		"Kako biste rezervirali termin, molimo pratite sljedeće korake!",
	"Choose date and quantity": "Odaberi datum i količinu",
	"Choose date": "Odaberi datum",
	"and time": "i vrijeme!",
	Quantity: "Količina",
	"Choose time": "Odaberi termin:",
	Next: "Dalje",
	Back: "Natrag",
	"Item successfully added to Cart!": "Artikl je uspješno dodan u košaricu!",
	"Date and quantity": "Datum i količina",
	Time: "Odaberi termin",
	"Pick exact time": "Odaberi točan termin",
	"First, choose date and select quantity for how many reservations you want to make":
		"Prvo, odaberi datum i količinu rezervacija koje želiš napraviti...",
	"First, choose date": "Prvo, odaberi datum...",
	"and then, pick exact time of your appointment":
		"a zatim, odaberi točan termin rezervacije!",
	noSubscriptionsText:
		"Nemate nijednu pretplatu! Molimo vas, javite se na recepciju kako biste ju dobili!",
	"My subscriptions": "Moje pretplate",
	imePrezimeBrojSobe: "Ime i prezime (broj sobe):",
	duration: "Vrijedi:",
	"Check out": "Odjava",
	"how to use app": "Kako koristiti aplikaciju?",
	Refresh: "Osvježi",
	"Go to homepage": "Idi na početnu stranicu",
	"Something went wrong": "Došlo je do pogreške!",
	"Room number": "Broj sobe",
	"Currently, making an apointment is not available. Please, try again later!":
		"Trenutno, kreiranje rezervacije nije dostupno. Molimo, pokušajte ponovno kasnije!",
	"This item is already in the cart!": "Ovaj artikl je već u košarici!",
	"Choose duration": "Odaberi vremenski period",
	From: "Od",
	To: "Do",
	"Purchase date": "Datum kupnje",
	"Reserved for": "Rezervirano za:",
	//validacijske poruke
	"Your review for stay is successfully saved!":
		"Vaša recenzija boravka je uspješno spremljena!",
	"Your review for stay is successfully deleted!":
		"Vaša recenzija boravka je uspješno izbrisana!",
	"Limit is successfully updated!": "Ograničenje je uspješno ažurirano!",
	"You cannot cancel an order item": "Nije moguće otkazati stavku sa narudžbe",
	"Review is successfully saved!": "Ocjena je uspješno spremljena!",
	"Item is successfully canceled!": "Artikl je uspješno otkazan!",
	"The calendar has no dates available": "Kalendar nema dostupne termine",
	"Delivery time has to be after ": "Vrijeme dostave mora biti iza ",
	"Please select stars from 1 to 5!":
		"Molimo odaberite broj zvjezdica od 1 do 5!",
	"Star rating is required": "Ocjenjivanje zvjezdicom je obavezno",
	// Tutorial
	next: "dalje",
	back: "nazad",
	skip: "preskoči upute",
	tutTitle1: "Naručite poslugu u sobu",
	tutText1:
		"PRETRAŽI veliki izbor HRANE i PIĆA, dodaj što želiš u KOŠARICU i klikni RAČUN. Vaša narudžba će, u skorije vrijeme, biti dostavljena u Vašu sobu.",
	tutTitle2: "Napravite rezervaciju",
	tutText2:
		"Želiš napraviti REZERVACIJU u WELLNESS-u? Nije problem. Odaberi MASAŽU ili neki drugi tretman, provjeri dostupne TERMINE i odaberi jedan. Dodaj u košaricu i klikni RAČUN. To je to! Uspješno ste rezervirali USLUGU. Jedino što Vam preostaje je doći u zakazano vrijeme.",
	tutTitle3: "Rezerviraj sportsku aktivnost",
	tutText3:
		"Želiš li rezervirati TENISKI TEREN ili KAJAKE? Vrlo je jednostavno. Pretraži sportsku kategoriju i odaberi ono što želiš. Odaberi slobodan TERMIN i dodaj u KOŠARICU. To je to. Uspješno ste rezervirali SPORTSKU AKTIVNOST.",
	tutTitle4: "Pregledaj narudžbe",
	tutText4:
		"Nakon svake NARUDŽBE možeš pregledati sve naručene stavke na ekranu NARUDŽBE. Ako je naruždba završena, možeš napraviti RECENZIJU i pomoći sebi, drugim gostima i ovom smještaju da poboljša uslugu.",
	tutTitle5: "Informiraj se",
	tutText5:
		"Klikni na stavku TRAŽI, a onda na HOTELSKU MAPU. Ondje možeš pronaći raznovrsne i korisne informacije poput: KUĆNIH PRAVILA, PRIJEVOZA i KONTAKT informacija.",
	tutTitle6: "Ostani u toku",
	tutText6:
		"Na POČETNOJ STRANICI uvijek možeš vidjeti što je popularno u mjestu gdje odsjedate. Tu su informacije poput kada i gdje su sljedeći veliki događaji, poput KONCERATA, ZABAVA i drugih aktivnosti.",
	tutTitle7: "Postavi pitanje",
	tutText7:
		"Otvori stavku CHAT i slobodno postavi bilo koje pitanje. RECEPCIJA će vam odgovoriti u kratkom roku. Chat je dostupan 24/7.",
	tutTitle8: "Pogledaj informacije o boravku",
	tutText8:
		"Klikni na stavku PROFIL gdje možeš vidjeti informacije o boravku i svu POTROŠNJU u trenutnoj rezervaciji. Pogledaj sve svoje pretplate i koristi ih kao ulaznice za različita mjesta poput DORUČKA, BAZENA,...",
	"No results": "Nema rezultata pretraživanja",
	"Loading results": "Učitavanje rezultata",
	//
	"Pickup type": "Način preuzimanja",
	others: "ostalo",
	"Select one or more days": "Odaberite jedan ili više dana",
};

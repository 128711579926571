// React
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { NotificationManager } from "react-notifications";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Typography, Dialog, Slide, Box } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useTheme } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

//Icons
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AlarmIcon from "@material-ui/icons/Alarm";

//Atoms
import IconButton from "Components/atoms/buttons/IconButton";
import Button from "Components/atoms/buttons/Button";

//Molecules
import TitleWithDivider from "Components/molecules/TitleWithDivider";
import CardDistributor from "Components/molecules/CardDistributor";
import SetDurationTime from "Components/molecules/SetDurationTime";

//Dialogs
import DialogWithTwoSubmitButtons from "Components/dialogs/DialogWithTwoSubmitButtons";
import FullscreenDialogSuccess from "Components/dialogs/FullscreenDialogSuccess";
import ExceededLimitDialog from "Components/dialogs/ExceededLimitDialog";
import RateApp from "Components/dialogs/RateApp";

//Actions
import {
	roomBill,
	closeCheckoutDialog,
	clearErrors,
	closeExceededLimitDialog,
} from "Modules/units/Order";

import {
	setLocalStorageItemsInRedux,
	clearCart,
	duration,
	clearDuration,
} from "Modules/units/Cart";

import { openRateAppDialog, closeRateAppDialog } from "Modules/units/Rate";
import { getAllOrders } from "Modules/units/Order";

//Utils
import { getCookie, areArraysEqual, usePersistedState } from "Util/functions";
import { GLOBAL_VARIABLES } from "Util/idConfig";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "relative",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	reminderBox: {
		padding: "10px",
		backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
		borderRadius: "10px",
		color: "white",
		textAlign: "center",
	},
	deliveryBox: {
		padding: "5px",
		borderRadius: "10px",
		color: "grey",
		boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
		display: "flex",
		placeContent: "space-between",
	},
	checkoutBox: {
		padding: "15px 20px",
		bottom: "55px",
		position: "fixed",
		width: "100%",
		left: "0px",
		right: "0px",
	},
	redDot: {
		display: "inline-block",
		background: "red",
		borderColor: "transparent",
		borderStyle: " solid",
		borderRadius: "50%",
		// marginTop: "2px",
		marginLeft: "-10px",
		position: "fixed",
		height: "10px",
		width: "10px",
	},
	deliveryItem: {
		display: "flex",
		alignItems: "center",
		overflow: "hidden",
		whiteSpace: "pre",
	},
	globalWarning: {
		margin: "0px",
		boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
		textAlign: "center",
		backgroundColor: "indianred",
		borderRadius: "5px",
	},
	animate: {
		animation: "shake 0.82s cubic-bezier(.36,.07,.19,.97) both",
	},
	totalSection: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		padding: "4px",
		backgroundColor: "#fff",
		borderRadius: "4px 4px 0 0",
		opacity: "0.65",
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const Cart = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const theme = useTheme();
	const order = useSelector((state) => state.order);
	const cart = useSelector((state) => state.cart);
	const rate = useSelector((state) => state.rate);
	const lists = useSelector((state) => state.lists.data);
	const duration_list = lists?.find((item) => item["Durations"]);

	const { dataCy } = props;

	const [open, setOpen] = usePersistedState("cartOpen", false);
	const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
	const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
	const [global_errors_services, setGlobalErrorsServices] = useState(null);
	const [global_errors_lang, setGlobalErrorsLang] = useState(null);
	const [scheduledTime, setScheduledTime] = useState(new Date());
	// const [durationTimeState, setDurationTimeState] = useState(
	//   getNameOfDuration(0)
	// );
	const [durationsListState, setDurationListState] = useState([]);
	const organisations = JSON.parse(localStorage.getItem("orgsByLoggedUser"));
	const curr = JSON.parse(localStorage.getItem("theme"))?.currency;

	let [orders, forOrders] = useState([]);

	let [orderOrders, setOrderOrders] = useState([]);
	let [appointmentOrders, setAppointmentOrders] = useState([]);
	let [eventOrders, setEventOrders] = useState([]);
	let [dateOrders, setDateOrders] = useState([]);

	let [totalQuantity, setTotalQuantity] = useState(0);

	orders =
		JSON.parse(localStorage.getItem("order")) != null
			? JSON.parse(localStorage.getItem("order"))
			: [];

	const getOrderOrders = () => {
		setOrderOrders(
			orders.filter((i) => {
				return i.data?.category?.template === GLOBAL_VARIABLES.templates.order;
			})
		);
	};

	const getAppointmentOrders = () => {
		setAppointmentOrders(
			orders.filter((i) => {
				return (
					i.data?.category?.template === GLOBAL_VARIABLES.templates.appointment
				);
			})
		);
	};

	const getEventOrders = () => {
		setEventOrders(
			orders.filter((i) => {
				return i.data?.category?.template === GLOBAL_VARIABLES.templates.event;
			})
		);
	};

	const getDateOrders = () => {
		setDateOrders(
			orders.filter((i) => {
				return (
					i.data?.category?.template ===
						GLOBAL_VARIABLES.templates.date_range ||
					i.data?.category?.template === GLOBAL_VARIABLES.templates.date_picker
				);
			})
		);
	};

	const getTotalQuantity = () => {
		let count = 0;
		orders.map((i) => {
			count += i.quantity;
		});
		setTotalQuantity(count);
	};

	useEffect(() => {
		if (open) {
			getOrderOrders();
			getAppointmentOrders();
			getEventOrders();
			getDateOrders();
			getTotalQuantity();
		}

		return () => {};
	}, [open]);

	function calculateAmountAndAddCurrency() {
		let amount = orders?.reduce(function(acc, obj) {
			if (
				obj.data.category.template !== GLOBAL_VARIABLES.templates.date_range
			) {
				return (
					acc +
					(parseInt(obj.data.price) + chargeAsDishExtras(obj.extras)) *
						obj.quantity
				);
			} else return acc + parseInt(obj.data.price) * getQuantity(obj.quantity, obj.data.category.template, obj.date, obj.dateTo);
		}, 0);

		return amount !== 0 ? amount.toFixed(2) + " " + curr : null;
	}

	const handleRemoveItem = (id) => {
		orders = orders.filter((i) => {
			if (i.unique_id !== id) {
				return i;
			} else return null;
		});

		localStorage.setItem("order", JSON.stringify(orders));
		dispatch(setLocalStorageItemsInRedux(orders));
		dispatch(clearErrors());
		dispatch(clearDuration());
		forOrders(orders);
		//
		getOrderOrders();
		getAppointmentOrders();
		getEventOrders();
		getDateOrders();
		getTotalQuantity();

		if (orders.length === 0) {
			localStorage.removeItem("orderScheduleTemp");
		}
	};

	const handleRemoveAll = () => {
		localStorage.removeItem("order");
		localStorage.removeItem("orderScheduleTemp");
		dispatch(clearCart());
		dispatch(clearErrors());
		dispatch(clearDuration());
		forOrders([]);
		//
		setOrderOrders([]);
		setAppointmentOrders([]);
		setEventOrders([]);
		getDateOrders([]);
		setTotalQuantity(0);
	};

	const handleLocalStorage = (id, value) => {
		orders = orders.filter((i) => {
			if (i.unique_id === id) {
				i.quantity = value;
				return i;
			} else return i;
		});

		localStorage.setItem("order", JSON.stringify(orders));
		forOrders(orders);
		//
		getOrderOrders();
		getAppointmentOrders();
		getEventOrders();
		getDateOrders();
		getTotalQuantity();
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseExceededDialog = () => {
		dispatch(closeExceededLimitDialog());
	};

	const setParentState = (value) => {
		setScheduledTime(value);
	};

	const putOnRoomBillAction = (warningControl) => {
		const structured_orders = orders.map((i) => {
			return {
				service: {
					id: i.data.id,
				},
				quantity: i.quantity,
				start_date: i.date
					? moment(i.date).format("YYYY-MM-DD HH:mm:ss")
					: null,
				stop_date: i.dateTo
					? moment(i.dateTo).format("YYYY-MM-DD HH:mm:ss")
					: null,
				scheduled_time:
					i.date && i.dateTo === undefined
						? moment(i.date).format("YYYY-MM-DD HH:mm:ss")
						: null,
				extras: i.extras,
				delivery_type: i.pickupType ? i.pickupType : null,
				pick_up_place_id: i.deliveryOrg ? i.deliveryOrg : null,
				campaign:
					i.campaign_id != "null" && i.campaign_id != null
						? { id: i.campaign_id }
						: null,
			};
		});

		dispatch(
			roomBill({
				services: structured_orders,
				scheduled_time: moment(scheduledTime).format("YYYY-MM-DD HH:mm:ss"),
				skip_warning: warningControl,
				lang: getCookie("lang"),
			})
		);
	};

	useEffect(() => {
		if (order?.successfullyPaid) {
			setOpen(false);
			setOpenPaymentDialog(false);
			setOpenDialogSuccess(true);
			dispatch(clearCart());
			dispatch(closeCheckoutDialog());
			dispatch(clearErrors());
			dispatch(clearDuration());
			dispatch(closeExceededLimitDialog());
			//
			//   setOrderOrders([])
			//   setAppointmentOrders([])
			//   setEventOrders([])
		}
		if (order?.closeAfterError) {
			setOpenPaymentDialog(false);
			dispatch(closeCheckoutDialog());
		}
	});

	/* useEffect(() => {
    dispatch(getAllLists());
  }, []); */

	useEffect(() => {
		// localStorage.removeItem("lastOpenedOrder");
		if (cart?.duration === null || isNaN(cart?.duration)) {
			const longest_duration =
				orders?.length > 0
					? orders?.reduce((p, c) =>
							(p.data?.category?.template !==
								GLOBAL_VARIABLES.templates.appointment &&
								p.data?.category?.template !==
									GLOBAL_VARIABLES.templates.date_range &&
								p.data?.category?.template !==
									GLOBAL_VARIABLES.templates.date_picker &&
								parseInt(p.data.duration_value) >
									(c.data?.category?.template !==
										GLOBAL_VARIABLES.templates.appointment &&
									c.data?.category?.template !==
										GLOBAL_VARIABLES.templates.date_range &&
									c.data?.category?.template !==
										GLOBAL_VARIABLES.templates.date_picker
										? parseInt(c.data.duration_value)
										: 0)) ||
							c.data.duration_value === "All day" ||
							c.data.duration_value === "Den ganzen Tag" ||
							c.data.duration_value === "Cijeli dan"
								? p
								: c
					  )
					: null;
			dispatch(
				duration(
					longest_duration?.data?.duration_value != "" &&
						longest_duration?.data?.duration_value !== "All day" &&
						longest_duration?.data?.duration_value !== "Den ganzen Tag" &&
						longest_duration?.data?.duration_value !== "Cijeli dan"
						? parseInt(longest_duration?.data?.duration_value)
						: 0
				)
			);

			setDurationListState(
				duration_list
					? duration_list["Durations"].filter((i) => {
							if (
								parseInt(i.value) >=
									parseInt(longest_duration?.data?.duration_value) ||
								i.value === GLOBAL_VARIABLES?.durations.allDay
							) {
								return i;
							} else return null;
					  })
					: []
			);
		}
	}, [open && cart?.duration === null]);

	function getClosingTime() {
		const currentDay = new Date().getDay() > 0 ? new Date().getDay() - 1 : 6;
		const closingTime =
			orders?.length > 0 &&
			orders[0].data?.organisation_working_hours &&
			orders[0].data?.organisation_working_hours[currentDay].close;

		let sameOrganisation = orders?.every(
			(v) => v.data?.org_core_id === orders[0].data?.org_core_id
		);
		let sameReservationType = orders?.every(
			(v) =>
				v.data?.category.reservation_type ===
				orders[0].data?.category.reservation_type
		);
		if (sameOrganisation && sameReservationType) {
			return closingTime;
		}
	}

	function getOpeningTime() {
		const currentDay = new Date().getDay() > 0 ? new Date().getDay() - 1 : 6;
		const openingTime =
			orders?.length > 0 &&
			orders[0].data?.organisation_working_hours &&
			orders[0].data?.organisation_working_hours[currentDay].close;

		let sameOrganisation = orders?.every(
			(v) => v.data?.org_core_id === orders[0].data?.org_core_id
		);
		let sameReservationType = orders?.every(
			(v) =>
				v.data?.category.reservation_type ===
				orders[0].data?.category.reservation_type
		);
		if (sameOrganisation && sameReservationType) {
			return openingTime;
		}
	}

	function firstCheckIsScheduledTimeAlright() {
		const closingTime = getClosingTime();
		const openingTime = getOpeningTime();

		const timeTemp = moment(new Date()).add(
			typeof cart?.duration === "string" ? 0 : cart?.duration,
			"minutes"
		);

		const minTime = moment(new Date(), "HH:mm");
		const maxTime = moment(closingTime, "HH:mm");

		if (
			isNotAppointmentOrDateRangeOrDatePicker() /* && isSameOrgAndResType()*/
		) {
			if (new Date(scheduledTime).getTime() < new Date(minTime).getTime()) {
				const tempMsg = t(
					`Scheduled time has to be greater than current time.`
				);
				NotificationManager.error(tempMsg);
			} else if (
				new Date(scheduledTime).getTime() > new Date(maxTime).getTime()
			) {
				const tempMsg = `${t(
					`Working time of this organisation is from`
				)} ${openingTime} ${t("to")} ${closingTime}`;
				NotificationManager.error(tempMsg);
			} else if (
				new Date(scheduledTime).getTime() < new Date(timeTemp).getTime()
			) {
				const tempMsg =
					t("Delivery time has to be after ") +
					moment(timeTemp).format("HH.mm");

				NotificationManager.error(tempMsg);
			} else {
				// NotificationManager.success(
				// 	`The order can be ready at ${moment(scheduledTime).format(
				// 		"HH:mm"
				// 	)} at the earliest`
				// );
				putOnRoomBillAction(order?.exceededDialog);
			}
		} else putOnRoomBillAction(false);
	}

	// function isSameOrgAndResType() {
	//   let sameOrganisation = orders?.every(
	//     v => v.data?.org_core_id === orders[0].data?.org_core_id
	//   );
	//   let sameReservationType = orders?.every(
	//     v =>
	//       v.data?.category.reservation_type ===
	//       orders[0].data?.category.reservation_type
	//   );
	//   if (sameOrganisation && sameReservationType) {
	//     return true;
	//   }
	// }

	function isNotAppointmentOrDateRangeOrDatePicker() {
		let temp = orders?.every(
			(v) =>
				v.data?.category.template !== GLOBAL_VARIABLES.templates.appointment &&
				v.data?.category.template !== GLOBAL_VARIABLES.templates.date_range &&
				v.data?.category.template !== GLOBAL_VARIABLES.templates.date_picker
		);
		if (temp) {
			return true;
		}
	}

	//Izračunava količinu za dane
	function getQuantity(quantity, template, date, dateTo) {
		if (template === GLOBAL_VARIABLES.templates.date_range) {
			if (date && dateTo) {
				if (date <= dateTo) {
					const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
					const firstDate = new Date(date);
					const secondDate = new Date(moment(dateTo).add("1", "days"));

					const diffDays = Math.round(
						Math.abs((firstDate - secondDate) / oneDay)
					);

					const quantityByDays = diffDays * quantity;
					return parseInt(quantityByDays);
				} else return 0;
			} else return quantity;
		} else return quantity;
	}

	function chargeAsDishExtras(data) {
		let temp = 0;
		if (data != undefined && data?.length > 0) {
			temp = data?.reduce((acc, x) => {
				if (x.charge_as_side_dish) {
					return acc + x.price;
				} else return acc;
			}, 0);
		}

		return parseInt(temp);
	}

	useEffect(() => {
		if (order?.errors?.services) {
			orders = orders.map((i, index) => {
				setGlobalErrorsServices(
					order?.errors?.services &&
						typeof order?.errors?.services[0] === "string"
						? order?.errors?.services[0]
						: null
				);
				setGlobalErrorsLang(
					order?.errors?.lang && typeof order?.errors?.lang[0] === "string"
						? order?.errors?.lang[0]
						: null
				);
				return {
					...i,
					error:
						order?.errors?.services[index] &&
						typeof order?.errors?.services[index] !== "string"
							? order?.errors?.services[index]
							: null,
				};
			});
			localStorage.setItem("order", JSON.stringify(orders));
		} else {
			setGlobalErrorsLang(null);
			setGlobalErrorsServices(null);
		}
	});

	function didNotRateApp() {
		return JSON.parse(localStorage.getItem("alreadyRated")) !== "yes";
	}

	// CART SHAKE ANIMATION START

	const [animationStyle, setAnimationStyle] = useState("");

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}

	const prevCart = usePrevious(cart);

	useEffect(() => {
		const comparedArrays = areArraysEqual(
			cart?.items?.data,
			prevCart?.items?.data
		);
		if (prevCart) {
			if (cart?.items?.data?.length > 0 && !comparedArrays) {
				setAnimationStyle(classes.animate);
			}
			const timerId = setTimeout(() => {
				setAnimationStyle("");
			}, 1000);
			return () => {
				clearTimeout(timerId);
			};
		}
	}, [cart?.items?.data]);

	// CART SHAKE ANIMATION END

	return (
		<>
			<div className={`${animationStyle}`}>
				<IconButton
					icon={<ShoppingCartIcon />}
					onClick={handleClickOpen}
					dataCy={dataCy}
				/>
				{cart?.items?.data.length > 0 ? (
					<span className={classes.redDot} />
				) : null}
			</div>

			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<Box
					style={{
						padding: "20px",
						paddingTop: "40px",
						paddingBottom: "0px",
						display: "inline-flex",
						justifyContent: "space-between",
					}}
				>
					<TitleWithDivider
						title={"my cart"}
						customStyle={{ textAlign: "left" }}
						dataCy={"MyCartTitle"}
					/>
					<Button
						label="remove all"
						customStyle={{ textAlign: "right" }}
						onClick={handleRemoveAll}
						customFontColor={true}
						disabled={cart?.items?.data.length === 0}
						dataCy={"removeAllButton"}
					/>
				</Box>
				{/* {orders.length > 1 && (
          <Box style={{ padding: "15px" }}>
            <Box className={classes.reminderBox}>{t("Delivery schedule may differ because items are from different locations.")}</Box>
          </Box>
        )} */}

				{global_errors_lang && global_errors_services && (
					<Box className={classes.globalWarning} style={{ padding: "15px" }}>
						<IconButton icon={<ErrorOutlineIcon />} color="default" />

						{global_errors_lang && (
							<Typography variant="body2">
								{t("Language") + ": " + t(global_errors_lang)}
							</Typography>
						)}

						{global_errors_services && (
							<Typography variant="body2">
								{t("Services") + ": " + t(global_errors_services)}
							</Typography>
						)}
					</Box>
				)}

				<Box
					style={{
						overflow: "auto",
						padding: "0px 20px 150px",
						height: "fit-content",
					}}
				>
					{orderOrders.length > 0 && (
						<Box style={{ marginTop: "24px" }}>
							<TitleWithDivider title={"orders"} size={"16px"} />
							{orderOrders.map((i) => (
								<CardDistributor
									id={i.data?.id}
									unique_id={i.unique_id}
									image={i.data?.gallery[0] && i.data?.gallery[0].path}
									title={i.data?.name}
									price={i.data?.price}
									extras={i.extras}
									handleRemoveItem={() => handleRemoveItem(i.unique_id)}
									cart={true}
									organisation={i.data?.org_core_id}
									quantity={i.quantity}
									handleLocalStorage={handleLocalStorage}
									dataCy={"cartCardContent"}
									currency={curr}
									date={i.date ? i.date : null}
									dateTo={i.dateTo ? i.dateTo : null}
									template={i.data?.category?.template}
									error={i.error}
									organisations={organisations}
									pickupType={i.pickupType}
									deliveryOrg={i.deliveryOrg}
									deliveryOrgName={i.deliveryOrgName}
									duration={i.data?.duration_value}
								/>
							))}
							<SetDurationTime
								closingTime={getClosingTime()}
								openingTime={getOpeningTime()}
								setParentState={setParentState}
							/>
						</Box>
					)}

					{appointmentOrders.length > 0 && (
						<Box style={{ marginBottom: "32px" }}>
							<TitleWithDivider title={"appointments"} size={"16px"} />
							{appointmentOrders.map((i) => (
								<CardDistributor
									id={i.data?.id}
									unique_id={i.unique_id}
									image={i.data?.gallery[0] && i.data?.gallery[0].path}
									title={i.data?.name}
									price={i.data?.price}
									extras={i.extras}
									handleRemoveItem={() => handleRemoveItem(i.unique_id)}
									cart={true}
									organisation={i.data?.org_core_id}
									quantity={i.quantity}
									handleLocalStorage={handleLocalStorage}
									dataCy={"cartCardContent"}
									currency={curr}
									date={i.date ? i.date : null}
									dateTo={i.dateTo ? i.dateTo : null}
									template={i.data?.category?.template}
									error={i.error}
									organisations={organisations}
									pickupType={i.pickupType}
									deliveryOrg={i.deliveryOrg}
									deliveryOrgName={i.deliveryOrgName}
									duration={i.data?.duration_value}
								/>
							))}
						</Box>
					)}

					{eventOrders.length > 0 && (
						<Box style={{ marginBottom: "32px" }}>
							<TitleWithDivider title={"events"} size={"16px"} />
							{eventOrders.map((i) => (
								<CardDistributor
									id={i.data?.id}
									unique_id={i.unique_id}
									image={i.data?.gallery[0] && i.data?.gallery[0].path}
									title={i.data?.name}
									price={i.data?.price}
									extras={i.extras}
									handleRemoveItem={() => handleRemoveItem(i.unique_id)}
									cart={true}
									organisation={i.data?.org_core_id}
									quantity={i.quantity}
									handleLocalStorage={handleLocalStorage}
									dataCy={"cartCardContent"}
									currency={curr}
									date={i.date ? i.date : null}
									dateTo={i.dateTo ? i.dateTo : null}
									template={i.data?.category?.template}
									error={i.error}
									organisations={organisations}
									pickupType={i.pickupType}
									deliveryOrg={i.deliveryOrg}
									deliveryOrgName={i.deliveryOrgName}
									duration={i.data?.duration_value}
								/>
							))}
						</Box>
					)}

					{dateOrders.length > 0 && (
						<Box style={{ marginBottom: "32px" }}>
							<TitleWithDivider title={"others"} size={"16px"} />
							{dateOrders.map((i) => (
								<CardDistributor
									id={i.data?.id}
									unique_id={i.unique_id}
									image={!!i.data?.gallery[0] && i.data?.gallery[0]?.path}
									title={i.data?.name}
									price={i.data?.price}
									extras={i.extras}
									handleRemoveItem={() => handleRemoveItem(i.unique_id)}
									cart={true}
									organisation={i.data?.org_core_id}
									quantity={i.quantity}
									handleLocalStorage={handleLocalStorage}
									dataCy={"cartCardContent"}
									currency={curr}
									date={i.date ? i.date : null}
									dateTo={i.dateTo ? i.dateTo : null}
									template={i.data?.category?.template}
									error={i.error}
									organisations={organisations}
									pickupType={i.pickupType}
									deliveryOrg={i.deliveryOrg}
									deliveryOrgName={i.deliveryOrgName}
									duration={i.data?.duration_value}
								/>
							))}
						</Box>
					)}
				</Box>
				<Box className={classes.checkoutBox}>
					{orders.length > 0 ? (
						<div className={classes.totalSection}>
							<Typography style={{ fontSize: "18px", fontWeight: 600 }}>
								{t("TOTAL ITEMS")}
							</Typography>
							<Typography style={{ fontSize: "18px", fontWeight: 600 }}>
								{totalQuantity}
							</Typography>
						</div>
					) : null}
					<Button
						customStyle={{
							fontWeight: 600,
							height: "65px",
							width: "100%",
							boxShadow: theme.shadows[3],
						}}
						label={"checkout"}
						variant="contained"
						disabled={true}
						amount={calculateAmountAndAddCurrency()}
						onClick={() => setOpenPaymentDialog(true)}
						dataCy={"checkoutButton"}
					/>
				</Box>
				<Box
					style={{
						bottom: 0,
						position: "fixed",
						width: "100%",
						backgroundColor: "white",
					}}
				>
					<IconButton
						color="default"
						size="medium"
						customStyle={{ width: "100%" }}
						icon={<ExpandLessIcon />}
						onClick={handleClose}
						dataCy={"closeCart"}
					/>
				</Box>
			</Dialog>

			<DialogWithTwoSubmitButtons
				opened={openPaymentDialog}
				handleClose={() => {
					setOpenPaymentDialog(false);
				}}
				putOnRoomBillAction={firstCheckIsScheduledTimeAlright}
				title={"checkout"}
				priceAmount={calculateAmountAndAddCurrency()}
				dataCy={"roomBillButton"}
			/>

			<FullscreenDialogSuccess
				opened={openDialogSuccess}
				handleClose={() => {
					setOpenDialogSuccess(false);
					dispatch(openRateAppDialog());
				}}
			/>

			{order?.exceededDialog && (
				<ExceededLimitDialog
					opened={order?.exceededDialog}
					handleClose={handleCloseExceededDialog}
					putOnRoomBillAction={firstCheckIsScheduledTimeAlright}
				/>
			)}

			{rate?.rateAppDialog && didNotRateApp() && (
				<RateApp
					opened={rate?.rateAppDialog}
					handleClose={() => dispatch(closeRateAppDialog())}
				/>
			)}
		</>
	);
};

Cart.defaultProps = {
	dataCy: null,
};

export default Cart;

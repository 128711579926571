/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import {
  getFunc,
  postFunc,
  putFunc,
  deleteFunc,
} from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import i18n from "i18next";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const GET_REVIEW_TYPES_REQ = "auth/GET_REVIEW_TYPES_REQ";
const GET_REVIEW_TYPES_SCS = "auth/GET_REVIEW_TYPES_SCS";
const GET_REVIEW_TYPES_FLR = "auth/GET_REVIEW_TYPES_FLR";

const SEND_REVIEW_REQ = "auth/SEND_REVIEW_REQ";
const SEND_REVIEW_SCS = "auth/SEND_REVIEW_SCS";
const SEND_REVIEW_FLR = "auth/SEND_REVIEW_FLR";

const REMOVE_RATING = "auth/REMOVE_RATING";
const CHANGE_RATING = "auth/CHANGE_RATING";
const GET_REVIEW_VALUES = "auth/GET_REVIEW_VALUES";

const OPEN_DIALOG_SPENDINGS = "auth/OPEN_DIALOG_SPENDINGS";
const CLOSE_DIALOG_SPENDINGS = "auth/CLOSE_DIALOG_SPENDINGS";

const GET_PROFILE_INFO_REQ = "auth/GET_PROFILE_INFO_REQ";
const GET_PROFILE_INFO_SCS = "auth/GET_PROFILE_INFO_SCS";
const GET_PROFILE_INFO_FLR = "auth/GET_PROFILE_INFO_FLR";

const SET_SPENDINGS_IN_REDUX = "auth/SET_SPENDINGS_IN_REDUX";
const CLEAR_SPENDINGS_FROM_REDUX = "auth/CLEAR_SPENDINGS_FROM_REDUX";

const SET_LIMITATIONS_REQ = "auth/SET_LIMITATIONS_REQ";
const SET_LIMITATIONS_SCS = "auth/SET_LIMITATIONS_SCS";
const SET_LIMITATIONS_FLR = "auth/SET_LIMITATIONS_FLR";

const LIMIT_IS_SETTED = "auth/LIMIT_IS_SETTED";

const VALIDATION_MESSAGE = "auth/VALIDATION_MESSAGE";
const VALIDATION_CLEAR = "auth/VALIDATION_CLEAR";

const RATE_OUR_APP_REQ = "auth/RATE_OUR_APP_REQ";
const RATE_OUR_APP_SCS = "auth/RATE_OUR_APP_SCS";
const RATE_OUR_APP_FLR = "auth/RATE_OUR_APP_FLR";

const OPEN_RATE_APP_DIALOG = "auth/OPEN_RATE_APP_DIALOG";
const CLOSE_RATE_APP_DIALOG = "auth/CLOSE_RATE_APP_DIALOG";

const GET_SUBSCRIPTIONS_REQ = "auth/GET_SUBSCRIPTIONS_REQ";
const GET_SUBSCRIPTIONS_SCS = "auth/GET_SUBSCRIPTIONS_SCS";
const GET_SUBSCRIPTIONS_FLR = "auth/GET_SUBSCRIPTIONS_FLR";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/

export const getReviewTypes = (lang) => async (dispatch) => {
  dispatch({ type: GET_REVIEW_TYPES_REQ });
  const response = await getFunc(`stayReviewTypes/${lang}`);
  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_REVIEW_TYPES_SCS,
      payload: response.data,
    });
  } else {
    dispatch({ type: GET_REVIEW_TYPES_FLR });
    NotificationManager.error(i18n.t(response.status.description));
  }
};

export const sendReview = (body) => async (dispatch) => {
  dispatch({ type: SEND_REVIEW_REQ });
  const response = await postFunc(`stayReviews`, body);
  if (response.status.errorCode === 200) {
    dispatch({
      type: SEND_REVIEW_SCS,
      payload: response.data,
    });
    NotificationManager.success(i18n.t(response.status.description));
  } else {
    dispatch({ type: SEND_REVIEW_FLR });
    NotificationManager.error(i18n.t(response.status.description));
  }
};

export const openRateAppDialog = () => async (dispatch) => {
  dispatch({ type: OPEN_RATE_APP_DIALOG });
};

export const closeRateAppDialog = () => async (dispatch) => {
  dispatch({ type: CLOSE_RATE_APP_DIALOG });
};

export const rateOurApp = (body) => async (dispatch) => {
  dispatch({ type: RATE_OUR_APP_REQ });
  const response = await postFunc(`applicationReviews/guestApp`, body);
  if (response.status.errorCode === 200) {
    dispatch({
      type: RATE_OUR_APP_SCS,
      payload: response.data,
    });
    dispatch(closeRateAppDialog());
    NotificationManager.success(i18n.t(response.status.description));
    localStorage.setItem("alreadyRated", JSON.stringify("yes"));
  } else if (response.status.errorCode === 400) {
    dispatch({ type: RATE_OUR_APP_FLR });
    dispatch({
      type: VALIDATION_MESSAGE,
      message: response.status.description,
    });
  } else {
    dispatch({ type: RATE_OUR_APP_FLR });
    NotificationManager.error(i18n.t(response.status.description));
  }
};

export const removeRating = () => async (dispatch) => {
  const response = await deleteFunc(`stayReviews`);
  if (response.status.errorCode === 200) {
    dispatch({
      type: REMOVE_RATING,
    });
    NotificationManager.success(i18n.t(response.status.description));
  } else {
    NotificationManager.error(i18n.t(response.status.description));
  }
};

export const changeRating = (id, value) => async (dispatch) => {
  dispatch({ type: CHANGE_RATING, id: id, value: value });
};

export const getReviewValues = (data) => async (dispatch) => {
  dispatch({ type: GET_REVIEW_VALUES, data: data });
};

export const openDialogSpendings = () => async (dispatch) => {
  dispatch({ type: OPEN_DIALOG_SPENDINGS });
};

export const closeDialogSpendings = () => async (dispatch) => {
  dispatch({ type: CLOSE_DIALOG_SPENDINGS });
  dispatch({ type: CLEAR_SPENDINGS_FROM_REDUX });
  dispatch({ type: VALIDATION_CLEAR });
};

export const getProfileInfo = () => async (dispatch) => {
  dispatch({ type: GET_PROFILE_INFO_REQ });
  const response = await getFunc(`reservationGuests`);
  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_PROFILE_INFO_SCS,
      payload: response.data,
    });
  } else {
    dispatch({ type: GET_PROFILE_INFO_FLR });
    NotificationManager.error(i18n.t(response.status.description));
  }
};

export const setSpendingsInRedux = (data, currency) => async (dispatch) => {
  dispatch({
    type: SET_SPENDINGS_IN_REDUX,
    guest: data.guest,
    data: data.order_items,
    total: data.total_spent,
    currency: currency,
    guest_id: data.id,
    limit_amount: data.limit_amount,
    limit_type: data.limit_type,
  });
};

export const clearSpendingFromRedux = () => async (dispatch) => {
  dispatch({ type: CLEAR_SPENDINGS_FROM_REDUX });
};

export const setLimitations = (guest_id, body) => async (dispatch) => {
  dispatch({ type: SET_LIMITATIONS_REQ });
  const response = await putFunc(`reservationGuests/${guest_id}`, body);
  if (response.status.errorCode === 200) {
    dispatch({ type: SET_LIMITATIONS_SCS, payload: response.data });
    dispatch({ type: VALIDATION_CLEAR });
    NotificationManager.success(i18n.t(response.status.description));
  } else if (response.status.errorCode === 400) {
    dispatch({
      type: SET_LIMITATIONS_FLR,
    });
    dispatch({
      type: VALIDATION_MESSAGE,
      message: response.status.description,
    });
  } else {
    dispatch({ type: SET_LIMITATIONS_FLR, error: response.status.description });
    NotificationManager.error(i18n.t(response.status.description));
  }
};

export const controlActionWhenLimitIsSuccessfullySetted = () => async (
  dispatch
) => {
  dispatch({ type: LIMIT_IS_SETTED });
};

export const getSubscriptions = (lang) => async (dispatch) => {
  dispatch({ type: GET_SUBSCRIPTIONS_REQ });
  const response = await getFunc(
    `orderItems/getAllSubscriptionsForGuest/${lang}`
  );
  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_SUBSCRIPTIONS_SCS,
      payload: response.data,
    });
  } else {
    dispatch({ type: GET_SUBSCRIPTIONS_FLR });
    NotificationManager.error(i18n.t(response.status.description));
  }
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/

const INIT_STATE = {
  review_types: {
    loading: false,
  },
  openDialogSpendings: false,
  profile_info: {
    loading: false,
  },
  spendings: {
    loading: false,
  },
  successfullySettedLimit: false,
  rateAppDialog: false,
  subscriptions: [],
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_REVIEW_TYPES_REQ:
      return {
        ...state,
        review_types: {
          loading: true,
        },
      };
    case GET_REVIEW_TYPES_SCS:
      return {
        ...state,
        review_types: {
          loading: false,
          data: action.payload.map((i) => {
            return { ...i, value: null };
          }),
        },
      };
    case GET_REVIEW_TYPES_FLR:
      return {
        ...state,
        review_types: {
          loading: false,
          error: action.error,
        },
      };
    case GET_PROFILE_INFO_REQ:
      return {
        ...state,
        profile_info: {
          loading: true,
        },
      };
    case GET_PROFILE_INFO_SCS:
      return {
        ...state,
        profile_info: {
          loading: false,
          data: action.payload,
        },
      };
    case GET_PROFILE_INFO_FLR:
      return {
        ...state,
        profile_info: {
          loading: false,
          error: action.error,
        },
      };
    case REMOVE_RATING:
      return {
        ...state,
        review_types: {
          loading: false,
          data: state?.review_types?.data?.map((i) => {
            return { ...i, value: null };
          }),
        },
      };
    case CHANGE_RATING:
      return {
        ...state,
        review_types: {
          loading: false,
          data: state?.review_types?.data?.map((i) => {
            if (i.id === action.id) {
              return { ...i, value: action.value };
            } else return i;
          }),
        },
      };
    case GET_REVIEW_VALUES:
      return {
        ...state,
        review_types: {
          loading: false,
          data: state?.review_types?.data?.map((i) => {
            const temp = action.data?.find(
              (item) => item.stay_review_type.id === i.id
            );
            return {
              ...i,
              value: temp ? temp.value : null,
              review_id: temp ? temp.id : i.review_id,
            };
          }),
        },
      };
    case OPEN_DIALOG_SPENDINGS:
      return {
        ...state,
        openDialogSpendings: true,
      };
    case CLOSE_DIALOG_SPENDINGS:
      return {
        ...state,
        openDialogSpendings: false,
      };

    case SET_SPENDINGS_IN_REDUX:
      return {
        ...state,
        spendings: {
          loading: false,
          data: action.data,
          guest: action.guest,
          total: action.total,
          currency: action.currency,
          guest_id: action.guest_id,
          limit_amount: action.limit_amount,
          limit_type: action.limit_type,
        },
      };
    case CLEAR_SPENDINGS_FROM_REDUX:
      return {
        ...state,
        spendings: {
          loading: false,
        },
      };
    case SET_LIMITATIONS_REQ:
      return {
        ...state,
        profile_info: {
          loading: true,
          ...state.profile_info,
        },
      };
    case SET_LIMITATIONS_SCS:
      return {
        ...state,
        successfullySettedLimit: true,
        profile_info: {
          loading: false,
          data:
            state.profile_info.data?.id === action.payload.id
              ? {
                  ...state.profile_info.data,
                  limit_amount: action.payload.limit_amount,
                  limit_type: action.payload.limit_type,
                }
              : state.profile_info.data.id !== action.payload.id
              ? {
                  ...state.profile_info.data,
                  others: state.profile_info.data.others.map((i) => {
                    if (i.id === action.payload.id) {
                      return {
                        ...i,
                        limit_amount: action.payload.limit_amount,
                        limit_type: action.payload.limit_type,
                      };
                    } else return i;
                  }),
                }
              : state.profile_info.data,
        },
      };

    case SET_LIMITATIONS_FLR:
      return {
        ...state,
        profile_info: {
          ...state.profile_info,
          loading: false,
          error: action.error,
        },
      };
    case LIMIT_IS_SETTED:
      return {
        ...state,
        successfullySettedLimit: false,
      };
    case OPEN_RATE_APP_DIALOG:
      return {
        ...state,
        rateAppDialog: true,
      };
    case CLOSE_RATE_APP_DIALOG:
      return {
        ...state,
        rateAppDialog: false,
      };
    // case GET_SUBSCRIPTIONS_REQ:
    //   return {
    //     ...state,
    //   };
    case GET_SUBSCRIPTIONS_SCS:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case GET_SUBSCRIPTIONS_FLR:
      return {
        ...state,
        subscriptions: {
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

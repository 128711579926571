// React
import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

// Swiper
import { useSwipeable } from "react-swipeable";

const style = makeStyles((theme) => ({
  slide: {
    scrollSnapAlign: "start",
    flexShrink: "0",
    width: "100%",
    height: "250px",
    backgroundColor: "rgb(231, 231, 231)",
    marginBottom: "10px",
    //marginTop: "5px",
    padding: "5px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "3px",
    zIndex: "0",
    borderRadius: "20px",
    boxShadow: theme.shadows[3],
  },
  imageNumber: {
    backgroundColor: "rgba(5, 5, 5, 0.4)",
    padding: "4px",
    borderRadius: "3px 3px 15px 3px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "500",
  },
}));

const Image = (props) => {
  const classes = style();

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      eventData.event.stopPropagation();
    },
    onSwipedLeft: (eventData) => {
      eventData.event.stopPropagation();
    },
    onSwipedRight: (eventData) => {
      eventData.event.stopPropagation();
    },
  });

  const {
    display,
    justifyContent,
    alignItems,
    index,
    length,
    imagePath,
    accordion,
    //key,
  } = props;

  return (
    <Box
      {...handlers}
      className={classes.slide}
      display={display}
      justifyContent={justifyContent}
      alignItems={alignItems}
      //key={key}
      className={classes.slide}
      style={{
        backgroundImage: `url(${imagePath})`,
        marginTop: accordion ? "0px" : "5px",
      }}
    >
      <div className={classes.imageNumber}>{`${index + 1}/${length}`}</div>
    </Box>
  );
};

Image.defaultProps = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  imagePath: "",
  //key: "",
  length: "",
  index: 0,
  accordion: false,
};

export default Image;

// React
import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Calendar } from "react-date-range";

// Atoms
import Button from "Components/atoms/buttons/Button";

// Molecules
import TitleWithDivider from "Components/molecules/TitleWithDivider";

// Translate
import enLocale from "date-fns/locale/en-US";
import hrLocale from "date-fns/locale/hr";
import deLocale from "date-fns/locale/de";

// MUI
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

// Utils
import { getCookie } from "Util/functions";

// Calendar Style
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const style = makeStyles((theme) => ({
  bottomStyle: {
    padding: "15px",
    bottom: "0px",
    display: "flex",
    position: "fixed",
    width: "100%",
    left: "0px",
    right: "0px",
    backgroundColor: "white",
  },
}));

const localeMap = {
  en: enLocale,
  hr: hrLocale,
  de: deLocale,
};

const formatDateMap = {
  en: "ll",
  hr: "Do MMM YYYY",
  de: "Do MMM YYYY",
};

const DatePickerDialogContent = (props) => {
  const { t } = useTranslation();
  const classes = style();
  const primaryColor = JSON.parse(localStorage.getItem("theme"))?.primary_color;

  const handleChangeDate = (event, newValue) => {
    setParentState("date", event);
  };

  const { handleSubmitDatePicker, setParentState, date } = props;

  return (
    <>
      <TitleWithDivider
        title={moment(date).format(formatDateMap[getCookie("lang")])}
        size="18px"
        width="40px"
      />
      <Calendar
        onChange={handleChangeDate}
        locale={localeMap[getCookie("lang")]}
        date={date}
        weekStartsOn={1}
        showMonthAndYearPickers={false}
        minDate={new Date(new Date().setDate(new Date().getDate()))}
        color={primaryColor}
        fixedHeight={true}
      />
      <Box className={classes.bottomStyle}>
        <Button
          onClick={handleSubmitDatePicker}
          variant="contained"
          customStyle={{
            width: "100%",
            height: "65px",
            fontWeight: 600,
          }}
          label={t("add to cart").toUpperCase()}
        />
      </Box>
    </>
  );
};

DatePickerDialogContent.defaultProps = {
  handleSubmitDatePicker: () => console.log("handleSubmitDatePicker"),
  setParentState: null,
  date: new Date(),
};

export default DatePickerDialogContent;

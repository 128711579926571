/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { postFunc, getFunc } from "Services/mainApiServices";
import Base64 from "Util/base64";
import { NotificationManager } from "react-notifications";
import i18n from "i18next";
import { htmlToText } from "html-to-text";
/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const LOGIN_REQ = "auth/LOGIN_REQ";
const LOGIN_SCS = "auth/LOGIN_SCS";
const LOGIN_FLR = "auth/LOGIN_FLR";

const LOGOUT_USER = "auth/LOGOUT_USER";

const GET_ALL_ORGANISATIONS_BY_LOGGED_USER_REQ =
	"auth/GET_ALL_ORGANISATIONS_BY_LOGGED_USER_REQ";
const GET_ALL_ORGANISATIONS_BY_LOGGED_USER_SCS =
	"auth/GET_ALL_ORGANISATIONS_BY_LOGGED_USER_SCS";
const GET_ALL_ORGANISATIONS_BY_LOGGED_USER_FLR =
	"auth/GET_ALL_ORGANISATIONS_BY_LOGGED_USER_FLR";

const VALIDATION_MESSAGE = "auth/VALIDATION_MESSAGE";
const VALIDATION_CLEAR = "auth/VALIDATION_CLEAR";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/

export const login = (body, history, realTimeAPI) => async (dispatch) => {
	dispatch({ type: LOGIN_REQ });

	const response = await postFunc("guests/login", body);

	if (response.status.errorCode === 200) {
		let decode = Base64.decode(response.access_token.trim().split(".")[1]);
		// eslint-disable-next-line no-control-regex
		decode = JSON.parse(decode.replace(/[^\x01-\x7F]/g, ""));
		dispatch({ type: VALIDATION_CLEAR });
		if (decode && response.access_token) {
			localStorage.setItem("user", JSON.stringify(decode));
			localStorage.setItem("data", JSON.stringify(response.data));
			localStorage.setItem("jwt-token", JSON.stringify(response.access_token));
			// localStorage.setItem("meni", JSON.stringify(response.meni));
			dispatch({ type: LOGIN_SCS, payload: { user: decode, data: response } });
			dispatch(getAllOrganisationsByLoggedUser());
			//history.push("/welcome");
			if (
				response.data?.organisation?.welcome_message === null ||
				response.data?.organisation?.welcome_page === false ||
				/^\s*$/.test(htmlToText(response.data?.organisation?.welcome_message))
			) {
				if (response.data?.organisation?.tutorials?.data?.length > 0) {
					history.push("/tutorial/home");
				} else {
					history.push("/home");
				}
			} else {
				history.push("/welcome");
			}
		}
	} else if (response.status.errorCode === 400) {
		dispatch({ type: LOGIN_FLR });
		dispatch({
			type: VALIDATION_MESSAGE,
			message: response.status.description,
		});
	} else {
		NotificationManager.error(i18n.t(response.status.description));
		history.push("/");
		dispatch({ type: VALIDATION_CLEAR });
		dispatch({ type: LOGIN_FLR });
	}
};

export const renewToken = async (history) => {
	const response = await getFunc("renew");

	if (response.status.errorCode === 200) {
		let decode = Base64.decode(response.access_token.trim().split(".")[1]);
		// eslint-disable-next-line no-control-regex
		decode = JSON.parse(decode.replace(/[^\x01-\x7F]/g, ""));
		if (decode && response.access_token) {
			localStorage.setItem("user", JSON.stringify(decode));
			localStorage.setItem("data", JSON.stringify(response.data));
			localStorage.setItem("jwt-token", JSON.stringify(response.access_token));
			// localStorage.setItem("meni", JSON.stringify(response.meni));
			history.push("home");
		}
	}
};

export const logOut = (history, realTimeAPI) => (dispatch) => {
	dispatch({ type: LOGOUT_USER });
	localStorage.clear();
	history.push("/");
	realTimeAPI.disconnect();
};

export const getAllOrganisationsByLoggedUser = () => async (dispatch) => {
	dispatch({ type: GET_ALL_ORGANISATIONS_BY_LOGGED_USER_REQ });
	const response = await getFunc(`organisations/getAllChildrenByLoggedUser`);
	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_ALL_ORGANISATIONS_BY_LOGGED_USER_SCS,
			payload: response.data,
		});
		localStorage.setItem(
			"orgsByLoggedUser",
			JSON.stringify(
				response?.data?.map((i) => {
					return {
						...i,
						color: "#" + (((1 << 24) * Math.random()) | 0).toString(16),
					};
				})
			)
		);
	} else {
		dispatch({ type: GET_ALL_ORGANISATIONS_BY_LOGGED_USER_FLR });
		NotificationManager.error(i18n.t(response.status.description));
	}
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/

const INIT_STATE = {
	user: JSON.parse(localStorage.getItem("user")),
	data: JSON.parse(localStorage.getItem("data")),
	// meni: JSON.parse(localStorage.getItem("meni")),
	loading: false,
	organisations: {
		loading: false,
	},
};

export default function reducer(state = INIT_STATE, action = {}) {
	switch (action.type) {
		case LOGIN_REQ:
			return {
				...state,
				items: {},
				loadnig: true,
			};
		case LOGIN_SCS:
			return {
				...state,
				user: action.payload.user,
				data: action.payload.data.data,
				meni: action.payload.user.meni,
				loading: false,
			};
		case LOGIN_FLR:
			return {
				...state,
				loading: false,
			};
		case GET_ALL_ORGANISATIONS_BY_LOGGED_USER_REQ:
			return {
				...state,
				organisations: {
					loading: true,
				},
			};
		case GET_ALL_ORGANISATIONS_BY_LOGGED_USER_SCS:
			return {
				...state,
				organisations: {
					loading: false,
					data: action.payload,
				},
			};
		case GET_ALL_ORGANISATIONS_BY_LOGGED_USER_FLR:
			return {
				...state,
				organisations: {
					loading: false,
					error: action.error,
				},
			};
		default:
			return state;
	}
}

// React
import React from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";

// Material UI
import Box from "@material-ui/core/Box";
import DialogMUI from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

//Atoms
import IconButton from "Components/atoms/buttons/IconButton";
import Button from "Components/atoms/buttons/Button";
import { Typography } from "@material-ui/core";

const DialogWithTwoSubmitButtons = ({
  opened,
  handleClose,
  title,
  putOnRoomBillAction,
  priceAmount,
  dataCy,
  currency,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <DialogMUI
        open={opened}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle style={{ textAlign: "left" }} id="alert-dialog-title">
          <IconButton
            onClick={handleClose}
            icon={<CloseIcon />}
            customStyle={{ float: "right" }}
            color="default"
          />
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {t(title).toUpperCase()}
          </Typography>
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            padding: "0px 10px 10px 10px",
            display: "block",
            padding: "15px",
          }}
        >
          <Box
            style={{ height: "120px", textAlign: "center", marginTop: "25px" }}
          >
            <Typography variant="subtitle2">
              {t("total").toUpperCase()}
            </Typography>
            <Typography variant="h4">
              {priceAmount ? priceAmount : ""}
            </Typography>
          </Box>
          <Button
            onClick={() => {
              NotificationManager.info(
                t("Card payment is not implemented yet!")
              );
            }}
            label={"pay with card"}
            variant="contained"
            customFontColor={true}
            customStyle={{
              width: "100%",
              height: "80px",
              boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
              fontWeight: "bold",
            }}
          />
          <Button
            onClick={putOnRoomBillAction}
            label={"room bill"}
            variant="contained"
            customStyle={{
              width: "100%",
              height: "80px",
              marginTop: "10px",
              marginLeft: "0px",
              boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
              fontWeight: "bold",
            }}
            dataCy={dataCy}
          />
        </DialogContent>
      </DialogMUI>
    </div>
  );
};

export default DialogWithTwoSubmitButtons;

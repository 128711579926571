// React
import React, { memo } from "react";
import { useTranslation } from "react-i18next";

// MUI
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";

// Atoms
import Price from "Components/atoms/UI/Price";
import Tag from "Pages/browse/components/molecules/Tag";

const AccordionSummary = withStyles({
  root: {
    paddingLeft: 0,
    width: "100%",
    height:"40px",
    minHeight:"40px"
  },
  content: {
    margin: 0,
    padding: 0,
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    gridTemplateRows: "40px",
    gridColumnGap: "8px",
    gridTemplateAreas:
      '"title priceTag"',
  },
  expandIcon: {},
})(MuiAccordionSummary);

const expandIconStyle = { fontSize: "0.9rem", padding: 0 };

const SummaryImageless = (props) => {
  const { t } = useTranslation();
  const {
    id,
    title,
    classes,
    price,
    status,
    currency,
  } = props;

  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon sx={expandIconStyle} />}
      aria-controls={`${id}d-content`}
      id={`${id}d-header`}
    >
      <div className={classes.titleImageless}>
        <Typography className={classes.titleHeaderImageless}>{t(title)}</Typography>
      </div>
      <div className={classes.priceTagImageless}>
        {status && (
          <Tag
            label={status}
            //priceExists={price > 0}
            customStyle={{
              padding: "0 3px",
              fontSize: "12px",
              marginLeft:"8px"
            }}
          />
          )}
        {price > 0 && (
          <Price
            label={`${price ? price.toFixed(2) : ""} ${currency || ""}`}
            customStyle={{
              padding: "0 3px",
              fontSize: "12px",
              marginLeft:"8px"
            }}
          />
        )}
      </div>
    </AccordionSummary>
  );
};

SummaryImageless.defaultProps = {
  title: "Title",
  classes: {},
  id: "",
  price: "",
  status: null,
  currency: null,
};

export default memo(SummaryImageless);

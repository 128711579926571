// React
import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import ButtonMUI from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";

// Router
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  disabledButton: {
    backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
    cursor: "default",
    "&:disabled": {
      backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
      color: "white",
      boxShadow: theme.shadows[3],
    },
  },
  customFontColor: {
    backgroundColor: "white",
    backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": `${theme.palette.secondary.main}`,
  },
  wrapTextStyle: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

const Button = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    variant,
    label,
    onClick,
    color,
    goTo,
    icon,
    fullWidth,
    type,
    size,
    customStyle,
    disabled,
    amount,
    customFontColor,
    dataCy,
    wrapText,
  } = props;

  return (
    <ButtonMUI
      className={
        customFontColor ? classes.customFontColor : classes.disabledButton
      }
      variant={variant}
      color={color}
      onClick={goTo ? () => history.push(goTo) : onClick}
      endIcon={icon ? <Icon>{icon}</Icon> : false}
      fullWidth={fullWidth}
      type={type}
      size={size}
      style={customStyle}
      disabled={amount ? false : disabled}
      dataCy={dataCy}
    >
      {wrapText ? (
        <span className={classes.wrapTextStyle}>{t(label)}</span>
      ) : (
        <>
          {/* <span> */}
          {t(label)}
          {amount ? " | " + amount : null}
          {/* </span> */}
        </>
      )}
    </ButtonMUI>
  );
};

Button.defaultProps = {
  variant: "",
  label: "",
  onClick: () => console.log("Ouch!"),
  color: "primary",
  goTo: "",
  icon: false,
  fullWidth: false,
  type: "button",
  size: "medium",
  disabled: false,
  customStyle: {},
  amount: false,
  customFontColor: false,
  dataCy: null,
  wrapText: false,
};

export default Button;

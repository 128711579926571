module.exports = {
	"Invalid code!": "Ungültiger Code!",
	Food: "Essen",
	Drinks: "Getränke",
	Massage: "Massage",
	Cleaning: "Reinigung",
	Taxi: "Taxi",
	Souvenirs: "Souvenirs",
	"CHOOSE QUANTITY AND EXTRAS": "WÄHLEN SIE MENGE UND EXTRAS",
	"choose quantity and extras": "Menge und Extras auswählen",
	"Choose quantity": "Menge auswählen",
	"select the number of tickets": "wählen sie die anzahl der tickets aus",
	"SELECT THE NUMBER OF TICKETS": "WÄHLEN SIE DIE ANZAHL DER TICKETS AUS",
	"Select the number of tickets": "Wählen Sie die Anzahl der Tickets aus",
	reviews: "Bewertungen",
	Close: "Schließen",
	New: "Neu",
	all: "alle",
	Pizza: "Pizza",
	Pasta: "Pasta",
	Fish: "Fisch",
	"my cart": "Mein Warenkorb",
	checkout: "die kasse",
	"remove all": "alles entfernen",
	"Delivery schedule may differ because items are from different locations.":
		"Der Lieferplan kann abweichen, da die Artikel von verschiedenen Standorten stammen.",
	"TOTAL ITEMS": "GESAMTANZAHL",
	"Show details": "Zeige Details",
	appointments: "Termine",
	events: "Veranstaltungen",
	Ketchup: "Ketchup",
	Mayonnaise: "Mayonnaise",
	"Olive oil": "Olivenöl",
	"Hot sauce": "Scharfe Soße",
	extras: "extras",
	quantity: "Menge",
	"add to cart": "in den Warenkorb legen",
	"room bill": "Zimmerrechnung",
	"pay with card": "mit Karte bezahlen",
	"Order successfully placed!": "Bestellung erfolgreich aufgegeben!",
	"You can always track your orders in ORDERS section under main menu.":
		"Sie können Ihre Bestellungen jederzeit im Abschnitt BESTELLUNGEN im Hauptmenü verfolgen.",
	"order more": "mehr bestellen",
	"my orders": "meine Bestellungen",
	"My stay": "Mein Aufenthalt",
	"Rate your stay": "Bewerten Sie Ihren Aufenthalt",
	"delete rating": "Bewertung entfernen",
	accomodation: "Unterkunft",
	service: "Bedienung",
	review: "Rezension",
	"Type here...": "Geben Sie hier ein...",
	confirm: "bestätigen",
	"Floor & Room": "Etage & Zimmer",
	"Departure time": "Abfahrtszeit",
	"My balance": "Mein Gleichgewicht",
	"My spendings": "Meine Ausgaben",
	Spendings: "Spendings",
	Spent: "Verbraucht",
	notifications: "Mitteilungen",
	"read all": "Lese alles",
	chips: "chips",
	tortillas: "tortillas",
	Hamburger: "Hamburger",
	"special offer": "sonderangebot",
	"Special offer": "Sonderangebot",
	Popular: "Popular",
	"today's specialities": "heutige Spezialitäten",
	"my timeline": "mein Zeitplan",
	pending: "anstehend",
	finished: "fertig",
	"Swimming pool": "Schwimmbad",
	rate: "Bewertung",
	"chat with triplehost": "chatte mit Triplehost",
	"This feature is coming soon...": "Diese Funktion kommt bald...",
	"8:00": "8:00",
	total: "gesamt",
	cancel: "stornieren",
	"view available termins": "verfügbare Termine anzeigen",
	reserve: "Reservieren",
	"MAKE AN APPOINTMENT": "EINEN TERMIN MACHEN",
	"make an appointment": "einen Termin machen",
	ORDER: "BESTELLEN",
	order: "bestellen",
	"Odaberi početni datum": "Wählen Sie ein Startdatum",
	"Odaberi krajnji datum": "Enddatum auswählen",
	"Cart contains invalid items": "Der Warenkorb enthält ungültige Artikel",
	"Scheduled date is in past": "Geplantes Datum ist in der Vergangenheit",
	"Date from is greater than date to": "Datum von ist größer als Datum bis",
	"Enter the amount": "Geben Sie den Betrag ein",
	"Set a limit": "Setzen Sie ein Limit",
	Ban: "Verbot",
	Limitation: "Einschränkung",
	"Not a valid number.": "Keine gültige Nummer.",
	"Amount can not be negative ": "Betrag kann nicht negativ sein",
	close: "schließen",
	continue: "fortsetzen",
	"You have exceeded the limit": "Sie haben das Limit überschritten!",
	"Do you want cancel an action or continue?":
		"Möchten Sie eine Aktion abbrechen oder fortfahren??",
	"Date to must be greater than date from":
		"Datum bis muss größer sein als Datum von",
	"not rated": "nicht bewertet",
	"on room bill": "auf Zimmerrechnung",
	"my orders & appointments": "meine Bestellungen & Termine",
	Accommodation: "Unterkunft",
	Service: "Bedienung",
	"A review": "Eine Rezension",
	"schedule delivery": "Lieferung planen",
	home: "Startseite",
	browse: "Suche",
	orders: "Aufträge",
	chat: "Chat",
	profile: "Profil",
	Continue: "Fortsetzen",
	Proceed: "Vorgehen",
	cancelOrder: "cancel order",
	cancelOrderText: "Are you sure you want to cancel selected order item?",
	Free: "Free",
	"Please pick an appointment!": "Please pick an appointment!",
	"Search...": "Search...",
	"yes, i am sure": "yes, i am sure",
	"call off": "cancel",
	Type: "Type",
	"more characters": "more characters",
	Search: "Search...",
	"Please fill out the whole review!": "Please fill out the whole review!",
	"Chat is disabled": "Chat ist deaktiviert",
	"Add new conversation": "Neue Unterhaltung hinzufügen",
	"Remove conversation": "Gespräch entfernen",
	"Type message here": "Nachricht hier eingeben",
	"Log out": "Log out",
	"Guest is not assigned to Room": "Guest is not assigned to Room",
	"Order items have been added to Cart!":
		"Order items have been added to Cart!",
	"There isn't any orders! Make an order and it'll appear here!":
		"There isn't any orders! Make an order and it'll appear here!",
	"No restrictions": "No restrictions",
	Set: "Set",
	"Your review will help us improve GuestApp. Please, give us your honest opinon!":
		"Your review will help us improve GuestApp. Please, give us your honest opinon!",
	"Card payment is not implemented yet!":
		"Card payment is not implemented yet!",
	"Scheduled time has to be greater than current time.":
		"Scheduled time has to be greater than current time.",
	"Closing time of this organisation is at ":
		"Closing time of this organisation is at ",
	submit: "submit",
	"Your rating is successfully saved": "Your rating is successfully saved",
	"Rate our app": "Rate our app",
	"not now": "ne sad",
	"start making an appointment": "start making an appointment",
	"Max. number of reservation by once is":
		"Max. number of reservation by once is ",
	"Please follow these steps to make an appointment":
		"Please, follow these steps to make an appointment!",
	"Choose date and quantity": "Choose date and quantity",
	"Choose date": "Choose date",
	"and time": "and time!",
	Quantity: "Quantity",
	"Choose time": "Choose time:",
	Next: "Next",
	Back: "Back",
	"Item successfully added to Cart!": "Item successfully added to Cart!",
	"Date and quantity": "Date and quantity",
	Time: "Time",
	"Pick exact time": "Pick exact time",
	"First, choose date and select quantity for how many reservations you want to make":
		"First, choose date and select quantity for how many reservations you want to make",
	"First, choose date": "First, choose date",
	"and then, pick exact time of your appointment":
		"and then, pick exact time of your appointment!",
	noSubscriptionsText:
		"There is no any subscriptions! Please, check reception to get one!",
	"My subscriptions": "My subscriptions",
	imePrezimeBrojSobe: "First and last name (room number):",
	duration: "Duration:",
	"Check out": "Check out",
	"how to use app": "How to use app?",
	Refresh: "Refresh",
	"Go to homepage": "Go to homepage",
	"Something went wrong": "Something went wrong!",
	"Room number": "Room number",
	"Currently, making an apointment is not available. Please, try again later!":
		"Currently, making an apointment is not available. Please, try again later!",
	"This item is already in the cart!": "This item is already in the cart!",
	"Choose duration": "Choose duration",
	From: "From",
	To: "To",
	"Purchase date": "Purchase date",
	"Reserved for": "Reserved for:",
	//validacijske poruke
	"Your review for stay is successfully saved!":
		"Your review for stay is successfully saved!",
	"Your review for stay is successfully deleted!":
		"Your review for stay is successfully deleted!",
	"Limit is successfully updated!": "Limit is successfully updated!",
	"You cannot cancel an order item": "You cannot cancel an order item",
	"Review is successfully saved!": "Review is successfully saved!",
	"Item is successfully canceled!": "Item is successfully canceled!",
	"The calendar has no dates available": "The calendar has no dates available",
	"Delivery time has to be after ": "Delivery time has to be after ",
	"Please select stars from 1 to 5!": "Please select stars from 1 to 5!",
	"Star rating is required": "Star rating is required",

	// Tutorial
	next: "next",
	back: "back",
	skip: "skip tutorial",
	tutTitle1: "Order room service",
	tutText1:
		"BROWSE trough large selection of FOOD and BEVERAGES, add what you want to the CART and click CHECKOUT. Your order will be delivered soon to your room.",
	tutTitle2: "Make an appointment",
	tutText2:
		"Want to make an APPOINTMENT in the WELLNESS? No problem. Pick MASSAGE or some other treatment, check available TERMINS and choose one. Add it to the cart and click CHECKOUT. That's it! You have successfully booked an APPOINTMENT. You only have to be there in time.",
	tutTitle3: "Book sport activity",
	tutText3:
		"Do you want to reserve TENNIS COURT or KAYAKS? It is very simple. Browse trough sport category and pick one you desire. Choose available TERMIN and add it to the CART. That's it. You have successfully reserved an SPORT ACTIVITY.",
	tutTitle4: "Review orders",
	tutText4:
		"After every ORDER you can view all your ordered item on the ORDERS screen. If order is completed you can make an REVIEW and help you, other guests and this property to make better service.",
	tutTitle5: "Get informed",
	tutText5:
		"Click BROWSE tab and then HOTEL MAPPING. There you can find various useful information such as: HOUSE RULES, TRANSPORTATION and CONTACT information.",
	tutTitle6: "Stay up to date",
	tutText6:
		"On HOME SCREEN you can always see what is trending in place you staying. There will be information like when and where are next big events such as CONCERTS, PARTIES and other activities.",
	tutTitle7: "Ask a question",
	tutText7:
		"Open CHAT tab and feel free to ask any question. RECEPTION will answer you in short notice. Chat is available 24/7.",
	tutTitle8: "View your stay information",
	tutText8:
		"Click PROFILE tab where you can see your STAY information and all SPENDINGS in your current reservation. View your subscriptions and use them as tickets for various events like BREAKFAST, SWIMMING POOL... ",
	"No results": "Keine Ergebnisse gefunden",
	"Loading results": "Ladeergebnisse",
	//
	"Pickup type": "Abholtyp",
	others: "Andere",
	"Select one or more days": "Wählen Sie einen oder mehrere Tage aus",
};

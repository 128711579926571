// React
import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import ChipMUI from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

// Router
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  slideBtn: {},
  primary: {
    backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
    color: "white",
    flexShrink: "0",
    scrollSnapAlign: "start",
    marginRight: "1rem",
    margin: "5px 0",
    fontWeight: "600",
    boxShadow: theme.shadows[2],
    borderRadius: "10px",
    cursor: "pointer",
    "&:hover, &:focus": {
      backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
    },
  },
  default: {
    backgroundColor: "white",
    backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
    WebkitBackgroundClip: "text",
    "-webkit-text-fill-color": `${theme.palette.secondary.main}`,
    flexShrink: "0",
    scrollSnapAlign: "start",
    marginRight: "1rem",
    margin: "5px 0",
    fontWeight: "600",
    boxShadow: theme.shadows[2],
    borderRadius: "10px",
  },
}));

const SlideChip = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    clickable,
    color,
    disabled,
    label,
    onClick,
    size,
    variant,
    id,
    customStyle,
    goTo,
  } = props;

  return (
    <ChipMUI
      id={id}
      clickable={clickable}
      // className={classes.slideBtn}
      variant={variant}
      className={color ? classes.primary : classes.default}
      onClick={goTo ? () => history.push(goTo) : onClick}
      size={size}
      style={customStyle}
      disabled={disabled}
      label={t(label).toUpperCase()}
    />
  );
};

SlideChip.defaultProps = {
  clickable: true,
  id: "",
  variant: "default",
  label: "",
  onClick: () => console.log("Ouch!"),
  // color: "primary",
  goTo: "",
  icon: false,
  size: "medium",
  disabled: false,
  customStyle: {},
};

export default SlideChip;

// React
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DateRange } from "react-date-range";
import { useSelector } from "react-redux";

// Atoms
import Button from "Components/atoms/buttons/Button";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Slider from "@material-ui/core/Slider";
import Divider from "@material-ui/core/Divider";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ButtonMUI from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// Calendar translate
import enLocale from "date-fns/locale/en-US";
import hrLocale from "date-fns/locale/hr";
import deLocale from "date-fns/locale/de";

// Molecules
import TitleWithDivider from "Components/molecules/TitleWithDivider";

// Utils
import { getCookie } from "Util/functions";
import { GLOBAL_VARIABLES } from "Util/idConfig";

// Calendar style
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const localeMap = {
  en: enLocale,
  hr: hrLocale,
  de: deLocale,
};

const formatDateMap = {
  en: "ll",
  hr: "Do MMM YYYY",
  de: "Do MMM YYYY",
};

const style = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "650px",
  },
  backButton: {
    color: "dimgrey",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  status_div: {
    margin: "1px 0px 0px 15px",
    height: "20px",
    width: "20px",
    borderRadius: "5px",
  },
  horizontal_line_extras: {
    width: "50%",
    marginTop: "11px",
    height: "1px",
    backgroundColor: "#8080804d",
    borderWidth: "0px",
    boxShadow: "0px 0px 1px 0px #b7b5b5b8",
  },
  quantityBaloon: {
    "& .MuiSlider-track": {
      backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
    },
    "& .MuiSlider-thumb": {
      backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
      // borderRadius: "50% 0% 50% 50%",
      "& .PrivateValueLabel-circle-53": {
        backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
      },
    },
  },
  color: {
    color: "grey",
  },
  total: {
    display:"flex",
    flexDirection:"row", 
    justifyContent:"space-between", 
    alignItems:"center", 
    padding:"24px"
  }
}));

const DateRangeDialogContent = (props) => {
  const { t } = useTranslation();
  const classes = style();
  const maxQuantity = useSelector((state) => state.browse?.maxQuantity);
  const primaryColor = JSON.parse(localStorage.getItem("theme"))?.primary_color;

  const [activeStep, setActiveStep] = useState(0);
  const [stateRange, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      control: true,
    },
  ]);
  const [dayCount, setDayCount] = useState(1)

  const countDays = () => {
    let a = moment(stateRange[0]?.startDate)
    let b = moment(stateRange[0]?.endDate)
    setDayCount(Math.ceil(b.diff(a, "days", true)+1))
  }

  useEffect(() => {
    countDays()
    return () => {
    }
  }, [stateRange])
  
  

  const maxNumOfReservations = GLOBAL_VARIABLES.maxNumOfReservations.find(
    (i) => i.name === "date_range"
  ).value;

  useEffect(() => {
    setParentState("date", stateRange[0].startDate);
    setParentState("dateTo", stateRange[0].endDate);
  }, [stateRange]);

  const handleNext = () => {
    handleCheckMaxQuantity(() =>
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleQuantity = (event, newValue) => {
    setParentState("chosenCapacity", newValue);
  };

  const {
    handleSubmitDateRange,
    setParentState,
    chosenCapacity,
    handleCheckMaxQuantity,
    price,
    currency,
  } = props;

  const funcForButtonsDisplay = () => {
    return (
      <Box
        style={{
          padding: "15px",
          bottom: "0px",
          display: "flex",
          position: "fixed",
          width: "100%",
          left: "0px",
          right: "0px",
          backgroundColor: "white",
        }}
      >
        
      <ButtonMUI
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.backButton}
          startIcon={<ArrowBackIosIcon />}
          style={{ margin: "10px" }}
        >
          {t("Back")}
        </ButtonMUI>
        <Button
          onClick={activeStep === 0 ? handleNext : handleSubmitDateRange}
          variant="contained"
          customStyle={{
            width: "100%",
            height: "65px",
            fontWeight: 600,
          }}
          label={t(activeStep === 0 ? "Next" : "add to cart").toUpperCase()}
        />
        
      </Box>
    );
  };

  const firstStep = (
    <>
      <DateRange
        editableDateInputs={true}
        onChange={(item) => {
          //kad se prvi put dodje, postavlja se prvo end date.
          if (stateRange[0].control) {
            setState([
              {
                startDate: stateRange[0].startDate,
                endDate: item.selection.endDate,
                key: "selection",
                control: false,
              },
            ]);
          } else {
            setState([item.selection])
          } ;
          //getDifferenceInDays()
          
        }}
        ranges={stateRange}
        showDateDisplay={false}
        weekStartsOn={1}
        fixedHeight={true}
        minDate={new Date(new Date().setDate(new Date().getDate()))}
        retainEndDateOnFirstSelection={true}
        rangeColors={[primaryColor]}
        locale={localeMap[getCookie("lang")]}
        showMonthAndYearPickers={false}
      />
    </>
  );

  const secondStep = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "15px",
        }}
      >
        <div style={{ display: "table", textAlign: "center" }}>
          <Typography
            variant="caption"
            color="primary"
            classes={{
              colorPrimary: classes.color,
            }}
          >
            {t("From")}:
          </Typography>
          <Typography variant="subtitle2">
            {moment(stateRange[0].startDate).format(
              formatDateMap[getCookie("lang")]
            )}
          </Typography>
        </div>
        <div style={{ display: "table", textAlign: "center" }}>
          <Typography
            variant="caption"
            color="primary"
            classes={{
              colorPrimary: classes.color,
            }}
          >
            {t("To")}:
          </Typography>
          <Typography variant="subtitle2">
            {moment(stateRange[0].endDate).format(
              formatDateMap[getCookie("lang")]
            )}
          </Typography>
        </div>
      </div>
      {maxQuantity && (
        <div
          style={{
            display: "flex",
            placeContent: "space-evenly",
            borderRadius: "10px",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
            padding: "20px",
          }}
        >
          <Slider
            className={classes.quantityBaloon}
            defaultValue={1}
            step={1}
            marks
            min={1}
            max={
              maxQuantity > maxNumOfReservations
                ? maxNumOfReservations
                : maxQuantity
            }
            valueLabelDisplay="on"
            value={chosenCapacity}
            onChange={handleQuantity}
          />
        </div>
      )}
    </>
  );

  return (
    <div id="step">
      <div className={classes.root}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          style={{ padding: "0px 0px" }}
        >
          {[t("Select one or more days"), t("Choose quantity")].map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Divider style={{ margin: "15px" }} />

        {activeStep === 0 && firstStep}
        {activeStep === 1 && secondStep}
        <div className={classes.total}>
          <TitleWithDivider title="total" size="18px" width={"40px"} />
          <Typography style={{fontSize:"18px", fontWeight:"600", color:"dimgray"}}>{`${price>0 ? ((price*chosenCapacity*dayCount).toFixed(2)) : ""} ${currency || ""}`}</Typography>
        </div>
      </div>
      {funcForButtonsDisplay()}
    </div>
  );
};

DateRangeDialogContent.defaultProps = {
  handleSubmitDateRange: () => console.log("handleSubmitDateRange"),
  handleCheckMaxQuantity: () => console.log("handleCheckMaxQuantity"),
  setParentState: null,
  chosenCapacity: 1,
};

export default DateRangeDialogContent;

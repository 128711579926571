// React
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { NotificationManager } from "react-notifications";

// Util functions
import { GLOBAL_VARIABLES } from "Util/idConfig";

// MUI

// Atoms
import Button from "Components/atoms/buttons/Button";

//Dialogs
import SingleViewDialog from "Components/dialogs/SingleViewDialog";

// Actions
import {
	getOneItem,
	clearOneItem,
	viewAvailableTermins,
	openAvailableTermins,
	closeAvailableTermins,
	getQuantityForDateRange,
} from "Modules/units/Browse";
import { addItemsToCart, clearDuration } from "Modules/units/Cart";
import { getAllLists } from "Modules/units/Lists";

const orderButtonText = "choose quantity and extras";
const eventButtonText = "select the number of tickets";
const appointmentButtonText = "start making an appointment";
const datePickerAndDateRangeButtonText = "reserve";

const OrderSection = () => {
	const [openOrder, setOpenOrder] = useState(false);
	const [quantity, setQuantity] = useState(1);
	const [extras, setExtras] = useState([]);
	const [pickupType, setPickupType] = useState(
		/*"eac6d0e7-924b-4d11-b136-84688b559552"*/ null
	);
	const [deliveryOrg, setDeliveryOrg] = useState(null);
	const [deliveryOrgName, setDeliveryOrgName] = useState("");
	const [date, setDate] = useState(new Date());
	const [dateTo, setDateTo] = useState(new Date());
	const [chosenCapacity, setCapacity] = useState(1);
	const [clearInputs, onClearInputs] = useState(false);
	const [appointmentValue, onSetAppointmentValue] = useState(false);

	const curr = JSON.parse(localStorage.getItem("theme"))?.currency;
	const orders = JSON.parse(localStorage.getItem("order"));
	const { t } = useTranslation();
	const dispatch = useDispatch();
	let { camp_id } = useParams();

	const browse = useSelector((state) => state.browse);
	const template_id_temp = useSelector(
		(state) => state.browse?.oneItem?.data?.category?.template
	);

	const handleCloseDialog = (e) => {
		e.stopPropagation();
		setOpenOrder(false);
		onClearInputs(true);
		setCapacity(1);
		setPickupType(/*"eac6d0e7-924b-4d11-b136-84688b559552"*/ null);
		setDeliveryOrg(null);
	};

	const setParentState = (name, value, date) => {
		if (name === "quantity") {
			setQuantity(value);
		} else if (name === "extras") {
			setExtras(value);
		} else if (name === "pickupType") {
			setPickupType(value);
		} else if (name === "deliveryOrg") {
			setDeliveryOrg(value);
		} else if (name === "deliveryOrgName") {
			setDeliveryOrgName(value);
		} else if (name === "date") {
			setDate(value);
		} else if (name === "dateTo") {
			setDateTo(value);
		} else if (name === "chosenCapacity") {
			setCapacity(value);
		} else if (name === "appointment") {
			if (value === null) {
				onSetAppointmentValue(true);
			} else {
				onSetAppointmentValue(false);
				const date_temp = moment(date).format("YYYY-MM-DD");
				setDate(new Date(date_temp + "T" + value + ":00"));
			}
		}
	};

	const handleSubmitAppointment = (data) => {
		dispatch(
			addItemsToCart(
				{
					unique_id: Math.random()
						.toString(36)
						.substr(2, 9),
					data: data,
					date: date,
					quantity: chosenCapacity,
					campaign_id: camp_id,
				},
				() => {
					NotificationManager.success("Added to cart successfully!");
					setOpenOrder(false);
				}
			)
		);
	};

	const handleSubmitDateRange = (data) => {
		dispatch(
			addItemsToCart(
				{
					unique_id: Math.random()
						.toString(36)
						.substr(2, 9),
					data: data,
					date: moment(date).format("YYYY-MM-DD"),
					dateTo: moment(dateTo).format("YYYY-MM-DD"),
					quantity: chosenCapacity,
					campaign_id: camp_id,
				},
				() => {
					NotificationManager.success("Added to cart successfully!");
					setOpenOrder(false);
				}
			)
		);
	};

	const handleSubmitDatePicker = (data) => {
		dispatch(
			addItemsToCart(
				{
					unique_id: Math.random()
						.toString(36)
						.substr(2, 9),
					data: data,
					date: date,
					quantity: quantity,
					campaign_id: camp_id,
				},
				() => {
					NotificationManager.success("Added to cart successfully!");
					setOpenOrder(false);
				}
			)
		);
	};

	const handleStoreArrays = (data) => {
		dispatch(
			addItemsToCart(
				{
					unique_id: Math.random()
						.toString(36)
						.substr(2, 9),
					extras: extras,
					quantity: quantity,
					pickupType,
					deliveryOrg,
					deliveryOrgName,
					data: data,
					campaign_id: camp_id,
				},
				() => {
					NotificationManager.success("Added to cart successfully!");
					setOpenOrder(false);
					setQuantity(1);
					setExtras([]);
					setPickupType(/*"eac6d0e7-924b-4d11-b136-84688b559552"*/ null);
					setDeliveryOrg(null);
				}
			)
		);
	};

	return (
		<>
			{template_id_temp !== GLOBAL_VARIABLES.templates.readonly &&
			template_id_temp !== undefined &&
			browse?.oneItem?.data?.price != null &&
			!browse?.oneItem?.data?.disable_order ? (
				<Button
					customStyle={{
						gridArea: "itemOrderBtn",
						padding: "0.5rem 0.7rem",
						fontSize: "15px",
						fontWeight: 600,
						borderRadius: "10px",
						backgroundImage: "linear-gradient(#00A2BA, #00A2BA)",
						/*height: "65px",
              width: "100%",
              boxShadow: "rgba(0, 0, 0, 0.7) 1px 1px 20px 1px"*/
					}}
					//color={"primary"}
					label={t(
						template_id_temp === GLOBAL_VARIABLES.templates.order ||
							template_id_temp === GLOBAL_VARIABLES.templates.side_dish
							? orderButtonText
							: template_id_temp === GLOBAL_VARIABLES.templates.appointment
							? appointmentButtonText
							: template_id_temp === GLOBAL_VARIABLES.templates.event
							? eventButtonText
							: template_id_temp === GLOBAL_VARIABLES.templates.date_picker ||
							  template_id_temp === GLOBAL_VARIABLES.templates.date_range
							? datePickerAndDateRangeButtonText
							: null
					).toUpperCase()}
					onClick={(e) => {
						e.stopPropagation();
						template_id_temp !== GLOBAL_VARIABLES.templates.appointment
							? setOpenOrder(true)
							: browse?.oneItem?.data?.calendar_max_capacity != null
							? setOpenOrder(true)
							: NotificationManager.info(
									t(
										"Currently, making an apointment is not available. Please, try again later!"
									)
							  );
					}}
					variant="contained"
					dataCy={"viewAnItemButton"}
				/>
			) : null}
			<SingleViewDialog
				opened={openOrder}
				handleClose={handleCloseDialog}
				title={browse?.oneItem?.data?.name}
				price={browse?.oneItem?.data?.price}
				currency={curr}
				maxCap={browse?.oneItem?.data?.calendar_max_capacity}
				template_id={template_id_temp}
				handleSetDefaultValues={() => {
					setDate(new Date());
					setDateTo(new Date());
					setCapacity(1);
					dispatch(clearDuration());
				}}
				handleSubmit={() => {
					handleStoreArrays(browse?.oneItem?.data);
				}}
				handleSubmitDateRange={() => {
					orders?.find((i) => i.data?.id === browse?.oneItem?.data?.id)
						? NotificationManager.error(t("This item is already in the cart!"))
						: handleSubmitDateRange(browse?.oneItem?.data);
				}}
				handleSubmitDatePicker={() => {
					handleSubmitDatePicker(browse?.oneItem?.data);
				}}
				handleViewAppointments={(func) => {
					dispatch(
						viewAvailableTermins(
							browse?.oneItem?.data.id,
							moment(date).format("YYYY-MM-DD"),
							chosenCapacity,
							func
						)
					);
				}}
				handleReserve={() => {
					appointmentValue
						? NotificationManager.error(t("Please pick an appointment!"))
						: (handleSubmitAppointment(browse?.oneItem?.data),
						  dispatch(closeAvailableTermins()));
				}}
				handleCheckMaxQuantity={(func) => {
					dispatch(
						getQuantityForDateRange(
							browse?.oneItem?.data.id,
							moment(date).format("YYYY-MM-DD"),
							moment(dateTo).format("YYYY-MM-DD"),
							func
						)
					);
				}}
				quantity={quantity}
				pickupType={pickupType}
				deliveryOrg={deliveryOrg}
				deliveryOrgName={deliveryOrgName}
				extrasState={browse?.oneItem?.data?.extras_services}
				setParentState={setParentState}
				date={date}
				dateTo={dateTo}
				chosenCapacity={chosenCapacity}
			/>
		</>
	);
};

export default OrderSection;

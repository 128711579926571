/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
// React
import React from "react";
import { useTranslation } from "react-i18next";

// Image
import { SwwImage } from "Components/atoms/imgs/SwwImage";

// Atoms
import Button from "Components/atoms/buttons/Button";

// MUI
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const Sww = (props) => {
  const { resetErrorBoundary, goToHome } = props;
  const { t } = useTranslation();

  return (
    <>
      <Box
        style={{ textAlign: "-webkit-center", display: "block", margin: "8px" }}
      >
        <SwwImage />
        <Typography variant="h6" style={{ marginTop: "10px" }}>
          {t("Something went wrong")}
        </Typography>
        <div style={{ display: "table" }}>
          <Button
            variant="contained"
            label={t("Refresh")}
            onClick={resetErrorBoundary}
            customStyle={{ maxInlineSize: "fit-content", marginTop: "20px" }}
            customFontColor={true}
          />
          <Button
            variant="contained"
            label={t("Go to homepage")}
            onClick={goToHome}
            customStyle={{ maxInlineSize: "fit-content", marginTop: "20px" }}
          />
        </div>
      </Box>
    </>
  );
};

export default Sww;

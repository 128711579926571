// React
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { htmlToText } from "html-to-text";

// MUI
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";

// Atoms
import Price from "Components/atoms/UI/Price";
import Tag from "Pages/browse/components/molecules/Tag";

const AccordionSummary = withStyles({
  root: {
    paddingLeft: 0,
    width: "100%",
  },
  content: {
    margin: 0,
    padding: 0,
    display: "grid",
    gridTemplateColumns: "75px 1fr 1fr 1fr 1fr",
    gridTemplateRows: "45px 30px",
    gridColumnGap: "0px",
    gridRowGap: "0px",
    gridTemplateAreas:
      '"img title title title title" "img priceTag priceTag priceTag priceTag"',
  },
  expandIcon: {},
})(MuiAccordionSummary);

const expandIconStyle = { fontSize: "0.9rem", padding: 0 };

const Summary = (props) => {
  const { t } = useTranslation();
  const {
    id,
    image,
    title,
    description,
    classes,
    price,
    status,
    currency,
  } = props;

  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon sx={expandIconStyle} />}
      aria-controls={`${id}d-content`}
      id={`${id}d-header`}
    >
      <div
        className={classes.itemMiniImage}
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>
      <div className={classes.title}>
        <Typography className={classes.titleHeader}>{t(title)}</Typography>
        <Typography className={classes.titleDesc}>
          {htmlToText(description).replaceAll('*', '')}
        </Typography>
      </div>
      <div className={classes.priceTag}>
        {price > 0 && (
          <Price
            label={`${price ? price.toFixed(2) : ""} ${currency || ""}`}
            customStyle={{
              padding: "0 3px",
              fontSize: "13px",
            }}
          />
        )}
        {status && (
          <Tag
            label={status}
            priceExists={price > 0}
            customStyle={{
              padding: "0 3px",
              fontSize: "13px",
            }}
          />
        )}
      </div>
    </AccordionSummary>
  );
};

Summary.defaultProps = {
  image: "",
  title: "Title",
  description: "Description",
  classes: {},
  id: "",
  price: "",
  status: null,
  currency: null,
};

export default memo(Summary);

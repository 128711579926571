// React
import React from "react";
import { useTranslation } from "react-i18next";

// Material UI
import DialogMUI from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

//Atoms
import IconButton from "Components/atoms/buttons/IconButton";

//Molecules
import OrderDialogContent from "Components/molecules/OrderDialogContent";
import AppointmentDialogContent from "Components/molecules/AppointmentDialogContent";
import DatePickerDialogContent from "Components/molecules/DatePickerDialogContent";

//Dialogs
import DateRangeDialogContent from "Components/molecules/DateRangeDialogContent";

//Utils
import { GLOBAL_VARIABLES } from "Util/idConfig";

const SingleViewDialog = props => {
  const { t } = useTranslation();

  const {
    opened,
    handleClose,
    title,
    price,
    currency,
    handleSubmit,
    template_id,
    setParentState,
    quantity,
    extrasState,
    date,
    dateTo,
    handleSubmitDateRange,
    handleSubmitDatePicker,
    handleViewAppointments,
    handleReserve,
    maxCap,
    chosenCapacity,
    handleCheckMaxQuantity,
    handleSetDefaultValues,
    pickupType,
    deliveryOrg,
    deliveryOrgName
  } = props;

  const variant =
    template_id === GLOBAL_VARIABLES.templates.order ||
    template_id === GLOBAL_VARIABLES.templates.side_dish ||
    template_id === GLOBAL_VARIABLES.templates.event
      ? "order"
      : template_id === GLOBAL_VARIABLES.templates.appointment
      ? "appointment"
      : template_id === GLOBAL_VARIABLES.templates.date_picker
      ? "date_picker"
      : template_id === GLOBAL_VARIABLES.templates.date_range
      ? "date_range"
      : null;

  return (
    <div>
      <DialogMUI
        open={opened}
        fullWidth={true}
        fullScreen={variant !== "order"}
        maxWidth={"sm"}
        onEnter={handleSetDefaultValues}
        onClick={e=>e.stopPropagation()}
      >
        <DialogTitle style={{ textAlign: "left" }} id="alert-dialog-title">
          {`${t(title).toUpperCase()} | (${price>0 ? price.toFixed(2) : ""} ${currency || ""})`}
          <IconButton
            onClick={handleClose}
            icon={<CloseIcon />}
            customStyle={{ float: "right" }}
          />
        </DialogTitle>
        {variant === "order" ? (
          <DialogContent dividers>
            <OrderDialogContent
              setParentState={setParentState}
              quantity={quantity}
              price={price}
              currency={currency}
              extrasState={extrasState}
              handleSubmit={handleSubmit}
              pickupType={pickupType}
              deliveryOrg={deliveryOrg}
              deliveryOrgName={deliveryOrgName}
            />
          </DialogContent>
        ) : variant === "date_picker" ? (
          <DialogContent dividers>
            <DatePickerDialogContent
              setParentState={setParentState}
              handleSubmit={() => console.log("submit")}
              date={date}
              variant={variant}
              title={title}
              handleClose={handleClose}
              handleSubmitDatePicker={handleSubmitDatePicker}
              handleViewAppointments={handleViewAppointments}
              handleReserve={handleReserve}
            />
          </DialogContent>
        ) : variant === "appointment" ? (
          <DialogContent dividers>
            <AppointmentDialogContent
              setParentState={setParentState}
              handleSubmit={() => console.log("submit")}
              date={date}
              variant={variant}
              title={title}
              handleClose={handleClose}
              handleSubmitDatePicker={handleSubmitDatePicker}
              handleViewAppointments={handleViewAppointments}
              handleReserve={handleReserve}
              maxCap={maxCap}
              chosenCapacity={chosenCapacity}
            />
          </DialogContent>
        ) : variant === "date_range" ? (
          <DialogContent dividers>
            <DateRangeDialogContent
              handleSubmit={() => console.log("submit")}
              setParentState={setParentState}
              date={date}
              dateTo={dateTo}
              quantity={quantity}
              price={price}
              currency={currency}
              handleSubmitDateRange={handleSubmitDateRange}
              chosenCapacity={chosenCapacity}
              handleCheckMaxQuantity={handleCheckMaxQuantity}
            />
          </DialogContent>
        ) : null}
      </DialogMUI>
    </div>
  );
};

SingleViewDialog.defaultProps = {
  opened: false,
  handleClose: () => console.log("handleClose"),
  title: "Title",
  handleSubmit: () => console.log("handleSubmit"),
  template_id: null,
  setParentState: null,
  quantity: 1,
  extrasState: [],
  date: new Date(),
  dateTo: new Date(),
  handleSubmitDateRange: () => console.log("handleSubmitDateRange"),
  handleSubmitDatePicker: () => console.log("handleSubmitDatePicker"),
  handleViewAppointments: () => console.log("handleViewAppointments"),
  handleCheckMaxQuantity: () => console.log("handleCheckMaxQuantity"),
  handleSetDefaultValues: () => console.log("handleSetDefaultValues"),
  handleReserve: () => console.log("handleReserve"),
  maxCap: 1,
  chosenCapacity: 1
};

export default SingleViewDialog;

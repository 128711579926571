// React, Redux
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import AlarmIcon from "@material-ui/icons/Alarm";
import moment from "moment";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";

// Atoms
import Button from "Components/atoms/buttons/Button";

// Utils
import { usePersistedState } from "Util/functions";

const useStyles = makeStyles((theme) => ({
	proba: {
		padding: "5px 0px 3px 5px !important",
		width: "15%",
		[theme.breakpoints.down("sm")]: {
			width: "25%",
		},
	},
	specialOutline: {
		borderColor: "white",
	},
	deliveryBox: {
		padding: "5px",
		borderRadius: "10px",
		color: "grey",
		boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
		display: "flex",
		placeContent: "space-between",
	},
	deliveryItem: {
		display: "flex",
		alignItems: "center",
		overflow: "hidden",
		whiteSpace: "pre",
	},
	temp: {
		justifyContent: "center",
	},
}));

const SetDurationTime = (props) => {
	const { closingTime, openingTime, setParentState } = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const cart = useSelector((state) => state.cart);

	const timeAfterAddingDuration = moment(new Date()).add(
		typeof cart?.duration === "string" ? 0 : cart?.duration,
		"minutes"
	);
	const [orderScheduleTemp, setOrderScheduleTemp] = usePersistedState(
		"orderScheduleTemp",
		null
	);

	const [selectedTime, setSelectedTime] = useState(
		orderScheduleTemp &&
			new Date(moment(orderScheduleTemp)).getTime() >=
				new Date(
					moment(timeAfterAddingDuration, "HH:mm").add(1, "minutes")
				).getTime()
			? orderScheduleTemp
			: moment(new Date()).add(
					typeof cart?.duration === "string" ? 1 : cart?.duration + 1,
					"minutes"
			  )
	);

	const handleSelectedTime = (time) => {
		const minTime = moment(new Date(), "HH:mm");
		const maxTime = moment(closingTime, "HH:mm");
		const timeTemp = moment(timeAfterAddingDuration, "HH:mm");

		if (new Date(time).getTime() < new Date(minTime).getTime()) {
			const tempMsg = t(`Scheduled time has to be greater than current time.`);
			NotificationManager.error(tempMsg);
		} else if (new Date(time).getTime() > new Date(maxTime).getTime()) {
			const tempMsg = `${t(
				`Working time of this organisation is from`
			)} ${openingTime} ${t("to")} ${closingTime}`;

			NotificationManager.error(tempMsg);
		} else if (new Date(time).getTime() < new Date(timeTemp).getTime()) {
			const tempMsg =
				t("Delivery time has to be after ") +
				moment(timeAfterAddingDuration).format("HH.mm");

			NotificationManager.error(tempMsg);
		} else {
			setSelectedTime(new Date(time));
			setOrderScheduleTemp(new Date(time));
		}
	};

	useEffect(() => {
		setParentState(selectedTime);
	}, [selectedTime]);

	return (
		<>
			<Box style={{ paddingTop: "15px", paddingBottom: /*"140px"*/ "32px" }}>
				<Box className={classes.deliveryBox}>
					<Box className={classes.deliveryItem}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<TimePicker
								ampm={false}
								value={selectedTime}
								// disabled
								onChange={handleSelectedTime}
								inputVariant="outlined"
								InputProps={{
									endAdornment: (
										<Button
											customFontColor={true}
											label={t("schedule delivery")}
											color="primary"
											customStyle={{ padding: "2px" }}
										/>
									),
									startAdornment: (
										<InputAdornment style={{ color: "slategray" }}>
											<AlarmIcon />
										</InputAdornment>
									),
									classes: {
										root: classes.temp,
										input: classes.proba,
										notchedOutline: classes.specialOutline,
									},
								}}
								cancelLabel={t("Close")}
							/>
						</MuiPickersUtilsProvider>
					</Box>
				</Box>
			</Box>
		</>
	);
};

SetDurationTime.defaultProps = {
	closingTime: null,
	openingTime: null,
	setParentState: null,
};

export default SetDurationTime;

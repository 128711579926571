// React
import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Typography, Box } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

// Atoms
import IconButton from "Components/atoms/buttons/IconButton";

const WarningCartMessage = (props) => {
  const { t } = useTranslation();
  const { classes, error } = props;

  return (
    <>
      <Box
        className={
          error.service?.id || error.quantity
            ? classes.globalWarningMessage
            : classes.warningMessage
        }
      >
        <IconButton icon={<ErrorOutlineIcon />} color="default" />

        {error._schema && (
          <Typography variant="body2">{t(error._schema[0])}</Typography>
        )}

        {error.quantity && (
          <Typography variant="body2" style={{ fontWeight: 500 }}>
            {t("Quantity") + ": " + t(error.quantity)}
          </Typography>
        )}

        {error.service?.id && (
          <Typography variant="body2" style={{ fontWeight: 500 }}>
            {t("Service") + ": " + t(error.service?.id)}
          </Typography>
        )}
      </Box>
    </>
  );
};

WarningCartMessage.defaultProps = {
  error: [],
  classes: {},
};

export default WarningCartMessage;

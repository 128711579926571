// React
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

// Material UI
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MapIcon from "@material-ui/icons/Place";
import Divider from "@material-ui/core/Divider";

// Atoms
import Button from "Components/atoms/buttons/Button";
import SelectList from "Components/atoms/inputs/SelectList";

// Molecules
import TitleWithDivider from "Components/molecules/TitleWithDivider";

// Utils
import { getNameFromList } from "Util/functions";
import { GLOBAL_VARIABLES } from "Util/idConfig";

// Actions
import { getOrgsForPickup } from "Modules/units/Browse";

const style = makeStyles((theme) => ({
	horizontal_line_extras: {
		width: "40%",
		marginTop: "11px",
		marginRight: "0px",
		marginLeft: "auto",
		height: "1px",
		backgroundColor: "#8080804d",
		borderWidth: "0px",
		boxShadow: "0px 0px 1px 0px #b7b5b5b8",
		[theme.breakpoints.down("sm")]: {
			width: "10%",
		},
	},
	quantityBaloon: {
		"& .MuiSlider-track": {
			backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
		},
		"& .MuiSlider-thumb": {
			backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
			// borderRadius: "50% 0% 50% 50%",
			"& .PrivateValueLabel-circle-53": {
				backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
			},
		},
	},
	total: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		margin: "20px 0",
	},
}));

const OrderDialogContent = (props) => {
	const { t } = useTranslation();
	const classes = style();
	const dispatch = useDispatch();
	const [extras_temp, setExtrasTemp] = useState([]);
	const pickupTypes = useSelector(
		(state) => state.browse.oneItem?.data?.service_delivery_types
	);
	const itemId = useSelector((state) => state.browse.oneItem?.data?.id);
	const lists = useSelector((state) => state.lists);
	const cartItems = useSelector((state) => state.cart.items.data);
	const deliveryOrganisations = useSelector((state) => state.browse.pickupOrgs);

	const {
		handleSubmit,
		setParentState,
		quantity,
		price,
		currency,
		extrasState,
		pickupType,
		deliveryOrg,
		deliveryOrgName,
	} = props;

	const handleCheckChieldElement = (item, value) => {
		if (value) {
			setExtrasTemp(extras_temp?.concat(item));
		} else {
			setExtrasTemp(
				extras_temp?.filter((i) => {
					if (i.id === item.id) {
						return null;
					} else return i;
				})
			);
		}
	};

	useEffect(() => {
		dispatch(getOrgsForPickup());

		let foundItem = cartItems.find((item) => item.data.id === itemId);
		if (foundItem) {
			setParentState("pickupType", foundItem.pickupType);
			setParentState("deliveryOrg", foundItem.deliveryOrg);
			setParentState("deliveryOrgName", foundItem.deliveryOrgName);
		}
	}, []);

	useEffect(() => {
		setParentState("extras", extras_temp);
	}, [extras_temp]);

	useEffect(() => {
		if (
			pickupType === GLOBAL_VARIABLES.deliveryTypes.roomService &&
			!cartItems.some((item) => item.data.id === itemId)
		) {
			setParentState("deliveryOrg", null);
			setParentState("deliveryOrgName", "");
		}

		if (
			deliveryOrganisations &&
			deliveryOrganisations[0] &&
			pickupType === GLOBAL_VARIABLES.deliveryTypes.orgPickup &&
			!cartItems.some((item) => item.data.id === itemId)
		) {
			setParentState("deliveryOrg", deliveryOrganisations[0].org_core_id);
			setParentState("deliveryOrgName", deliveryOrganisations[0].name);
		}
	}, [deliveryOrganisations, pickupType]);

	const handleChangeQuantity = (event, newValue) => {
		setParentState("quantity", newValue);
	};

	const getTempExtrasTotal = () => {
		let amount = 0;
		extras_temp.map((i) => {
			amount += i.price;
		});
		return amount;
	};

	return (
		<>
			<div>
				{/* <TitleWithDivider title="quantity" size="18px" width={"40px"} /> */}
				<Slider
					className={classes.quantityBaloon}
					style={{ marginTop: "35px" }}
					defaultValue={2}
					step={1}
					marks
					min={1}
					max={10}
					valueLabelDisplay="on"
					value={quantity}
					onChange={handleChangeQuantity}
				/>
			</div>

			{extrasState.length > 0 && (
				<div style={{ paddingBottom: "20px" }}>
					<TitleWithDivider title="extras" size="18px" width={"40px"} />
					<div style={{ textAlign: "center" }}>
						{extrasState.map((i) => (
							<Box style={{ height: "25px", display: "flex" }}>
								<div style={{ float: "left", overflow: "auto" }}>
									<Typography>{`${t(i.name)} | (${
										i.price > 0 ? i.price.toFixed(2) : ""
									} ${currency || ""})`}</Typography>
								</div>
								<hr className={classes.horizontal_line_extras} />
								<Checkbox
									style={{ float: "right", margin: "0px" }}
									color="primary"
									id={i.id}
									onChange={(e) =>
										handleCheckChieldElement(i, e.target.checked)
									}
								/>
							</Box>
						))}
					</div>
				</div>
			)}

			{pickupTypes.length > 0 && (
				<Box mb={2} width="100%">
					<TitleWithDivider title="Delivery type" size="18px" width={"40px"} />

					{cartItems.some((item) => item.data.id === itemId) ? (
						<>
							<FormControlLabel
								disabled
								checked
								control={<Radio />}
								label={getNameFromList(
									lists,
									"Delivery type",
									cartItems.find((item) => item.data.id === itemId).pickupType
								)}
							/>
							{pickupType === GLOBAL_VARIABLES.deliveryTypes.orgPickup && (
								<>
									<Divider />
									<Box display="flex" alignItems="center" mt={1}>
										<MapIcon color="primary" style={{ paddingRight: "5px" }} />
										<Typography variant="button">{deliveryOrgName}</Typography>
									</Box>
								</>
							)}
						</>
					) : (
						<>
							<FormControl>
								<RadioGroup
									value={pickupType}
									onChange={(e) => setParentState("pickupType", e.target.value)}
								>
									{pickupTypes?.map((item) => (
										<FormControlLabel
											disabled={
												item.delivery_type_id ===
													GLOBAL_VARIABLES.deliveryTypes.orgPickup &&
												deliveryOrganisations.length === 0
											}
											value={item.delivery_type_id}
											control={<Radio />}
											label={
												getNameFromList(
													lists,
													"Delivery type",
													item.delivery_type_id
												) !== "Pickup organisations"
													? getNameFromList(
															lists,
															"Delivery type",
															item.delivery_type_id
													  )
													: "Pick up at"
											}
										/>
									))}
								</RadioGroup>
							</FormControl>

							{pickupType === GLOBAL_VARIABLES.deliveryTypes.orgPickup && (
								<FormControl
									size="small"
									variant="outlined"
									style={{ width: "100%" }}
								>
									<Select
										value={deliveryOrg}
										onChange={(e) => {
											setParentState("deliveryOrg", e.target.value);
											setParentState(
												"deliveryOrgName",
												e.currentTarget.dataset.myValue
											);
										}}
									>
										{deliveryOrganisations?.map((item) => (
											<MenuItem
												key={item.id}
												data-my-value={item.name}
												value={item.org_core_id}
											>
												{item.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
						</>
					)}
				</Box>
			)}

			<div className={classes.total}>
				<TitleWithDivider title="total" size="18px" width={"40px"} />
				<Typography
					style={{ fontSize: "18px", fontWeight: "600", color: "dimgray" }}
				>{`${
					price + getTempExtrasTotal() > 0
						? ((price + getTempExtrasTotal()) * quantity).toFixed(2)
						: ""
				} ${currency || ""}`}</Typography>
			</div>

			<Button
				onClick={handleSubmit}
				variant="contained"
				customStyle={{
					width: "100%",
					height: "65px",
					fontWeight: 600,
				}}
				label={t("add to cart").toUpperCase()}
				dataCy={"orderButton"}
			/>
		</>
	);
};

OrderDialogContent.defaultProps = {
	handleSubmit: () => console.log("handleSubmit"),
	setParentState: null,
	quantity: 1,
	extrasState: [],
};

export default OrderDialogContent;

// React
import React from 'react'

// MUI
import { Typography } from '@material-ui/core'

// Atoms
import IconButton from 'Components/atoms/buttons/IconButton'

const OrgNavigation = ({length, org, index, onClickPrev, onClickNext}) => {
    
    return (
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", margin:"12px 0"}}>
            <IconButton icon={'chevron_left'} onClick={()=>onClickPrev(index)} disabled={index > 0 ? false : true} />
            <Typography style={{fontSize:"20px", textTransform:"uppercase", color:"dimgrey"}}>{org?.name}</Typography>
            <IconButton icon={'chevron_right'} onClick={()=>onClickNext(index)} disabled={index+1 !== length ? false : true} />
        </div>
    )
}

export default OrgNavigation

// React
import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

// MUI
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionActions from "@material-ui/core/AccordionActions";

// Accordion
import Summary from "Pages/browse/components/Accordion/Summary";
import Details from "Pages/browse/components/Accordion/Details";
/*import SummaryImageless from "Pages/browse/components/Accordion/SummaryImageless";
import DetailsImageless from "Pages/browse/components/Accordion/DetailsImageless";*/
import Popups from "Pages/browse/components/Accordion/Popups";
import { useStyles } from "Pages/browse/components/Accordion/accordionStyles";

// Atoms
import IconButton from "Components/atoms/buttons/IconButton";

// Actions
import {
  addItemsToCart,
  clearOneItemFromCart,
  clearDuration,
} from "Modules/units/Cart";
import { clearErrors } from "Modules/units/Order";

// Constants
const reviewsString = "reviews";


const Accordion = withStyles(theme=>({
  root: {
    boxShadow:
      theme.shadows[3],
    borderRadius: "10px !important",
  },
  expanded: { borderRadius: "20px !important" },
}))(MuiAccordion);

const AccordionActions = withStyles({
  root: {
    justifyContent: "center",
    padding: 0,
  },
})(MuiAccordionActions);

const AccordionContent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [openReviews, setOpenReviews] = useState(false);
  const [openState, setOpen] = useState({ open: false, message: null });

  const cartItems = useSelector((state) => state.cart?.items);

  const oneItem = useSelector((state) => state.browse?.oneItem);

  const handleCloseReviews = () => {
    setOpenReviews(false);
  };

  const handleClose = (event, reason) => {
    event?.stopPropagation();
    if (reason === "clickaway") {
      return;
    }

    setOpen({
      open: false,
      message: null,
    });
  };

  const handleAdd = (event) => {
    event.stopPropagation();
    dispatch(
      addItemsToCart({ data: oneItem?.data }, () =>
        setOpen({
          open: true,
          message: "Item is successfully added to Favorites!",
        })
      )
    );
  };

  const handleRemove = (event) => {
    event.stopPropagation();
    dispatch(
      clearOneItemFromCart(id, () =>
        setOpen({
          open: true,
          message: "Item is removed from Favorites!",
        })
      )
    );
    dispatch(clearErrors());
    dispatch(clearDuration());
  };

  useEffect(() => {
    localStorage.setItem("order", JSON.stringify(cartItems?.data));
  }, [cartItems?.data]);

  const {
    image,
    title,
    descriptionText,
    price,
    status,
    currency,
    id,
    expanded,
    handleChange,
  } = props;

  return (
    <>
      <Accordion
        id={`item${id}`}
        expanded={expanded === id}
        onChange={handleChange(id)}
        TransitionProps={{ unmountOnExit: true }}
      >
        {expanded !== id ? (
          <Summary
            id={id}
            image={image}
            description={descriptionText}
            classes={classes}
            title={title}
            price={price}
            status={status}
            currency={currency}
          />
        ) : (
          <Details
            id={id}
            image={image}
            expanded={expanded}
            classes={classes}
            title={title}
            reviewsString={reviewsString}
            price={price}
            status={status}
            currency={currency}
            descriptionText={descriptionText}
            handleChange={handleChange}
            handleAdd={handleAdd}
            handleRemove={handleRemove}
            setOpenReviews={setOpenReviews}
          />
        )}
        <AccordionActions onClick={handleChange(id)}>
          <IconButton color="default" size="medium" icon={<ExpandLessIcon />} />
        </AccordionActions>
      </Accordion>
      <Popups
        reviewsString={reviewsString}
        classes={classes}
        openState={openState}
        handleClose={handleClose}
        openReviews={openReviews}
        handleCloseReviews={handleCloseReviews}
        oneItem={oneItem}
      />
    </>
  );
};

AccordionContent.defaultProps = {
  image: "",
  component: "img",
  title: "Title",
  price: "",
  id: "",
  descriptionText: "",
  currency: null,
  expanded: false,
  handleChange: () => console.log("change"),
};

export default memo(AccordionContent);

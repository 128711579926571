// React
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Calendar } from "react-date-range";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import ButtonMUI from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Slider from "@material-ui/core/Slider";

// Radio
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Atoms
import Button from "Components/atoms/buttons/Button";

// Calendar translate
// import enLocale from "date-fns/locale/en-US";
// import hrLocale from "date-fns/locale/hr";
// import deLocale from "date-fns/locale/de";

import { enLocale, hrLocale, deLocale } from "date-fns/locale";

// Utils
import { getCookie } from "Util/functions";
import { GLOBAL_VARIABLES } from "Util/idConfig";

// Calendar style
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const localeMap = {
	en: enLocale,
	hr: hrLocale,
	de: deLocale,
};

const style = makeStyles((theme) => ({
	root: {
		width: "100%",
		minHeight: "650px",
	},
	backButton: {
		// marginRight: theme.spacing(1),
		color: "dimgrey",
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	status_div: {
		margin: "1px 0px 0px 15px",
		height: "20px",
		width: "20px",
		borderRadius: "5px",
	},
	horizontal_line_extras: {
		width: "50%",
		marginTop: "11px",
		height: "1px",
		backgroundColor: "#8080804d",
		borderWidth: "0px",
		boxShadow: "0px 0px 1px 0px #b7b5b5b8",
	},
	quantityBaloon: {
		"& .MuiSlider-track": {
			backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
		},
		"& .MuiSlider-thumb": {
			backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
			// borderRadius: "50% 0% 50% 50%",
			"& .PrivateValueLabel-circle-53": {
				backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
			},
		},
	},
}));

const AppointmentDialogContent = (props) => {
	const classes = style();
	const { t } = useTranslation();
	const browse = useSelector((state) => state.browse);
	const primaryColor = JSON.parse(localStorage.getItem("theme"))?.primary_color;

	const stepperRef = useRef(null);

	const [stepperHeight, setStepperHeight] = useState(0);
	const [activeStep, setActiveStep] = useState(0);
	const [valuetemp, setValue] = useState("");

	const maxNumOfReservations = GLOBAL_VARIABLES.maxNumOfReservations.find(
		(i) => i.name === "appointment"
	).value;

	useEffect(() => {
		if (typeof stepperRef?.current?.offsetHeight === "number") {
			setStepperHeight(stepperRef?.current?.offsetHeight);
		}
	}, [stepperRef]);

	useEffect(() => {
		document.getElementById("step").scrollIntoView();
	}, [activeStep]);

	function getTermins(data) {
		if (typeof data === "object") {
			const data2 = Object.entries(data);
			return data2.map(([key, value]) => {
				return (
					<RadioGroup
						onChange={handleChange}
						key={key}
						style={{ height: "25px", display: "flex" }}
						value={valuetemp}
					>
						<div style={{ marginRight: "auto", width: "40px" }}>
							<Typography>{key}</Typography>
						</div>
						<hr className={classes.horizontal_line_extras} />
						<div style={{ display: "contents", marginRight: "0px" }}>
							<FormControlLabel
								value={key}
								control={<Radio />}
								style={{
									margin: "-10px 0px 0px 5px",
									width: "4%",
								}}
								disabled={value === 2}
							/>

							<div
								className={classes.status_div}
								style={{
									backgroundImage:
										value === 0
											? "linear-gradient(90deg, rgb(0, 119, 0), #1baf00)"
											: value === 1
											? "linear-gradient(90deg, rgb(249 241 0), #e8d700)"
											: value === 2
											? "linear-gradient(90deg, rgb(249 0 0), #800000)"
											: null,
								}}
							/>
						</div>
					</RadioGroup>
				);
			});
		}
	}

	const handleChange = (event) => {
		setValue(event.target.value);
		setParentState("appointment", event.target.value, date);
	};

	const handleNext = () => {
		handleViewAppointments(() =>
			setActiveStep((prevActiveStep) => prevActiveStep + 1)
		);
	};

	const handleBack = () => {
		setValue("");
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleChangeDate = (event, newValue) => {
		setParentState("date", event);
	};

	const handleQuantity = (event, newValue) => {
		setParentState("chosenCapacity", newValue);
	};

	const {
		handleViewAppointments,
		setParentState,
		date,
		handleReserve,
		maxCap,
		chosenCapacity,
	} = props;

	const FuncForButtonsDisplay = () => {
		return (
			<Box
				ref={stepperRef}
				style={{
					padding: "15px",
					bottom: "0px",
					display: "flex",
					position: "fixed",
					width: "100%",
					left: "0px",
					right: "0px",
					backgroundColor: "white",
				}}
			>
				<ButtonMUI
					disabled={activeStep === 0}
					onClick={handleBack}
					className={classes.backButton}
					startIcon={<ArrowBackIosIcon />}
					style={{ margin: "10px" }}
				>
					{t("Back")}
				</ButtonMUI>
				<Button
					onClick={activeStep === 0 ? handleNext : handleReserve}
					variant="contained"
					customStyle={{
						width: "100%",
						height: "65px",
						fontWeight: 600,
					}}
					label={t(activeStep === 0 ? "Next" : "add to cart").toUpperCase()}
				/>
			</Box>
		);
	};

	const firstStep = (
		<>
			<Calendar
				onChange={handleChangeDate}
				locale={localeMap[getCookie("lang")]}
				date={date}
				weekStartsOn={1}
				showMonthAndYearPickers={false}
				minDate={new Date(new Date().setDate(new Date().getDate()))}
				color={primaryColor}
				fixedHeight={true}
			/>
			<br />
			<br />
			{maxCap ? (
				<div
					style={{
						display: "flex",
						placeContent: "space-evenly",
						borderRadius: "10px",
						boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
						padding: "20px",
					}}
				>
					<Slider
						className={classes.quantityBaloon}
						defaultValue={1}
						step={1}
						marks
						min={1}
						max={maxCap > maxNumOfReservations ? maxNumOfReservations : maxCap}
						valueLabelDisplay="on"
						value={chosenCapacity}
						onChange={handleQuantity}
						disabled={maxCap === 1}
					/>
				</div>
			) : (
				<Typography
					variant="caption"
					style={{ display: "block", textAlign: "center" }}
				>
					{t(
						"Currently, making an apointment is not available. Please, try again later!"
					)}
				</Typography>
			)}
		</>
	);

	const secondStep = (
		<>
			<div
				style={{
					placeContent: "space-evenly",
					borderRadius: "10px",
					boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
					textAlign: "center",
					padding: "10px",
				}}
			>
				{getTermins(browse?.available?.data)}
			</div>
		</>
	);

	return (
		<div id="step">
			<div
				className={classes.root}
				style={{ marginBottom: activeStep !== 0 ? stepperHeight : "auto" }}
			>
				<Stepper
					activeStep={activeStep}
					alternativeLabel
					style={{ padding: "0px 0px" }}
				>
					{[
						maxCap > 1 ? t("Choose date and quantity") : t("Choose date"),
						t("Pick exact time"),
					].map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<Divider style={{ margin: "15px" }} />

				{activeStep === 0 && firstStep}
				{activeStep === 1 && secondStep}
			</div>
			<FuncForButtonsDisplay />
		</div>
	);
};

AppointmentDialogContent.defaultProps = {
	handleViewAppointments: () => console.log("handleViewAppointments"),
	handleReserve: () => console.log("handleReserve"),
	setParentState: null,
	date: new Date(),
	maxCap: 1,
	chosenCapacity: 1,
};

export default AppointmentDialogContent;

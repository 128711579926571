import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    gridArea: "title",
    paddingLeft: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    paddingTop: "4px",
  },
  titleImageless: {
    gridArea: "title",
    paddingLeft: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  titleHeader: {
    fontSize: "15px",
    fontWeight: "600",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  titleHeaderImageless: {
    fontSize: "15px",
    fontWeight: "600",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },
  },
  titleDesc: {
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: "13px",
    mozLineHeight: "13px",
    fontSize: "12px",
    fontWeight: "normal",
    color: "dimgrey",
  },
  itemMiniImage: {
    gridArea: "img",
    width: "100%",
    minHeight: "75px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px",
  },
  priceTag: {
    paddingLeft: "8px",
    gridArea: "priceTag",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    inlineSize: "fit-content",
  },
  priceTagImageless: {
    //paddingLeft: "8px",
    gridArea: "priceTag",
    display: "flex",
    flexDirection:"row",
    //justifyContent: "space-between",
    alignItems: "center",
    inlineSize: "fit-content",
  },

  itemImage: {
    gridArea: "itemImage",
    width: "100%",
    height: "250px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "20px",
    padding: "5px 5px 15px 5px",
    marginBottom: "10px",
    //marginTop: "5px",
    boxShadow: theme.shadows[2],
    [theme.breakpoints.up("sm")]: {
      marginBottom: "25px",
    },
  },
  itemDesc: {
    gridArea: "itemDesc",
    display: "grid",
    gridTemplateColumns: "auto",
    gridTemplateRows: "auto",
    rowGap: "6px",
    gridTemplateAreas:
      '"itemTitle itemTitle itemTitle" "itemButtons . favBtn" "itemText itemText itemText"',
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  itemOrder: {
    gridArea: "itemOrder",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "auto",
    //rowGap: "4px",
    gridTemplateAreas:
      '"itemOrderBtn itemOrderBtn itemOrderBtn"',
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  /*itemOrderBtn: {
    gridArea: "itemOrder",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "auto",
    //rowGap: "4px",
    gridTemplateAreas:
      '"itemOrderBtn itemOrderBtn itemOrderBtn"',
    paddingLeft: "16px",
    paddingRight: "16px",
  },*/
  itemDescImageless: {
    marginTop:'16px',
    gridArea: "itemDesc",
    display: "grid",
    gridTemplateColumns: "auto",
    gridTemplateRows: "auto",
    rowGap: "6px",
    gridTemplateAreas:
      '"itemTitle itemTitle itemTitle" "itemButtons . favBtn" "itemText itemText itemText"',
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  itemReview: {
    gridArea: "itemReview",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "auto",
    rowGap: "4px",
    gridTemplateAreas:
      '"itemReviewTitle itemReviewTitle itemReviewTitle" "itemReviewContent itemReviewContent itemReviewContent"',
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  itemTitle: {
    gridArea: "itemTitle",
  },
  detailsTitle: {
    fontSize: "16px",
    fontWeight: "600",
  },
  itemButtons: {
    gridArea: "itemButtons",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    inlineSize: "fit-content",
  },
  favBtn: {
    gridArea: "favBtn",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  itemText: {
    gridArea: "itemText",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    paddingTop: "8px",
  },
  itemTextContent: {
    fontSize: "16px",
    margin:"4px 0",
    '& p': {
      margin:'0'
    }
  },
  itemReviewTitle: {
    gridArea: "itemReviewTitle",
    backgroundColor: "#f8f8ff",
    padding: "0.5rem 0.7rem",
    borderRadius: "10px",
  },
  itemReviewTitleText: { color: "grey", fontWeight: "600" },
  itemReviewContent: {
    gridArea: "itemReviewContent",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    display: "grid",
    fontSize: "13px !important",
    paddingTop: "8px",
  },
  boxInDialog: {
    borderRadius: "10px",
    boxShadow: theme.shadows[2],
    backgroundColor: "white",
    display: "grid",
    padding: "10px",
    marginTop: "10px",
    height: "125px",
  },
  boxInDialogReview: {
    height: "45px",
    padding: "10px",
    boxShadow: theme.shadows[2],
    borderRadius: "10px",
    backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
    color: "white",
  },
  reviewsString: {
    float: "left",
    fontSize: "small",
    marginTop: "3px",
  },
  box: {
    borderRadius: "10px",
    boxShadow: theme.shadows[2],
    backgroundColor: "white",
    display: "grid",
    padding: "10px",
    marginTop: "10px"
  },
}));

export { useStyles };

// React
import React from "react";
import moment from "moment";

// MUI
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import ReviewStar from "Components/atoms/UI/ReviewStar";

const style = makeStyles((theme) => ({
  reviewDescription: { color: "grey", overflow: "auto", height: "75px" },
}));

const SingleReview = (props) => {
  const classes = style();

  const { variant, name, date, stars, reviewDesc, customStyle } = props;

  return (
    <>
      <div>
        <Typography style={{ float: "left" }} variant={variant}>
          {name}
          {" - "}
          {moment(date).format("ll")}
        </Typography>

        <ReviewStar customStyle={{ float: "right" }} label={stars} />
      </div>
      <div className={classes.reviewDescription} style={customStyle}>
        {reviewDesc}
      </div>
    </>
  );
};

SingleReview.defaultProps = {
  variant: "subtitle1",
  name: "Name",
  date: new Date(),
  stars: "",
  reviewDesc: "",
  customStyle: {},
};

export default SingleReview;

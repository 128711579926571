module.exports = {
	"Invalid code!": "Invalid code!",
	Food: "Food",
	Drinks: "Drinks",
	Massage: "Massage",
	Cleaning: "Cleaning",
	Taxi: "Taxi",
	Souvenirs: "Souvenirs",
	"CHOOSE QUANTITY AND EXTRAS": "CHOOSE QUANTITY AND EXTRAS",
	"choose quantity and extras": "choose quantity and extras",
	"Choose quantity": "Choisir la quantité",
	"select the number of tickets": "select the number of tickets",
	"SELECT THE NUMBER OF TICKETS": "SELECT THE NUMBER OF TICKETS",
	"Select the number of tickets": "Select the number of tickets",
	reviews: "reviews",
	Close: "Close",
	New: "New",
	all: "all",
	Pizza: "Pizza",
	Pasta: "Pasta",
	Fish: "Fish",
	"my cart": "my cart",
	checkout: "checkout",
	"remove all": "remove all",
	"Delivery schedule may differ because items are from different locations.":
		"Delivery schedule may differ because items are from different locations.",
	"TOTAL ITEMS": "ARTICLES AU TOTAL",
	"Show details": "Afficher les détails",
	appointments: "réservations",
	events: "événements",
	Ketchup: "Ketchup",
	Mayonnaise: "Mayonnaise",
	"Olive oil": "Olive oil",
	"Hot sauce": "Hot sauce",
	extras: "extras",
	quantity: "quantity",
	"add to cart": "add to cart",
	"room bill": "room bill",
	"pay with card": "pay with card",
	"Order successfully placed!": "Order successfully placed!",
	"You can always track your orders in ORDERS section under main menu.":
		"You can always track your orders in ORDERS section under main menu.",
	"order more": "order more",
	"my orders": "my orders",
	"My stay": "My stay",
	"Rate your stay": "Rate your stay",
	"delete rating": "delete rating",
	accomodation: "accomodation",
	service: "service",
	review: "review",
	"Type here...": "Type here...",
	confirm: "confirm",
	"Floor & Room": "Floor & Room",
	"Departure time": "Departure time",
	"My balance": "My balance",
	"My spendings": "My spendings",
	Spendings: "Spendings",
	Spent: "Spent",
	notifications: "notifications",
	"read all": "read all",
	chips: "chips",
	tortillas: "tortillas",
	Hamburger: "Hamburger",
	"special offer": "special offer",
	"Special offer": "Special offer",
	Popular: "Popular",
	"today's specialities": "today's specialities",
	"my timeline": "my timeline",
	pending: "pending",
	finished: "finished",
	"Swimming pool": "Swimming pool",
	rate: "rate",
	"chat with triplehost": "chat with triplehost",
	"This feature is coming soon...": "This feature is coming soon...",
	"8:00": "8:00",
	total: "total",
	"view available termins": "view available termins",
	reserve: "reserve",
	"MAKE AN APPOINTMENT": "MAKE AN APPOINTMENT",
	"make an appointment": "make an appointment",
	ORDER: "ORDER",
	order: "order",
	"Odaberi početni datum": "Choose start date",
	"Odaberi krajnji datum": "Choose end date",
	"Cart contains invalid items": "Cart contains invalid items",
	"Scheduled date is in past": "Scheduled date is in past",
	"Date from is greater than date to": "Date from is greater than date to",
	"Enter the amount": "Enter the amount",
	"Set a limit": "Set a limit",
	Ban: "ban",
	Limitation: "limitation",
	"Not a valid number.": "Not a valid number.",
	"Amount can not be negative ": "Amount can not be negative",
	close: "close",
	continue: "continue",
	"You have exceeded the limit": "You have exceeded the limit!",
	"Do you want cancel an action or continue?":
		"Do you want cancel an action or continue?",
	"Date to must be greater than date from":
		"Date to must be greater than date from",
	"not rated": "not rated",
	"on room bill": "on room bill",
	"my orders & appointments": "my orders & appointments",
	Accommodation: "Accommodation",
	Service: "Service",
	"A review": "A review",
	"schedule delivery": "schedule delivery",
	home: "home",
	browse: "browse",
	orders: "orders",
	chat: "chat",
	profile: "profile",
	Continue: "Continue",
	Proceed: "Proceed",
	cancelOrder: "cancel order",
	cancelOrderText: "Are you sure you want to cancel selected order item?",
	Free: "Free",
	"Please pick an appointment!": "Please pick an appointment!",
	"Search...": "Search...",
	"yes, i am sure": "yes, i am sure",
	"call off": "cancel",
	Type: "Type",
	"more characters": "more characters",
	Search: "Search...",
	"Please fill out the whole review!": "Please fill out the whole review!",
	"Chat is disabled": "Chat is disabled",
	"Add new conversation": "Add new conversation",
	"Remove conversation": "Remove conversation",
	"Type message here": "Type message here",
	"Log out": "Log out",
	"Guest is not assigned to Room": "Guest is not assigned to Room",
	"Order items have been added to Cart!":
		"Order items have been added to Cart!",
	"There isn't any orders! Make an order and it'll appear here!":
		"There isn't any orders! Make an order and it'll appear here!",
	"No restrictions": "No restrictions",
	Set: "Set",
	"Your review will help us improve GuestApp. Please, give us your honest opinon!":
		"Your review will help us improve GuestApp. Please, give us your honest opinon!",
	"Card payment is not implemented yet!":
		"Card payment is not implemented yet!",
	"Scheduled time has to be greater than current time.":
		"Scheduled time has to be greater than current time.",
	"Closing time of this organisation is at ":
		"Closing time of this organisation is at ",
	submit: "submit",
	"Your rating is successfully saved": "Your rating is successfully saved",
	"Rate our app": "Rate our app",
	"not now": "not now",
	"start making an appointment": "start making an appointment",
	"Max. number of reservation by once is":
		"Max. number of reservation by once is ",
	"Please follow these steps to make an appointment":
		"Please, follow these steps to make an appointment!",
	"Choose date and quantity": "Choose date and quantity",
	"Choose date": "Choose date",
	"and time": "and time!",
	Quantity: "Quantity",
	"Choose time": "Choose time:",
	Next: "Next",
	Back: "Back",
	"Item successfully added to Cart!": "Item successfully added to Cart!",
	"Date and quantity": "Date and quantity",
	Time: "Time",
	"Pick exact time": "Pick exact time",
	"First, choose date and select quantity for how many reservations you want to make":
		"First, choose date and select quantity for how many reservations you want to make",
	"First, choose date": "First, choose date",
	"and then, pick exact time of your appointment":
		"and then, pick exact time of your appointment!",
	noSubscriptionsText:
		"There is no any subscriptions! Please, check reception to get one!",
	"My subscriptions": "My subscriptions",
	imePrezimeBrojSobe: "First and last name (room number):",
	duration: "Duration:",
	"Check out": "Check out",
	"how to use app": "How to use app?",
	Refresh: "Refresh",
	"Go to homepage": "Go to homepage",
	"Something went wrong": "Something went wrong!",
	"Room number": "Room number",
	"Currently, making an apointment is not available. Please, try again later!":
		"Currently, making an apointment is not available. Please, try again later!",
	"This item is already in the cart!": "This item is already in the cart!",
	"Choose duration": "Choose duration",
	From: "From",
	To: "To",
	"Purchase date": "Purchase date",
	"Reserved for": "Reserved for:",
	//validacijske poruke
	"Your review for stay is successfully saved!":
		"Your review for stay is successfully saved!",
	"Your review for stay is successfully deleted!":
		"Your review for stay is successfully deleted!",
	"Limit is successfully updated!": "Limit is successfully updated!",
	"You cannot cancel an order item": "You cannot cancel an order item",
	"Review is successfully saved!": "Review is successfully saved!",
	"Item is successfully canceled!": "Item is successfully canceled!",
	"The calendar has no dates available": "The calendar has no dates available",
	"Delivery time has to be after ": "Delivery time has to be after ",
	"Please select stars from 1 to 5!": "Please select stars from 1 to 5!",
	"Star rating is required": "Star rating is required",

	// Tutorial
	next: "next",
	back: "back",
	skip: "skip tutorial",
	tutTitle1: "Order room service",
	tutText1:
		"BROWSE trough large selection of FOOD and BEVERAGES, add what you want to the CART and click CHECKOUT. Your order will be delivered soon to your room.",
	tutTitle2: "Make an appointment",
	tutText2:
		"Want to make an APPOINTMENT in the WELLNESS? No problem. Pick MASSAGE or some other treatment, check available TERMINS and choose one. Add it to the cart and click CHECKOUT. That's it! You have successfully booked an APPOINTMENT. You only have to be there in time.",
	tutTitle3: "Book sport activity",
	tutText3:
		"Do you want to reserve TENNIS COURT or KAYAKS? It is very simple. Browse trough sport category and pick one you desire. Choose available TERMIN and add it to the CART. That's it. You have successfully reserved an SPORT ACTIVITY.",
	tutTitle4: "Review orders",
	tutText4:
		"After every ORDER you can view all your ordered item on the ORDERS screen. If order is completed you can make an REVIEW and help you, other guests and this property to make better service.",
	tutTitle5: "Get informed",
	tutText5:
		"Click BROWSE tab and then HOTEL MAPPING. There you can find various useful information such as: HOUSE RULES, TRANSPORTATION and CONTACT information.",
	tutTitle6: "Stay up to date",
	tutText6:
		"On HOME SCREEN you can always see what is trending in place you staying. There will be information like when and where are next big events such as CONCERTS, PARTIES and other activities.",
	tutTitle7: "Ask a question",
	tutText7:
		"Open CHAT tab and feel free to ask any question. RECEPTION will answer you in short notice. Chat is available 24/7.",
	tutTitle8: "View your stay information",
	tutText8:
		"Click PROFILE tab where you can see your STAY information and all SPENDINGS in your current reservation. View your subscriptions and use them as tickets for various events like BREAKFAST, SWIMMING POOL...",
	"No results": "Aucun résultat trouvé",
	"Loading results": "Chargement des résultats",
	//
	"Pickup type": "Type de ramassage",
	others: "les autres",
	"Select one or more days": "Sélectionnez un ou plusieurs jours",
};

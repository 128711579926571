// React, Redux
import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import { FormControl, Select, InputLabel, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    borderRadius: "10px",
    width: "100%",
  },
}));

const SelectList = (props) => {
  const { t } = useTranslation();
  const classes = style();

  const {
    margin,
    type,
    value,
    onChange,
    required,
    disabled,
    lists,
    listItem,
    label,
    name,
    customStyle,
  } = props;

  return (
    <FormControl
      size="small"
      variant="outlined"
      className={classes.formControl}
      style={customStyle}
    >
      <Select
        margin={margin}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label={t(label)}
        name={name}
        fullWidth
        disabled={disabled}
        type={type}
        required={required}
      >
        {lists?.map((item) => (
          <MenuItem key={item.id} value={item[listItem]}>
            {item[listItem]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectList.defaultProps = {
  type: "text",
  value: "",
  error: false,
  helperText: "",
  required: false,
  disabled: false,
  margin: "normal",
  name: "",
  customStyle: null,
};

export default SelectList;

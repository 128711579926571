// React
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";

// MUI
import { Typography, Box, Divider } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

// Atoms
import Price from "Components/atoms/UI/Price";
import IconButton from "Components/atoms/buttons/IconButton";

// Molecules
import NumericInput from "Components/molecules/NumericInput";

//Util
import { GLOBAL_VARIABLES } from "Util/idConfig";
import { getNameFromList } from "Util/functions";

const Accordion = withStyles({
	root: {
		//position:"absolute",
		zIndex: 1,
		padding: 0,
		margin: 0,
		border: 0,
		boxShadow: "none",
		"&:not(:last-child)": {
			borderBottom: 0,
		},
		"&:before": {
			display: "none",
		},
		"&$expanded": {
			margin: 0,
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		margin: 0,
		padding: 0,
		backgroundColor: "rgba(0, 0, 0, .03)",
		//borderBottom: '1px solid rgba(0, 0, 0, .125)',
		//marginBottom: -1,

		minHeight: 20,
		"&$expanded": {
			minHeight: 20,
		},
	},
	content: {
		margin: 0,
		padding: 0,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		"&$expanded": {
			margin: 0,
			padding: 0,
		},
	},
	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
		display: "flex",
		flexDirection: "column",
	},
}))(MuiAccordionDetails);

const AlternativeCartContent = (props) => {
	const { t } = useTranslation();

	const [expanded, setExpanded] = useState(null);

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const handleLocalStorage1 = (quantity) => {
		handleLocalStorage(unique_id, quantity);
	};

	function colorCharacter(id) {
		if (organisations?.length > 0) {
			let color_temp;
			let char_temp;
			organisations?.map((i) => {
				if (i.id === id) {
					color_temp = i.color;
					char_temp = i.name.split(" ")[0];
				}
			});

			return (
				<Box
					className={classes.itemSize}
					style={{
						/*alignSelf: "start",
            position: "absolute",*/
						backgroundColor: color_temp,
						textTransform: "uppercase",
						fontWeight: "bold",
						fontSize: "10px",
						color: "#fff",
						border: "2px solid #fff",
						borderRadius: "0px 0px 0px 10px",
						margin: "0px",
						padding: "0px",
						width: "100%",
						height: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderRight: 0,
					}}
				>
					{char_temp}
				</Box>
			);
		}
	}

	const {
		image,
		title,
		price,
		extras,
		organisation,
		quantity,
		handleLocalStorage,
		unique_id,
		currency,
		handleRemoveItem,
		classes,
		date,
		dateTo,
		template,
		error,
		organisations,
		pickupType,
		deliveryOrgName,
		duration,
	} = props;

	const lists = useSelector((state) => state.lists);

	return (
		<>
			<div className={classes.container}>
				<div className={classes.cartItemHeader}>
					<div
						className={classes.img}
						style={{ backgroundImage: `url(${image})` }}
					></div>
					<div className={classes.organisation}>
						{colorCharacter(organisation)}
					</div>
				</div>
				<div className={classes.cartItemDescription}>
					<div className={classes.title}>
						<Typography className={classes.titleText}>{t(title)}</Typography>
					</div>
					<div className={classes.info}>
						{extras.length > 0 || pickupType ? (
							<Accordion
								square
								expanded={expanded === unique_id}
								onChange={handleChange(unique_id)}
							>
								<AccordionSummary aria-controls="order" id="order-header">
									{expanded === unique_id ? (
										<KeyboardArrowUpIcon fontSize="small" color="default" />
									) : (
										<KeyboardArrowDownIcon fontSize="small" color="default" />
									)}
									<Typography className={classes.infoExtrasTitle}>
										{t("Show details")}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography className={classes.infoExtrasText}>
										{extras.map((i, index) => {
											return (
												t(i.name) +
												(i.charge_as_side_dish
													? ` (${i.price?.toFixed(2)} ${
															currency ? currency : ""
													  })`
													: ` (${t("Free")})`) +
												(index !== extras.length - 1 ? ", " : "")
											);
										})}
										{extras.length > 0 && pickupType ? (
											<Divider></Divider>
										) : null}
									</Typography>

									<Typography variant="caption">
										{`${
											pickupType
												? getNameFromList(lists, "Delivery type", pickupType)
												: ""
										} ${deliveryOrgName ? `@ ${deliveryOrgName}` : ""}`}
									</Typography>
								</AccordionDetails>
							</Accordion>
						) : null}
						{date ? (
							<Typography className={classes.infoDateText}>
								{template === GLOBAL_VARIABLES.templates.date_picker &&
									date &&
									moment(date).format("ll")}
								{template === GLOBAL_VARIABLES.templates.date_range &&
								date &&
								dateTo
									? moment(date).format("ll") === moment(dateTo).format("ll")
										? moment(date).format("ll")
										: moment(date).format("ll") +
										  " - " +
										  moment(dateTo).format("ll")
									: null}
								{template === GLOBAL_VARIABLES.templates.appointment && (
									<>
										{date && moment(date).format("lll") + ", "}
										<AccessTimeIcon fontSize="smaller" />
										{duration + "min"}
									</>
								)}
							</Typography>
						) : null}
					</div>
					<div className={classes.actions}>
						{price > 0 && (
							<Price
								customStyle={{
									padding: "0 3px",
									fontSize: "13px",
								}}
								label={
									(price ? price.toFixed(2) : "") +
									" " +
									(currency ? currency : "")
								}
							/>
						)}

						{(template === GLOBAL_VARIABLES.templates.order ||
							template === GLOBAL_VARIABLES.templates.date_range ||
							template === GLOBAL_VARIABLES.templates.event ||
							(template === GLOBAL_VARIABLES.templates.appointment &&
								quantity > 1)) && (
							// quantity != 0 && (
							<div style={{ display: "flex" }}>
								<NumericInput
									quantity={quantity}
									handleLocalStorage1={handleLocalStorage1}
									disabled={error}
									justValue={
										template === GLOBAL_VARIABLES.templates.date_range ||
										template === GLOBAL_VARIABLES.templates.appointment
									}
								/>
							</div>
						)}
					</div>
				</div>
				<div className={classes.closeBtn}>
					<IconButton
						icon={<Close />}
						color="default"
						onClick={handleRemoveItem}
					/>
				</div>
			</div>

			<div></div>
		</>
	);
};

AlternativeCartContent.defaultProps = {
	image: "",
	title: "Title",
	price: "",
	extras: [],
	organisation: "",
	quantity: 1,
	handleLocalStorage: null,
	unique_id: "",
	currency: null,
	handleRemoveItem: () => console.log("remove!"),
	classes: {},
	error: false,
	organisations: null,
};

export default AlternativeCartContent;

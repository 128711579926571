export const GLOBAL_VARIABLES = {
  subcategories: {
    all: {
      id: "d7232b25-dff5-4cea-8748-66a9a604c68a",
      name: "all"
    }
  },
  card_sizes: {
    large: "328fa834-12a0-41cf-a0a8-70864310ff63"
  },
  templates: {
    appointment: "f8a588e7-9949-4ce3-973c-303785b955f7",
    order: "24160744-38f4-48fc-9a51-67322e5d23a3",
    date_picker: "9704b2ff-0f4b-450a-9611-69673ff79d84",
    date_range: "9a1e86ed-03bc-45d2-b416-6309f540bda4",
    side_dish: "587c9b58-816e-4e30-bc55-4ff9e01e95da",
    event: "879f6a6e-34a4-4ab3-b84d-e7405a065a7e",
    readonly: "f5a851ea-a9eb-4ef3-920d-61e59fc8f82e"
  },

  //how many reservations is possible to reserve via GuestApp
  maxNumOfReservations: [
    { name: "date_range", value: 10 },
    { name: "appointment", value: 10 }
  ],
  marked_list: {
    without_header: "f33cf51e-1f5b-4113-9373-027222ee53dc"
  },
  durations: {
    lowestTime: "5b8a6b88-3537-4aca-871a-b12f0bc001f7",
    allDay: "6c90e62f-ae77-4366-af95-54d02310d73e"
  },
  review_field_types: {
    text: "81f6eb9a-c6a1-4c37-ac22-3fc4f6eac86d",
    mark: "fa42038d-32ab-4224-8cef-c606e7b37adc"
  },
  page_screen: {
    roomNumLogin: "809ad246-18f8-420f-9ec0-46d6bb49db85"
  },
  payment_status: [
    {
      id: "3874a24d-22ca-474a-8362-85e7add4988a",
      name: "finished",
      color: "#808080"
    },
    {
      id: "335057bd-770e-4b2d-a5f5-e52d96cb7069",
      name: "pending",
      color: "#ff0000"
    },
    {
      id: "280e54da-d5f3-4235-b90d-17a1b5661390",
      name: "canceled",
      color: "#33cc33"
    }
  ],
  order_item_status: [
    {
      id: "ecf6609e-c924-4320-b0ed-0d7b737cf864",
      name: "processing",
      color: "#ffccff"
    },
    {
      id: "0fa8a8d0-90bb-48aa-b4c7-7f8cc70f396c",
      name: "unsuccessfully processed",
      color: "#ffcc66"
    },
    {
      id: "cbebc670-50da-4d88-a566-017f98348389",
      name: "finished",
      color: "#808080"
    },
    {
      id: "0af38e1d-9f92-4be3-ad51-0455d325d96c",
      name: "pending",
      color: "#00cc00"
    },
    {
      id: "40af6e71-74ee-475f-adfe-0f35b0c2f2c4",
      name: "canceled",
      color: "#ff0000"
    },
    {
      id: "1b56be7b-68ca-4963-8403-04f623b8f099",
      name: "in progress",
      color: "#1a8cff"
    }
  ],
  notification_state: {
    sent: "6ba51ba1-daf8-41e0-991c-603b305e91cd",
    read: "efcab6b6-bf38-4d63-95af-35f3bc73ccb6",
    recieved: "397fdf4a-be04-48dc-9c2c-abd4725e88b0",
    successful: "a519a848-a596-4deb-9f58-da86f18f555b"
  },
  forStatistics: {
    hsr: "595fdf74-45b0-4f69-9c7c-b7a66262445b",
    selectView: "987ff9ac-47af-49d8-98f9-e545817c4d95",
    search: "0596317a-cd66-48a2-95d4-ad91f6f8c403",
    orders: "0224c02c-378d-4f4b-82c8-04164e922a85",
    push: "d5c5027f-7966-43cf-9fa3-9c8dc985cb44"
  },
  deliveryTypes: {
    roomService: "eac6d0e7-924b-4d11-b136-84688b559552",
    orgPickup: "775ff8f5-7486-4d4c-8e3c-3fd41b8b4a37"
  }
};

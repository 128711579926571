/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
// React
import React from "react";
import { useSelector } from "react-redux";

// Util functions
import { GLOBAL_VARIABLES } from "Util/idConfig";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

// Atoms
import Image from "Components/atoms/UI/Image";
import ImageWithProperties from "Components/atoms/UI/ImageWithProperties";

const useStyles = makeStyles((theme) => ({
  slider: {
    display: "flex",
    overflowX: "auto",
    scrollSnapType: "x mandatory",
    "-webkit-overflow-scrolling": "touch",
  },
  sliderWholeScreen: {
    display: "flex",
    overflowX: "auto",
    scrollSnapType: "x mandatory",
    "-webkit-overflow-scrolling": "touch",
    "&.MuiBox-root": {
      margin: "0 -16px -16px -16px",
    },
  },
  slideNoImage: {
    scrollSnapAlign: "start",
    flexShrink: "0",
    width: "100%",
    height: "250px",
    marginBottom: "10px",
    backgroundColor: "rgb(231, 231, 231)",
    background: "#D3D3D3",
    borderRadius: "3px",
    zIndex: "0",
  },
}));

const ImageSlider = (props) => {
  const classes = useStyles();
  const lists = useSelector((state) => state.lists.data);
  const marked_list = lists.find((item) => item.Marked);

  function getMarkedStatus(id) {
    const marked = marked_list
      ? marked_list?.Marked?.find((item) => item.id === id)
      : null;
    if (marked?.id !== GLOBAL_VARIABLES.marked_list?.without_header) {
      return marked?.text;
    }
    return null;
  }

  const {
    items,
    homepage,
    size,
    wholeScreen,
    currency,
    price_display,
    handleClick,
    accordion
  } = props;

  return (
    <Box className={wholeScreen ? classes.sliderWholeScreen : classes.slider}
    style={{ padding: accordion === false && "1px 1px 0px 1px" }}
    >
      {items.length > 0 ? (
        homepage ? (
          items.length === 1 ? 
          <ImageWithProperties
                size={size}
                key={items[0]?.service?.id}
                imagePath={`${items[0]?.gallery[0]?.path}`}
                index={0}
                length={items.length}
                label={items[0]?.service?.name}
                price={items[0]?.service?.price}
                handleClick={() => handleClick(items[0]?.service?.id)}
                currency={currency}
                marked={getMarkedStatus(items[0]?.service?.marked)}
                price_display={price_display}
                singleItem
              />
              :
          items.map((item, index) => {
            return (
              <ImageWithProperties
                size={size}
                key={item.service?.id}
                imagePath={`${item.gallery[0]?.path}`}
                index={index}
                length={items.length}
                label={item.service?.name}
                price={item.service?.price}
                handleClick={() => handleClick(item.service?.id)}
                currency={currency}
                marked={getMarkedStatus(item.service?.marked)}
                price_display={price_display}
              />
            );
          })
        ) : (
          items.map((item, index) => {
            return (
              <Image
                key={item.id}
                imagePath={`${item.path}`}
                index={index}
                length={items.length}
                accordion={accordion}
              />
            );
          })
        )
      ) : (
        <Box className={classes.slideNoImage} />
      )}
    </Box>
  );
};

ImageSlider.defaultProps = {
  items: [],
  homepage: false,
  size: false,
  wholeScreen: false,
  currency: null,
  price_display: true,
  accordion: false,
  handleClick: () => console.log("handleClick"),
};

export default ImageSlider;

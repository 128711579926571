// React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Box from "@material-ui/core/Box";

//Icons
import NotificationsIcon from "@material-ui/icons/Notifications";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

//Atoms
import IconButton from "Components/atoms/buttons/IconButton";
import Button from "Components/atoms/buttons/Button";

//Molecules
import TitleWithDivider from "Components/molecules/TitleWithDivider";
import CardDistributor from "Components/molecules/CardDistributor";

//Actions
import {
	markNotificationsAsRecieved,
	markNotificationsAsRead,
} from "Modules/units/Notification";

// Router
import { useHistory } from "react-router-dom";

//Utils
import { GLOBAL_VARIABLES } from "Util/idConfig";
import registerSW from "Util/registerSW";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	redDot: {
		display: "inline-block",
		background: "red",
		borderColor: "transparent",
		borderStyle: " solid",
		borderRadius: "50%",
		// marginTop: "2px",
		marginLeft: "-10px",
		position: "fixed",
		height: "10px",
		width: "10px",
	},
	headRow: {
		padding: "40px 20px 0px",
		justifyContent: "space-between",
		display: "inline-flex",
		// whiteSpace: "nowrap",
	},
	titleAndIcon: {
		justifyContent: "space-between",
		display: "inline-flex",
		alignItems: "start",
	},
	content: {
		overflow: "auto",
		padding: "0px 15px 60px",
	},
	bottomIcon: {
		bottom: 0,
		position: "fixed",
		width: "100%",
		backgroundColor: "white",
	},
}));

const Notifications = (props) => {
	const { dataCy } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const notifications = useSelector((state) => state.notifications);
	const history = useHistory();

	const [open, setOpen] = useState(false);
	const foundAtLeastOneNotRead = notifications?.list?.find(
		(i) => i.state === GLOBAL_VARIABLES.notification_state.sent
	);

	const handleReadAll = () => {
		const getNot =
			JSON.parse(localStorage.getItem("notifications")) != null
				? JSON.parse(localStorage.getItem("notifications"))
				: [];

		var tempNot = [];
		if (getNot && getNot.length > 0) {
			tempNot = getNot?.map((i) => {
				if (i) {
					return {
						...i,
						state: GLOBAL_VARIABLES.notification_state.read,
					};
				}
			});

			localStorage.setItem("notifications", JSON.stringify(tempNot));
			dispatch(
				markNotificationsAsRead(tempNot, {
					list_id: tempNot.map((i) => i.id),
				})
			);
		}
	};

	const handleReadNotification = (link, ID) => {
		setOpen(false);
		history.push(link);

		const getNot =
			JSON.parse(localStorage.getItem("notifications")) != null
				? JSON.parse(localStorage.getItem("notifications"))
				: [];

		var tempNot = [];
		if (getNot && getNot.length > 0) {
			tempNot = getNot?.map((i) => {
				if (i.id === ID) {
					return {
						...i,
						state: GLOBAL_VARIABLES.notification_state.read,
					};
				} else return i;
			});

			localStorage.setItem("notifications", JSON.stringify(tempNot));
			dispatch(markNotificationsAsRead(tempNot, { list_id: [ID] }));
		}
	};

	useEffect(() => {
		if (open) {
			const getNot =
				JSON.parse(localStorage.getItem("notifications")) != null
					? JSON.parse(localStorage.getItem("notifications"))
					: [];

			var tempNot = [];
			if (getNot && getNot.length > 0) {
				tempNot = getNot?.map((i) => {
					if (i && i.state === GLOBAL_VARIABLES.notification_state.sent) {
						return {
							...i,
							state: GLOBAL_VARIABLES.notification_state.recieved,
						};
					} else return i;
				});

				localStorage.setItem("notifications", JSON.stringify(tempNot));
				dispatch(
					markNotificationsAsRecieved(tempNot, {
						list_id: tempNot.map((i) => i.id),
					})
				);
			}
		}
	}, [open]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div>
				<IconButton
					icon={<NotificationsIcon />}
					onClick={handleClickOpen}
					dataCy={dataCy}
				/>

				{foundAtLeastOneNotRead != undefined ? (
					<span className={classes.redDot} />
				) : null}
			</div>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<Box className={classes.headRow}>
					<Box className={classes.titleAndIcon}>
						<TitleWithDivider
							title={"notifications"}
							dataCy={"notificationsTitle"}
						/>
						<IconButton
							icon={<NotificationsIcon />}
							onClick={() => registerSW()}
						/>
					</Box>
					<Button
						label="read all"
						customStyle={{
							// whiteSpace: "nowrap",
							// textOverflow: "ellipsis",
							// overflow: "hidden",
							// placeContent: "flex-start",
							textAlign: "right",
						}}
						customFontColor={true}
						dataCy={"readAllButton"}
						onClick={handleReadAll}
					/>
				</Box>
				<Box className={classes.content}>
					{notifications?.list?.length > 0 &&
						notifications?.list?.map((i) => (
							<CardDistributor
								key={i.id}
								notifications={true}
								id={i.id}
								title={i.title}
								image={i.campaign?.image}
								descriptionText={i.description}
								readOrUnread={false}
								notification_state={i.state}
								onClick={() => {
									handleReadNotification(i.link, i.id);
								}}
							/>
						))}
				</Box>
				<Box className={classes.bottomIcon}>
					<IconButton
						color="default"
						size="medium"
						customStyle={{ width: "100%" }}
						icon={<ExpandLessIcon />}
						onClick={handleClose}
						dataCy={"closeNotifications"}
					/>
				</Box>
			</Dialog>
		</>
	);
};

Notifications.defaultProps = {
	dataCy: null,
};

export default Notifications;

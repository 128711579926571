// React
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Material UI
import DialogMUI from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";

//Atoms
import Button from "Components/atoms/buttons/Button";
import { Typography } from "@material-ui/core";

// Image
import { SuccessfulOrderImg } from "Components/atoms/imgs/SuccessfulOrderImg";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	imageStyle: {
		// backgroundImage: "url(https://blush.ly/XbAu3OnV2u/p)",
		// backgroundColor: "#ffffff",
		height: "405px",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "contain",
		position: "relative",
		alignSelf: "center",
		display: "flex",
		alignItems: "center",
	},
}));

const FullscreenDialogSuccess = ({ opened, handleClose }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const clickedCategory = JSON.parse(localStorage.getItem("clickedCategory"));

	return (
		<div>
			<DialogMUI
				fullScreen
				open={opened}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<Box style={{ textAlign: "center", marginTop: "20px" }}>
					<Typography style={{ fontSize: "xx-large" }}>
						{t("Order successfully placed!")}
					</Typography>
				</Box>
				<div className={classes.imageStyle}>
					<SuccessfulOrderImg />
				</div>
				<Box style={{ textAlign: "center" }}>
					<Typography
						style={{
							position: "relative",
							fontSize: "large",
							width: "75%",
							margin: "auto",
						}}
					>
						{t(
							"You can always track your orders in ORDERS section under main menu."
						)}
					</Typography>
				</Box>
				<DialogActions style={{ display: "block", padding: "15px" }}>
					<Button
						onClick={() => {
							handleClose();
							clickedCategory
								? history.push("/selectviewcollapse/" + clickedCategory.id)
								: null;
						}}
						label={"order more"}
						variant="contained"
						customStyle={{
							width: "100%",
							height: "75px",
							boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
							fontWeight: "bold",
						}}
						customFontColor={true}
					/>
					<Button
						onClick={() => {
							handleClose();
							history.push("/orders");
						}}
						label={"my orders"}
						variant="contained"
						customStyle={{
							width: "100%",
							height: "75px",
							marginTop: "10px",
							marginLeft: "0px",
							boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.47)",
						}}
					/>
				</DialogActions>
			</DialogMUI>
		</div>
	);
};

export default FullscreenDialogSuccess;

// React
import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Box from "@material-ui/core/Box";

const style = makeStyles((theme) => ({
  slide: {
    scrollSnapAlign: "start",
    flexShrink: "0",
    width: "75%",
    height: "250px",
    backgroundColor: "rgb(231, 231, 231)",
    marginTop: "5px",
    marginBottom: "10px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: "0",
    borderRadius: "20px",
    marginLeft: "7px",
    display: "grid",
    boxShadow: theme.shadows[3],
  },
  label: {
    backgroundColor: "#ffffffeb",
    borderRadius: "10px",
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "500",
    width: "80%",
    marginBottom: "20px",
    height: "30px",
    textAlign: "center",
    padding: "6px",
    boxShadow: theme.shadows[3],
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  mobileLabel: {
    backgroundColor: "#ffffffeb",
    borderRadius: "10px",
    color: theme.palette.primary.main,
    fontSize: "11px",
    fontWeight: "500",
    width: "80%",
    marginBottom: "12px",
    height: "20px",
    textAlign: "center",
    padding: "2px",
    boxShadow: theme.shadows[3],
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  status: {
    backgroundImage: `linear-gradient(90deg,  #008D06 0% , #008D06 100%)`,
    borderRadius: "10px",
    color: "white",
    fontSize: "13px",
    fontWeight: "600",
    // width: "20%",
    height: "30px",
    textAlign: "center",
    padding: "6px",
    marginLeft: "20px",
    marginTop: "20px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  price: {
    backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
    borderRadius: "10px",
    color: "white",
    fontSize: "13px",
    fontWeight: "600",
    // width: "25%",
    height: "30px",
    textAlign: "center",
    padding: "6px",
    marginRight: "20px",
    marginTop: "20px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  mobilePrice: {
    backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
    borderRadius: "10px",
    color: "white",
    fontSize: "11px",
    fontWeight: "600",
    // width: "25%",
    height: "24px",
    textAlign: "center",
    padding: "4px",
    marginRight: "12px",
    marginTop: "12px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

const ImageWithProperties = (props) => {
  const classes = style();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const isTablet = useMediaQuery("(min-width:1024px)");
  const largerThanTablet = useMediaQuery("(min-width:1025px)");
  const largerThanMedDesk = useMediaQuery("(min-width:1601px)");

  const {
    imagePath,
    label,
    price,
    size,
    handleClick,
    currency,
    //marked,
    price_display,
    singleItem
  } = props;

  
  return (
    <Box
      className={classes.slide}
      style={{
        backgroundImage: `url(${imagePath})`,
        marginLeft:singleItem ? '0px' : '7px',
        width: size ? (largerThanTablet ? (largerThanMedDesk ? "44.35vw" : "72.575vw") : (isTablet ? "80.75vw" : "81.85vw")) : (largerThanTablet ? (largerThanMedDesk ? "22vw" : "36vw") : "40vw"),
        height: size ? (largerThanTablet ? (largerThanMedDesk ? "33.2625vw" : "54.43125vw") : "61.3125vw") : (largerThanTablet ? (largerThanMedDesk ? "22vw" : "36vw") : "40vw"),
      }}
      onDrag={(e) => e.stopPropagation()}
      onClick={handleClick}
    >
      <Box
        style={{
          display: "flex",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          justifyContent: "flex-end",
        }}
      >
        {/*marked ? (
          <Box style={{ float: "left" }} className={classes.status}>
            {t(marked).toUpperCase()}
          </Box>
        ) : (
          <Box />
        )*/}

        {price_display && price != null && (
          <Box style={{ placeSelf: "right" }} className={size ? classes.price : (matches ? classes.price : classes.mobilePrice)}>
            {price.toFixed(2) + " " + (currency ? currency : "")}
          </Box>
        )}
      </Box>

      <Box style={{ placeSelf: "flex-end center" }} className={size ? classes.label : (matches ? classes.label : classes.mobileLabel)}>
        {t(label).toUpperCase()}
      </Box>
    </Box>
  );
};

ImageWithProperties.defaultProps = {
  imagePath: "",
  //key: "",
  label: "",
  price: "",
  size: false,
  handleClick: () => console.log("handleClick"),
  currency: null,
  //marked: null,
  price_display: true,
};

export default ImageWithProperties;

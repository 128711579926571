// React
import React, { memo } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Snackbar, Box } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";

// Molecules
import SingleReview from "Components/molecules/SingleReview";

// Atoms
import ReviewStar from "Components/atoms/UI/ReviewStar";

// Dialogs
import Dialog from "Components/dialogs/Dialog";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Popups = (props) => {
  const { t } = useTranslation();
  const {
    reviewsString,
    classes,
    openState,
    handleClose,
    handleCloseReviews,
    openReviews,
    oneItem,
  } = props;
  return (
    <>
      <Snackbar
        open={openState?.open}
        autoHideDuration={2000}
        onClose={handleClose}
        key={t(openState.message)}
      >
        <Alert onClose={handleClose} severity="success">
          {t(openState.message)}
        </Alert>
      </Snackbar>

      <Dialog
        opened={openReviews}
        handleClose={handleCloseReviews}
        title={reviewsString}
        contentInfo={
          <Box className={classes.boxInDialogReview}>
            <ReviewStar
              label={oneItem?.data?.average_rating?.toFixed(1)}
              customStyle={{ float: "left" }}
              color="default"
            />
            <Typography
              style={{
                float: "right",
                fontSize: "small",
                marginTop: "3px"
              }}
            >
              {oneItem?.data?.number_of_reviews +
                " " +
                t(reviewsString).toUpperCase()}
            </Typography>
          </Box>
        }
        content={
          <>
            <div style={{ height: "auto" }}>
              {oneItem?.data?.reviews?.map(i => (
                <Box className={classes.boxInDialog}>
                  <SingleReview
                    name={i.guest.first_name + " " + i.guest.last_name}
                    date={i.order_item.review_time}
                    stars={i.order_item.review_point}
                    reviewDesc={i.order_item.review_description}
                    variant={"subtitle2"}
                    customStyle={{ fontSize: "14px" }}
                  />
                </Box>
              ))}
            </div>
          </>
        }
      />

      
    </>
  );
};

Popups.defaultProps = {
  classes: {},
  reviewsString: "",
  openState: {},
  handleClose: () => console.log("close"),
  handleCloseReviews: () => console.log("close reviews"),
  openReviews: null,
  oneItem: [],
};

export default memo(Popups);

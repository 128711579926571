/* eslint-disable camelcase */
// React
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Material-UI
import {
	Container,
	Box,
	MuiThemeProvider,
	createTheme,
} from "@material-ui/core";

// Util
import { detectMi } from "Util/functions";

// Layout components
import Header from "Layouts/sections/Header";
import HeaderCollapsibleSearchBar from "Layouts/sections/HeaderCollapsibleSearchBar";
import Footer from "Layouts/sections/Footer";

// Redux actions
import { getTheme } from "Modules/units/App";

// Loading spinner
import FullScreenSpinner from "Layouts/loaders/FullScreenSpinner";

import SelectViewCollapse from "Pages/browse/SelectViewCollapse";

// Share icon
//import iosShareIcon from "Assets/images/ios_share_icon.png";

const TripleAlternativeLayout = (state) => {
	const dispatch = useDispatch();
	const [handler, setHandler] = useState(null);

	//const history = useHistory();
	const app = useSelector((state) => state.app);
	//const [showInstallTut, setInstallTut] = useState(false);

	useEffect(() => {
		document.documentElement.style.height = "100%";
		document.getElementsByTagName("body")[0].style.height = "100%";
		document.getElementById("root").style.height = "100%";
		return () => {
			document.documentElement.style.height = "auto";
			document.getElementsByTagName("body")[0].style.height = "auto";
			document.getElementById("root").style.height = "auto";
		};
	}, []);

	useEffect(() => {
		if (app.theme === null) dispatch(getTheme());
		/*if (isIos() && !isInStandaloneMode()) {
      setInstallTut(true);
    }*/
		return () => {};
	}, []);

	const isIos = () => {
		const userAgent = window.navigator.userAgent.toLowerCase();
		return /iphone|ipad|ipod/.test(userAgent);
	};

	/*const isInStandaloneMode = () =>
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone ||
    document.referrer.includes("android-app://");*/

	/*const isHomePage = () => {
    if (history.location.pathname === "/home") {
      return true;
    } else return false;
  };*/
	const handleSetHandler = (data) => {
		setHandler(data);
	};

	return (
		<>
			{app.theme?.mui_theme ? (
				<MuiThemeProvider theme={createTheme(app.theme.mui_theme)}>
					<HeaderCollapsibleSearchBar />
					{/*showInstallTut && isHomePage() && (
            <Box
              className="ios-install-notification"
              onClick={() => setInstallTut(false)}
            >
              To <b>install</b> this webapp on your device; tap
              <img
                alt="iosshareicon"
                src={iosShareIcon}
                style={{
                  width: "20px",
                  height: "27px",
                  margin: "0 5px",
                }}
              />
              and then <b>Add to homescreen</b>.
            </Box>
              )*/}
					{/* <Container 
            {...handler}
            style={{ height: "100%", overflowX: !detectMi() ? 'clip' : 'initial'}} fixed maxWidth="md">
            <Box mt={12} pb={7}>
              {state.children}
              <SelectViewCollapse getHandler={handleSetHandler} />
            </Box>
          </Container> */}
					{state.children}
					<Box
						style={{
							width: "100%",
							position: "fixed",
							bottom: 0,
							boxShadow: "0px -7px 8px -7px rgba(0,0,0,0.20)",
							paddingBottom: isIos() ? "12px" : null,
							backgroundColor: "white",
							opacity: "0.95",
						}}
					>
						<Container fixed maxWidth="md">
							<Footer />
						</Container>
					</Box>
				</MuiThemeProvider>
			) : (
				<FullScreenSpinner />
			)}
		</>
	);
};

export default TripleAlternativeLayout;

/* eslint-disable no-nested-ternary */
// React, Redux, Router
import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { useDispatch } from "react-redux";

// MUI
import { AppBar, Toolbar, Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Atoms
import Logo from "Components/atoms/UI/Logo";
// import Input from "Components/atoms/inputs/Input";

// Pages
import Cart from "Pages/cart/index.js";
import Notifications from "Pages/notifications/index.js";

// Molecules
import ChangeLanguage from "Components/molecules/ChangeLanguage";

// Actions
import { getAllItemsFromCart } from "Modules/units/Cart";
import { getNotifications } from "Modules/units/Notification";

// Utils
import { getCookie } from "Util/functions";

const style = makeStyles(() => ({
  title: {
    flexGrow: 1,
  },
}));

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Header = (props) => {
  const classes = style();
  const dispatch = useDispatch();

  useEffect(() => {
    const data =
      JSON.parse(localStorage.getItem("order")) != null
        ? JSON.parse(localStorage.getItem("order"))
        : [];

    dispatch(getAllItemsFromCart(data));
    dispatch(getNotifications(getCookie("lang")));
  }, []);

  return (
    <HideOnScroll {...props}>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: "#ffffffe6",
          padding: "1rem 0",
          borderRadius: "0 0 10px 10px",
        }}
      >
        <Container fixed maxWidth="md" dataCy="container">
          <Toolbar disableGutters>
            <Box className={classes.title}>
              <Logo width="145px" goTo="/home" />
            </Box>
            <Box>
              <ChangeLanguage color="primary" dataCy={"languageIcon"} />
            </Box>
            <Box>
              <Notifications dataCy="notificationIcon" />
            </Box>
            <Box>
              <Cart dataCy="cartIcon" />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
};

export default Header;

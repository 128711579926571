// React
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
//import { isXsDevice } from "Util/functions";

// MUI
import AutocompleteMUI from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

// Services
import { postFunc } from "Services/mainApiServices";

// Molecules
import SearchCardContent from "Components/molecules/SearchCardContent";

const useStyles = makeStyles((theme) => ({
  proba: {
    borderRadius: "10px",
    backgroundColor: "white",
    padding: "5px !important",
  },
  labelRoot: {
    fontSize: "14px",
  },
  searchIcon: {
    fontSize: "1.2rem",
    margin: "5px",
    marginTop: "7px",
  },
  img: {
    height: "72px",
    width: "72px",
  },
  paper: {
    padding: 0,
    margin: 0,
  },
  listbox: {
    margin: 0,
    padding: 0,
  },

  specialOutline: {
    borderColor: "#d0d0d0",
    boxShadow: theme.shadows[3],
  },
}));


const AlternativeAutocomplete = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const [userLoadingText, setUserLoadingText] = useState("No results")
  const {
    value,
    lang,
    label,
    variant,
    autoFocus,
    validation,
    required,
    service,
    setParentState,
    charsToTrigger,
    disabled,
    value1,
    value2,
    org_id,
    listBoxHeight
  } = props;
  const [display, setDisplay] = useState(charsToTrigger);

  const valuesToDisplay = [value1];
  const imgToDisplay = [value2];
  const optionText = (option, valuesToDisplay) => {
    let label = "";
    const values = Object.values(option);
    if (typeof option === "object") {
      valuesToDisplay.forEach((number) => {
        label += `${values[number]} `;
      });
      return label;
    }
    return option;
  };

  const imageFunc = (option, imgToDisplay) => {
    let img = "";
    const values = Object.values(option);

    if (typeof option === "object") {
      imgToDisplay.forEach((number) => {
        img = values[number][0].path;
      });
      return img;
    }
    return option;
  };

  const searchFunc = async (value) => {
    setDisplay(charsToTrigger - value?.length);
    if (charsToTrigger <= value?.length) {
      const body = {
        search: value,
        lang: lang,
        org_id,
      };
      setUserLoadingText("Loading results")
      const response = await postFunc(service, body);
      response.data.length === 0 ? setUserLoadingText("No results") : null
      setOptions(response.data);
    }
    else {
      setOptions([]);
    }
  };

  const handleOptions = () => {
    setParentState({ label: "", id: "" });
    setOptions([]);
  };

  return (
    <AutocompleteMUI
      autoHighlight
      classes={{ paper: classes.paper, listbox: classes.listbox }}
      //disableOpenOnFocus
      // ListboxComponent={ListboxComponent}
      ListboxProps={{style:{maxHeight: listBoxHeight ? `${listBoxHeight}px` : "auto"}}}
      onInputChange={(e, newInputValue, reason) => {
        if (reason === "reset") {
          setOptions([]);
          setDisplay(charsToTrigger);
        } else searchFunc(e?.currentTarget?.value);
      }}
      onChange={(e, value) =>
        value
          ? setParentState({
              label: value ? optionText(value, valuesToDisplay) : "",
              id: value ? value.id : "",
            })
          : handleOptions()
      }
      getOptionLabel={(option) =>
        option
          ? optionText(option, valuesToDisplay)
          : "No results" && setOptions([])
      }
      options={options}
      renderOption={(option) => {
        return option ? (
          <SearchCardContent
            title={optionText(option, valuesToDisplay)}
            image={imageFunc(option, imgToDisplay)}
            description={option?.value3}
            price={option?.value2}
            status={option?.value5}
          />
        ) : null;
      }}
      value={value.label}
      disabled={disabled}
      loading={true}
      loadingText={
        display < 4 && display > 0
          ? `${t("Type") + " " + display + " " + t("more characters")}`
          : t(userLoadingText)
      }
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            label={t(label)}
            required={required}
            variant={variant}
            autoFocus={autoFocus}
            fullWidth
            margin="dense"
            error={validation}
            helperText={validation}
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end" style={{ color: "slategray" }}>
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
              classes: {
                root: classes.proba,
                notchedOutline:
                  variant === "outlined" && classes.specialOutline,
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
              },
            }}
          />
        </>
      )}
    />
  );
};

AlternativeAutocomplete.defaultProps = {
  label: "Label",
  variant: "outlined",
  autoFocus: false,
  type: "text",
  value: { label: "", id: "" },
  error: false,
  helperText: "",
  required: false,
  valuesToDisplay: [1, 2, 3],
  charsToTrigger: 1,
  disabled: false,
  org_id: null,
};

export default AlternativeAutocomplete;
